import { Fragment, useEffect, useState } from "react";
import { backend_api_url, cluster, getExplorerUrl, getUserSessionData, isUserAdmin } from "./constants";

const PurchaseListContainer = (props: any) => {

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const fetchInfo = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/purchases', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    showInfo(data.nfts);
                    
                }
            });
	};
  
    const showInfo = async (lists: any[]): Promise<any> => {
 
          //console.log(allListings)
         setListingJsx( lists.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
            <tr>
                <td>{info.date}</td>
                <td><a  rel="noreferrer" href={"/user/" + info.username} target="_blank">{info.username}</a></td>
 

                <td><a  rel="noreferrer" href={getExplorerUrl('address')+info.nft_asset_address+'/?cluster='+cluster} target="_blank">view</a></td>
                <td>$ {info.price}</td>

                <td><a  rel="noreferrer" href={"/collection/" + info.collectionAddress} target="_blank">{info.collectionName}</a></td>
                <td><a  rel="noreferrer" href={"/user/" + info.creator} target="_blank">{info.creator}</a></td>
 

            </tr>
            </Fragment>) );
 
     };
 

	useEffect(() => {
		
        //fetchAllMyGachas();
        if(!isUserAdmin()) {
            window.location.href = '/';
        }
        fetchInfo();
	  }, []);

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">
               <a href="/admin/users">Users</a>&nbsp;|&nbsp;               
               <a href="/admin/creators">Creators</a>&nbsp;|&nbsp;               
               <a href="/admin/collections">Collections</a>&nbsp;|&nbsp;               
               <a href="/admin/purchases">Purchases</a>&nbsp;|&nbsp; 
               <a href="/admin/actions">Actions</a>

               <table className="walletActivityTable admin-infos"  style={{ "borderSpacing" : 0}} >
                <tbody>
                    <tr>
                    <td align="center"><b>TIME</b></td>
                    <td align="center"><b>USER</b></td>
                    <td align="center"><b>NFT</b></td>
                    <td align="center"><b>PRICE</b></td>
                    <td align="center"><b>COLLECTION</b></td>
                    <td align="center"><b>CREATOR</b></td> 

                    </tr>	

                    {listingJsx}
                
                </tbody>
                </table>
            </div>
                
         
        </Fragment>
    );
}

export default PurchaseListContainer;