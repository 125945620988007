import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import { backend_api_url, cluster, getCurrentWallet, getExplorerUrl, getUserBalance, getUserSessionData, isShowLedgerInfo, isShowLedgerInfoNew, wait} from "./constants";
import { PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';

import { SolanaWallet } from "@web3auth/solana-provider";

    
import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import LedgerItem from "./LedgerItem"; 
import LedgerItemNew from "./LedgerItemNew";
 

const GachaWallet = (props : any) => {
  
 
    const [ledgerJsx, setLedgerJsx] = useState<JSX.Element[] | null>([])
    const [ledgerMobileJsx, setLedgerMobileJsx] = useState<JSX.Element[] | null>([])
 
    const [isProcessing, setIsProcessing] = useState(false);
 
    const [collectionName, setCollectionName] = useState("");

  

  const loadAllActivities = (address) => {
    setIsProcessing(true);

    const userData = getUserSessionData();
 
    let params = {
        token: userData ? userData.token : '',
        secret: userData ? userData.secret : '',
        candy_machine_address: address

    }


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/machine/get-txn', requestOptions)
        .then(response => response.json())
        .then(data => {
            setCollectionName(data.name)
            if(data.status === 1) {
                
 
                renderTableData (data.txns); 
                
                setIsProcessing(false);

            }
        });
        
  }

  const renderTableData = (txns) => {

    setLedgerJsx( txns.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
    <tr>
        <td align="center">{info.date_created_display}</td>
        <td align="center">{info.type}</td>

        <td align="center"><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+info.collectionAddress+'?cluster='+ cluster}>{info.collectionAddress}</a></td>

        <td align="center"><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+info.receiverAddress+'?cluster='+ cluster}>{info.receiverAddress}</a></td>

    </tr>
    </Fragment>) );

setLedgerMobileJsx( txns.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
<table className="walletActivityTable"  style={{ "borderSpacing" : 0}}>
<tbody>
<tr>
    <td width="40%"><b>DATE</b></td>
    <td width="60%">{info.date_created_display	}</td>
</tr>
<tr>
    <td width="40%"><b>TYPE</b></td>
    <td width="60%">{info.type}</td>
</tr>
<tr>
    <td width="40%"><b>FROM</b></td>
    <td width="60%"><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+info.collectionAddress+'?cluster='+ cluster}>{info.collectionAddress}</a></td>
</tr> 
<tr>
    <td width="40%"><b>TO</b></td>
    <td width="60%"><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+info.receiverAddress+'?cluster='+ cluster}>{info.receiverAddress}</a></td>
</tr> 
</tbody>
</table>
</Fragment>) );

  }

  let paramsMain = useParams();

    useEffect(() => {
        if(!getUserSessionData()) { 
            window.location.href = "/";
        }else{
          //setIsProcessing(true);
          //checkTxn();

          const userData = getUserSessionData();
          if(userData) {
            loadAllActivities(paramsMain.address);

          }
        }
    }, []);

    props.setClassInfo('walletActivityPg');
 
   


    return (
        <Fragment>

<div className="contents">

<h5 className="outer"><a href={'/collection-wallet/' + paramsMain.address}>{collectionName}</a> - Wallet Activity</h5>

<div className="br"></div>


<div className="tblDesktop">
    {/* cellpadding="0" cellspacing="0" */}
<table className="walletActivityTable"  style={{ "borderSpacing" : 0}} >
  <tbody>
    <tr>
      <td align="center"><b>DATE</b></td>
      <td align="center"><b>TYPE</b></td>
      <td align="center"><b>FROM</b></td>
      <td align="center"><b>TO</b></td>
    </tr>	

    {ledgerJsx}
 
  </tbody>
</table>
</div>

<div className="tblMobile">
  

    {ledgerMobileJsx}

    
</div>		

{isProcessing && <p className="bigger-font" style={{"textAlign": "center"}}>Processing...</p>}
 

</div>

        
        </Fragment>

    );
}

export default GachaWallet;