import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { isUserLoggedIn } from "./constants";

const CheckoutForm = (props : any) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
 

  const doConfirm = async () => {
 
    setIsLoading(true);
    const { paymentIntent  } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000",
      },
      redirect: 'if_required' 
    });
 
    
    //console.log(paymentIntent);

    if(paymentIntent) { 
      switch (paymentIntent.status) {
      case "succeeded":
          //setMessage("Payment succeeded!");
          localStorage.removeItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET);

          props.showSuccessPayment();
          props.saveUserReload(props.reloadBalance, paymentIntent.id, 'stripe');

          //props.mint(paymentIntent.id, 'stripe');
          break;
      case "processing":
          setMessage("Your payment is processing.");
          break;
      case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
      default:
          setMessage("Something went wrong.");
          break;
      }
    }
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    localStorage.removeItem("pendingPaypalId"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET);

    if(!isUserLoggedIn()) {
      //console.log("hiding, to show login");
      props.hideAndLoginBeforeCapture(doConfirm);
      return;
    }

    await doConfirm();
    
   
  };


 
  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element"/>
      {
          stripe &&
      <button className="stripe-btn" disabled={isLoading || !stripe || !elements} id="submit">
       
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
       
      </button>
      }
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default CheckoutForm;