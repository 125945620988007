import { Fragment, useEffect, useState } from "react";
import { backend_api_url, getUserSessionData } from "./constants";
import { useParams } from "react-router-dom";

const PromoCode = (props: any) => {
 

    const fetchPromo = async (code): Promise<any> => {
         
        const params = {
            code: code,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/promo-by-code', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    window.location.href = '/collection/' + data.gacha_address;

                    localStorage.setItem("promo_"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET, code);

                } else {
                    window.location.href = '/';
                }
            });
	};
  
    let paramsMain = useParams();

	useEffect(() => {
		
        fetchPromo(paramsMain.promoCode);
	
	  }, []);

    return (
        <Fragment>
                 
        </Fragment>
    );
}

export default PromoCode;