import { Fragment, useEffect, useState } from "react";
import { backend_api_url, getUserSessionData } from "./constants";

const MyGacha = (props: any) => {

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const fetchAllMyGachas = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/gachas', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    fetchAllMachine(data.machines);
                    
                }
            });
	};
 
    const fetchAllMachine = async (gachas: any[]): Promise<any> => {
 
       console.log(gachas);
        //console.log(allListings)
        setListingJsx( gachas.map( (gacha) => <Fragment> {gacha.avatar ? <img alt="gacha-avatar" className="gacha-avatar" src={gacha.avatar} /> : ""} <a href={'/collection/'+gacha.candy_machine_address}>{gacha.name} ({gacha.candy_machine_address})</a><br /></Fragment>) );

	};


	useEffect(() => {
		
        fetchAllMyGachas();
	
	  }, []);

    return (
        <Fragment>
                My Gachas <br />
                {listingJsx}
        </Fragment>
    );
}

export default MyGacha;