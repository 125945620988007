import React, {Fragment, useEffect, useRef, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import {createAccount, getBalance, cluster, getConnectionConfig, getUserSessionData, backend_api_url} from "../constants";
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';

const Privacy = (props: any) => {


    return (
        <Fragment>
        
        <main>
  <section className="headline">
	<h1>Privacy Policy </h1>
  </section>

  
  <section className="white">
	<div className="padding">
		<p> We at Toybot.app and our affiliated companies worldwide are committed to respecting your online privacy and recognize your need for appropriate protection and management of any information you share with us by using our web sites.</p>
		<p>Toybot.app has established the following Online Privacy Policy so that you can understand the care with which we intend to treat your information. This policy describes the overall privacy practices of Toybot.app regarding information we collect on our network of web sites and through our other Internet advertising mediums, including but not limited to email, newsletters, and leave-behinds. This policy does not apply to the practices of companies that Toybot.app does not own or control, or to people that Toybot.app does not employ.</p>
		<p><strong>Information Collected by Toybot.app</strong></p>
		<p><strong>Non-Personally Identifiable Consumer Information</strong></p>
		<p>Toybot.app collects various types of information from you to better meet your needs and to provide you with a more relevant user experience. Some of this information is collected automatically through cookies (a small text file placed on your computer) or other Internet tracking technologies. The type of information your browser or Internet session may automatically provide us with can include the browser you are using, your Internet domain, the operating system on your computer, your IP address and the URL you saw just before coming to the Toybot.app site, the pages of the site you visited and the URL of the page you visited upon leaving the site.</p>
		<p>Toybot.app may also contract with reputable third-party advertising companies to serve ads on our behalf. These companies may also use cookies or other measures to collect your non-personally identifiable information.</p>
		<p><strong>Personally Identifiable Consumer Information</strong></p>
		<p>In addition, at some of the Toybot.app web sites, we collect information that you may choose to provide to us when requesting additional information from us or from one or more of our clients. Examples of personally identifiable data that you may provide to us include name, address, and email address. If you provide personally identifiable data to us, you may later elect to have your information deleted from our files, or kept from being used for purposes other than for the service requested, by following the opt-out procedures set forth below.</p>
		<p><strong>How Collected Information is Used</strong></p>
		<p>The non-personally identifiable information that Toybot.app collects is used for marketing and sales efforts to better target advertisements and other content in an effort to create a more personally relevant experience. The information is also used to predict responses to advertisements, and to help determine which ads perform best and which content is most appropriate for different individuals.</p>
		<p>If you elect to provide Toybot.app with personally identifiable information, we may utilize such information for marketing purposes, and this use may result in follow-up communications from Toybot.app and from our clients. In addition, that information may be combined with information collected from other sources to assist with targeting ads to your preferences and behaviors, and for other Toybot.app sales and marketing efforts.</p>
		<p><strong>Sharing Information with Third Parties</strong></p>
		<p>All personally identifiable information you provide is maintained in confidence by Toybot.app. There are instances, however, in which your personally identifiable and non-personally identifiable information is shared with third parties, as described below.</p>
		<p>Your non-personally identifiable data may be shared with third-party partners of Toybot.app on an anonymous basis.</p>
		<p>When you request information from Toybot.app or one or more of our clients, we may transfer that information within Toybot.app or route your information to our client(s), across borders and from your country or jurisdiction to other countries or jurisdictions around the world, so that they may provide the information on their product or service that you requested.</p>
		<p>We may also disclose information to an affiliate or third party who provides substantially similar services as the original service about which you requested more information. In such situations, we will provide you with an opportunity to opt-out of such sharing. Please be aware that Toybot.app is very careful in selecting companies with whom it does business.</p>
		<p>In addition to the above situations where your information may be shared with others, there is also the possibility that Toybot.app, or part(s) of the company, may one day be purchased by, or merged with, another company. In the event of such transaction, the acquiring (or merging) company will have access to all collected personally identifiable information.</p>
		<p>Finally, Toybot.app may share your information as necessary to comply with a court order or to cooperate with government and law enforcement officials.</p>
		<p><strong>Security</strong></p>
		<p>Wherever your personally identifiable information may be held within Toybot.app or on its behalf, we intend to take reasonable and appropriate steps to protect the information you shared with us from unauthorized access or disclosure. Please note that while Toybot.app will make every reasonable effort to protect your information, no transmission of data over the Internet is 100% secure.</p>
		<p><strong>Choice</strong></p>
		<p><strong>Non-Personally Identifiable Consumer Information - Use of Cookies &amp; Opting-Out</strong></p>
		<p>Some of our web sites utilize cookies to collect information about your website activities and to recall the personal information you previously shared with us. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them.</p>
		<p>You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser. Blocking cookies may result in a lower quality experience for you while you are visiting Toybot.app web sites.</p>
		<p><strong>Personally Identifiable Consumer Information - Opting-Out</strong></p>
		<p>You may choose whether or not to provide personally identifiable information to Toybot.app. If you choose to provide such information to Toybot.app, you have the right to tell us not to make some of the uses of such information described herein or may have such information deleted in its entirety. If you no longer wish to receive our newsletters, surveys or promotional communications via email, you may opt-out without charge by emailing hello@toybot.app  and following the instructions to unsubscribe. To have your personal information completely removed from our databases without charge, simply contact us at hello@toybot.app  and provide the name of the service for which information was provided, your full name, mailing address, phone number and email address so that we may identify you in the opt-out process. Once we receive your instruction, we will promptly take corrective action.</p>
		<p><strong>Access/Accuracy</strong></p>
		<p>To the extent that you do provide us with personally identifiable information, Toybot.app wishes to maintain the accuracy of this information. Where we collect personally identifiable information from you on the Web, our goal is to provide a means of contacting Toybot.app should you need to update or correct that information. If for any reason those means are unavailable or inaccessible, you may send updates and corrections about your personally identifiable information to hello@toybot.app  and we will make reasonable efforts to incorporate the changes in the information that we hold as soon as practicable.</p>
		<p><strong>Children's Privacy</strong></p>
		<p>In compliance with the Children's Online Privacy Protection Act (COPPA), Toybot.app is not structured to attract children. Accordingly, we do not intend to collect personally identifiable information from anyone we know to be under 13 years of age</p>
		<p><strong>Refer a Friend</strong></p>
		<p>Some of Toybot.app's web sites offer you the opportunity to easily notify a friend of the offer or information promoted on the site. If you choose to use our referral service to tell a friend about a Toybot.app site, we will ask you for your friend's personally identifiable information, including name and email address. We will automatically send your friend a one-time email inviting him or her to visit the site. Toybot.app stores this information for the sole purpose of sending this one-time email and tracking the success of our referral program.</p>
		<p>Your friend may contact us at hello@toybot.app  to request that we remove this information from our database. Our one-time email to your friend will inform him or her of this fact.</p>
		<p><strong>Commitment</strong></p>
		<p>Protecting your privacy online is an evolving area, and Toybot.app's web sites are constantly changing to meet these demands. If you have any comments or questions regarding our Online Privacy Policy, please contact us at hello@toybot.app . While we cannot guarantee privacy perfection, we will address any issue to the best of our abilities as soon as practicable after you bring it to our attention.</p>
		<p><strong>Your Consent</strong></p>
		<p>By using this web site, you consent to the terms of our Online Privacy Policy and to Toybot.app's processing of personally identifiable information for the purposes given above. Should the Online Privacy Policy change, we intend to take every reasonable step to ensure that these changes are brought to your attention by posting all changes prominently on our web site for a reasonable period of time and by amending this privacy policy.</p>
	</div>  
  </section>
</main>


         

        </Fragment>
    );
}
export default Privacy;