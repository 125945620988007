import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';

// interface Props {
//   className?: string;
//   onChange?: (value: number) => void;
//   value?: number;
// }

const Slider2 = (props: any) => {
  const line = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState(false);
  const [curVal, setCurVal] = useState(0);

  const onDrag = (e: MouseEvent | TouchEvent) => {
    if (focus && props.onChange) {
      const position = 'touches' in e ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;
      const lineElement = line.current;

      if (lineElement) {
        const { left, width } = lineElement.getBoundingClientRect();
        const val = Math.min(1, Math.max(0, (position - left) / width));
        setCurVal(val);
        props.onChange(val);
      }

      if ('preventDefault' in e) {
        (e as MouseEvent).preventDefault();
      }
    }
  };

  const onStop = () => {
    setFocus(false);
  };

  const onStart = (e: MouseEvent | TouchEvent) => {
    setFocus(true);
    onDrag(e);
  };

  useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      onStop();
    };

    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
      onDrag(e);
    };

    window.addEventListener('mouseup', handleMouseUp, { passive: false });
    window.addEventListener('mousemove', handleMouseMove, { passive: false });
    window.addEventListener('touchmove', handleMouseMove, { passive: false });
    window.addEventListener('touchend', handleMouseUp, { passive: false });

    const lineElement = line.current;
    if (lineElement) {
      lineElement.addEventListener('mousedown', onStart);
      lineElement.addEventListener('touchstart', onStart);
    }

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);

      const lineElement = line.current;
      if (lineElement) {
        lineElement.removeEventListener('mousedown', onStart);
        lineElement.removeEventListener('touchstart', onStart);
      }
    };
  }, [onDrag, onStart]);

  return (
    <div className={cn('absolute-zoom-cropper-slider', props.className)} ref={line}>
      <div className="absolute-zoom-cropper-slider__line">
        <div
          className="absolute-zoom-cropper-slider__fill"
          style={{
            flexGrow: curVal,
          }}
        />
        <div
          className={cn(
            'absolute-zoom-cropper-slider__circle',
            focus && 'absolute-zoom-cropper-slider__circle--focus',
          )}
          style={{
            left: `${curVal * 100}%`,
          }}
        >
          <div
            className={cn(
              'absolute-zoom-cropper-slider__inner-circle',
              focus && 'absolute-zoom-cropper-slider__inner-circle--focus',
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Slider2;
