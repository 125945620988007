import React, {Fragment, useEffect, useState} from "react";
import { backend_api_url, getUserSessionData, cluster, settingsSlider } from "./constants";
import { useConnection } from "@solana/wallet-adapter-react";
import { Keypair } from "@solana/web3.js";
import { Metaplex, PublicKey, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import { toast } from "react-toastify";
import Slider from "react-slick";
import { useParams } from "react-router-dom";

const RecentlyJoined = (props: any) => {
 
    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)
    const [num, setNum] = useState(0)


    const { connection } = useConnection();
    let params = useParams(); 

    const [renderedElements, setRenderedElements] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const delay = 100; // Adjust the delay (in milliseconds) between rendering elements

        const timer = setTimeout(() => {
        if (listingJsx && currentIndex < listingJsx.length) {
            setRenderedElements((prevElements) => [...prevElements, listingJsx[currentIndex]]);
            setCurrentIndex(currentIndex + 1);
        }
        }, delay);

        return () => clearTimeout(timer);
    }, [currentIndex, listingJsx]);

    const viewRecentlyJoined = async ()  => {


        const params = { 
            
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/recently-joined', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    
                    displayRecentlyJoined(data.users);

                }
            });
            return;
        
    }

    const searchUsers = (search)  => {


        const params = { 
            search: search,
            type: 'user'
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/search', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    
                    displayRecentlyJoined(data.users);

                }
            });
            return;
        
    }
    const displayRecentlyJoined = async(users : any) => {

         
        setNum(users.length);
        setListingJsx( users.map( (userInfo, index) => <Fragment key={index + (new Date()).getTime()}>
                        <a style={{"textAlign" : "center"}} className={props.type === 'search' ? 'user-avatar-image percent20' : 'imgSliderDetails user-avatar-image'} rel="noreferrer" href={'/user/'+userInfo.username} >
                        <img alt="" src={userInfo.avatar} /><br />{userInfo.username}</a></Fragment>) );

    }

    useEffect(() => {
        if(props.type === 'search') {
            searchUsers(props.search);
        }else{
		    viewRecentlyJoined();
        }
	  }, []);
 
    
      settingsSlider.infinite = num >= 5 ? true : false;
    
      //console.log(settingsSlider);

    return (

        <Fragment>
            {props.type === 'search' && (listingJsx === null || listingJsx.length === 0) && <div style={{"position": "relative", "top": "-10px", "marginBottom" : "10px", "fontSize" : "18px"}}>No Results</div>}

            {
                props.type === 'search' && renderedElements
            }
 
             {
                props.useCarousel && listingJsx && props.type !== 'search' && 
                <Slider  {...settingsSlider}>
                        {
                            listingJsx
                        }


                </Slider>
            } 

{
                !props.useCarousel && listingJsx && props.type !== 'search' && 
                renderedElements
            } 
        </Fragment>
    );
}

export default RecentlyJoined;