import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import {cluster, getClientOnRampSecret, getPrivateKeyBase58, getUserSessionData, isUserLoggedIn} from "./constants";
import Modal from 'react-bootstrap/Modal';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const TestPaypal = (props : any) => {

     
    const [secret, setSecret] = useState<string>('');
    const [showStripe, setShowStripe] = useState(false);
    const handleCloseStripe = () => setShowStripe(false);
    const handleShowStripe = () => setShowStripe(true);

    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    
    const [mintError, setMintError] = useState("");
    const [mintErrorHeader, setMintErrorHeader] = useState("");

    const openPaypal = async () => {
        handleShowStripe();
    }

    const showSuccessPayment = () => {
        handleCloseStripe();
        setMintErrorHeader('Notification');
        setMintError('Payment Successful');
        handleShowError();
    }
    const amountInput = useRef<HTMLInputElement>(null);

 
const initialOptions = {
    clientId: "Acux5fCC29X6ICvxsvCuBBHPm1BR2xCutIC_l9QNFt2ur7ryopn41wvf4i0Wi-ihqtRC5vwD-4dTZlg8",
    currency: "USD",
    intent: "authorize",
};
let actionsTemp;

const createNow = () => {
    onCreateOrder(null, actionsTemp);
}

const capturePaypal = () => {
    console.log(actionsTemp);

    if(actionsTemp) {
        actionsTemp.order.capture().then((details) => {
            // const name = details.payer.name.given_name;
            console.log(details);
            // alert(`Transaction completed by ${name}`);
            
            console.log(details.id);
            console.log(details.status); 
            
        });
    }
}
 
const onCreateOrder = (data,actions) => {
    
    return actions.order.create({
        purchase_units: [
            {
                amount: {
                    value: amountInput.current.value, // "8.99",
                },
            },
        ],
    });
}

const onApproveOrder = (data,actions) => {

    return actions.order.authorize().then((details) => {
        // const name = details.payer.name.given_name;
        console.log(details);
        // alert(`Transaction completed by ${name}`);
        
        console.log(details.id);
        console.log(details.status); 
        
        actionsTemp = actions;
    });

return actions.order.capture().then((details) => {
// const name = details.payer.name.given_name;
console.log(details);
// alert(`Transaction completed by ${name}`);

console.log(details.id);
console.log(details.status);
handleCloseStripe();

});
}



    return (
        <Fragment>
             <button className="buttonLink btn-logout" onClick={openPaypal}>Do Paypal Payment</button>

             <button className="buttonLink btn-logout" onClick={capturePaypal}>Capture Payment</button>

            
             <Modal className="modal-error" show={showError} onHide={handleCloseError} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>{mintErrorHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                    <div>
                        {mintError}
                    </div>
    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 
            
                <Modal className="modal-payment" size="sm" show={showStripe} onHide={handleCloseStripe} backdrop="static">
                <Modal.Header closeButton={true}>
                {/* <Modal.Title>Notification</Modal.Title> */}
                </Modal.Header>
                    <Modal.Body>
                    <div style={{ "textAlign" : "center"}}>

                    <button className="buttonLink btn-logout" onClick={capturePaypal}>Capture Payment</button>

                    <input type="text" className="textbox" required ref={amountInput} defaultValue={100} />

                    <button onClick={createNow}>Trigger Create</button>
                        {
                            <PayPalScriptProvider options={initialOptions}>
                            <PayPalButtons style={{ layout: "horizontal" }} 
                            fundingSource='paypal'
                            createOrder={(data, actions) => onCreateOrder(data, actions)}
                            onApprove={(data, actions) => onApproveOrder(data, actions)}
                            />
                        </PayPalScriptProvider>
                        }
                    </div>
        
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal> 

        </Fragment>

    );
}

export default TestPaypal;