import React, {Fragment, useEffect, useRef, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import {createAccount, getBalance, cluster, getConnectionConfig, getUserSessionData, backend_api_url} from "../constants";
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';

const Terms = (props: any) => {


    return (
        <Fragment>
        <main>
  <section className="headline">
	<h1>Terms &amp; Conditions</h1>
  </section>

  
  <section className="white">
	<div className="padding">
	
 <p> Updated: July 2020</p>	
 
 <p> We at Toybot.app and our affiliated companies worldwide are committed to respecting your online privacy and recognize your need for appropriate protection and management of any information you share with us by using our web sites.</p>

 <p>Thank you for accessing our website (the “Site”). This Site is an Internet property of Toybot.app  (“Company,” "we," “our,” or "us").  In this Terms &amp; Conditions of Use (“Terms”), “you,” "user" or "your" means you.  By using this site, you agree to comply with and be bound by the Terms, including the arbitration provisions under the section titled “Disputes.” Please review these Terms carefully. If you do not agree to these Terms, you should not use this site.</p>

 <p><strong>1. Agreement.</strong> These Terms describe the terms and conditions applicable to your access of and use of the Site. These Terms may be modified at any time by us upon posting of the modified Agreement. Any such modifications shall be effective immediately. You can view the most recent version of these Terms at any time at this Site. Each use by you shall constitute and be deemed your unconditional acceptance of these Terms.</p>

 <p><strong>2. Privacy.</strong> Your visit to our site is also governed by our Privacy Policy. Please review our Privacy Policy by clicking the “Privacy Policy” hyperlink near the bottom of the Site.</p>
 
 <p><strong>3. Ownership.</strong> All content included on this site is and shall continue to be the property of us or our content suppliers and is protected under applicable copyright, patent, trademark, and other proprietary rights. Any copying, redistribution, use or publication by you of any such content or any part of the Site is prohibited, except as expressly permitted in these Terms. Under no circumstances will you acquire any ownership rights or other interest in any content by or through your use of this Site.</p>
 
 <p><strong>4. Intended Audience.</strong> This Site is intended for adults only. This Site is not intended for and may not be used by any person under the age of 18.</p>
 
 <p><strong>5. Trademarks.</strong> This either trademarks or registered trademarks of us. Other product and company names mentioned on this Site may be trademarks of their respective owners.</p>
 
 <p><strong>6. Site Use.</strong> We grant you a limited, revocable, nonexclusive license to use this site solely for your own personal use and not for republication, distribution, assignment, sublicense, sale, preparation of derivative works, or other use. You agree not to copy materials on the site, reverse engineer or break into the site, or use materials, products or services in violation of any law. The use of this Site is at the discretion of us and we may terminate your use of this Site at any time.</p>
 
 <p><strong>7. Compliance with Laws.</strong> You agree to comply with all applicable laws regarding your use of the Site. You further agreed that information provided by you is truthful and accurate to the best of your knowledge.</p>
 
 <p><strong>8. Indemnification.</strong> You agree to indemnify, defend and hold us and our partners, employees, parents, subsidiaries, and affiliates, harmless from any liability, loss, claim and expense, including reasonable attorney's fees, related to your violation of these Terms or use of the Site.</p>
 
 <p><strong>9. Disclaimer.</strong> THE INFORMATION ON THIS SITE IS PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS. YOU AGREE THAT USE OF THIS SITE IS AT YOUR SOLE RISK. WE DISCLAIM ALL WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY EXPRESS WARRANTIES, STATUTORY WARRANTIES, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. TO THE EXTENT YOUR JURISDICTION DOES NOT ALLOW LIMITATIONS ON WARRANTIES, THIS LIMITATION MAY NOT APPLY TO YOU. YOUR SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE SITE SHALL BE TO DISCONTINUE USING THE SITE.</p>
 
 <p><strong>10. Limitation of Liability.</strong> UNDER NO CIRCUMSTANCES WILL WE BE LIABLE OR RESPONSIBLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN ANY WAY RELATING TO THE SITE, YOUR SITE USE, OR THE CONTENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE AND/OR CONTENT IS TO CEASE ALL OF YOUR SITE USE.</p>
 
 <p>You may have additional rights under certain laws (including consumer laws) which do not allow the exclusion of implied warranties, or the exclusion or limitation of certain damages. If these laws apply to you, the exclusions or limitations in these Terms that directly conflict with such laws may not apply to you.</p>
 
 <p><strong>11. Use of Information.</strong> We reserve the right, and you authorize us, to use and assign all information regarding site uses by you and all information provided by you in any manner consistent with our Privacy Policy.</p>
 
 <p><strong>12. Copyrights and Copyright Agent.</strong> If you believe your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have otherwise been violated, please provide a notice containing all of the following information to our Copyright Agent:<br /><br />
(a) An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;<br />
(b) A description of the copyrighted work that you claim has been infringed;<br />
(c) A description of where the material that you claim is infringing is located on the Site;<br />
(d) Your address, telephone number, and e-mail address;<br />
(e) A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and<br />
(f) A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
</p>

 <p>For notice of claims of copyright infringement, contact us as specified under the section titled “Contact Information.”</p>
 
 <p><strong>13. Disputes.  Any dispute or claim relating in any way to your use of this website, including any related calls texts or other communications, will be resolved by binding arbitration, rather than in court</strong>, except that you may assert claims in small claims court if your claims qualify.  <strong>This includes claims against our clients, affiliates, vendors, and marketing partners</strong>, which are third party beneficiaries of this arbitration agreement.  The Federal Arbitration Act and federal arbitration law apply to this agreement.</p>
 
 <p><strong>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the terms of these terms as a court would.</strong></p>
 
 <p>The arbitration may be conducted by the American Arbitration Association (AAA) under its rules, including the AAA's Supplementary Procedures for Consumer-Related Disputes. The AAA's rules are available at <a href="https://www.adr.org"> www.adr.org </a> or by calling 1-800-778-7879. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location.</p>
 
 <p><strong>We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action</strong>. If for any reason a claim proceeds in court rather than in arbitration <strong>we each waive any right to a jury trial</strong>. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.</p>
 
 <p><strong>14.  Applicable Law.</strong> You agree that the laws of the state of the defending party without regard to conflicts of laws provisions will govern these Terms and any dispute that may arise between you and us or our vendors, partners, employees, parents, subsidiaries, or affiliates.</p>
 
 <p><strong>15. Severability.</strong> If any provision of these Terms shall be adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect.</p>
 
 <p><strong>16. Waiver.</strong> The failure by us to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision.  Any waiver of these Terms by us must be in writing and signed by an authorized representative.</p>
 
 <p><strong>17. Termination.</strong> We may terminate these Terms at any time, with or without notice, for any reason.</p>
 
 <p><strong>18. Relationship of the Parties.</strong>  Nothing contained in these Terms or your use of the Site shall be construed to constitute either party as a partner, joint venturer, employee or agent of the other party, nor shall either party hold itself out as such.  Neither party has any right or authority to incur, assume or create, in writing or otherwise, any warranty, liability or other obligation of any kind, express or implied, in the name of or on behalf of the other party, it being intended by both parties that each shall remain independent contractors responsible for its own actions.</p>
 
 <p><strong>19. Entire Agreement.</strong> This Terms of Use constitutes the entire agreement between you and us and governs your use of the Site, and supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to this Site.  Notwithstanding the foregoing, you may also be subject to additional terms and conditions, posted policies (including but not limited to the Privacy Policy), guidelines, or rules that may apply when you use the Site.  We may revise these Terms at any time by updating these Terms and posting it on the Site.  Accordingly, you should visit the Site and review the Terms of Use periodically to determine if any changes have been made. Your continued use of this Site after any changes have been made to the Terms of Use signifies and confirms your acceptance of any such changes or amendments to the Terms of Use.</p>
 
 <p><strong>20. Contact Information.</strong> hello@toybot.app</p>
 
	</div>  
  </section>
</main>
         

        </Fragment>
    );
}
export default Terms;