import React, {Fragment, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom'; 
import Moment from 'react-moment';
import { CANDY_MACHINE_ADDRESS, TOKEN_PROGRAM_ID, backend_api_url, checkIfMintTransaction, checkIfPublishTransaction, cluster, getCurrentWallet, getExplorerUrl, getGachaMachineInfo, getUsdcMintAddress, getUserSessionData, isTransferNft } from "./constants";
import { useConnection } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL, Connection, clusterApiUrl, Keypair, PublicKey} from "@solana/web3.js";
import * as splToken from "@solana/spl-token";
import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import { sign } from "crypto";

const LedgerItem = (props: any) => {

    const { connection } = useConnection();
    const [ledgerType, setLedgerType] =  useState<JSX.Element | null>(null)
    const [txnDate, setTxnDate] = useState('');
    const [fromWallet, setFromWallet] = useState<JSX.Element | null>(null)
    const [toWallet, setToWallet] = useState<JSX.Element | null>(null)

    const [amount, setAmount] = useState<JSX.Element | null>(null);
    const [balance, setBalance] = useState('');

    const [transactionType, setTransactionType]   = useState<JSX.Element | null>(null)

    const [isFound, setIsFound] = useState(false);
 
const [fromWalletRevShare, setFromWalletRevShare] = useState<JSX.Element | null>(null)
const [toWalletRevShare, setToWalletRevShare] = useState<JSX.Element | null>(null)
const [ledgerTypeRevShare, setLedgerTypeRevShare] =  useState<JSX.Element | null>(null)
const [amountRevShare, setAmountRevShare] = useState<JSX.Element | null>(null);

const [isSoldToOwn, setIsSoldToOwn] = useState(false);
const [transactionTypeSoldToOwn, setTransactionTypeSoldToOwn]   = useState<JSX.Element | null>(null)
const [amountRevShareSoldToOwn, setAmountRevShareSoldToOwn] = useState<JSX.Element | null>(null);

    const getMintAmountForCollection = async (candyMachineAddress) => {
        //setAmount(<Fragment>sample USDC</Fragment>);
        let gacha = await getGachaMachineInfo(candyMachineAddress);
        //console.log(gacha);
        if(gacha) {
            let amount = gacha.payment.amount / 1000000;
            setAmount(<Fragment>{amount.toFixed(5)} USDC</Fragment>);
        }
    }

    const getMintAmountForCollectionNew = async (candyMachineAddress, userWallet) => {
        //setAmount(<Fragment>sample USDC</Fragment>);
        

        const params = {
            address: candyMachineAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    let amount = data.price;

                    setAmount(<Fragment>$ {amount.toFixed(2)}</Fragment>);
  
                    if(data.ownerWallet === userWallet) {
                        ;//setIsSoldToOwn(true);
                    }
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+data.address+'?cluster='+ cluster}>{data.address}</a>);
                }else{
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+candyMachineAddress+'?cluster='+ cluster}>{candyMachineAddress}</a>);

                }


            });
        return;
    }

    const getCandyMachineAddress = async (collectionAddress, amountOfUsdc, userWallet) => {
        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    //if(amountOfUsdc < 0 || amountOfUsdc.toFixed(5) === '0.00000') {
                        let amount = data.price;

                        setAmount(<Fragment> {amount.toFixed(5)}  USDC</Fragment>);

                        //getMintAmountForCollection(data.address);
                    //} 
                    if(data.ownerWallet == userWallet) {
                        setIsSoldToOwn(true);
                    }
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+data.address+'?cluster='+ cluster}>{data.address}</a>);
                }else{
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+collectionAddress+'?cluster='+ cluster}>{collectionAddress}</a>);

                }


            });
        return;
    }

    const checkIfPreviousIsPublishCollection = (transaction, userWallet) => {

        let isPublish = false;
 
        if(transaction.prevTransaction) {
            const accountKeys = transaction.prevTransaction.transaction.transaction.message.staticAccountKeys;
            
            if(accountKeys[0].toBase58() === userWallet && accountKeys.length > 5) {
                for(var i in accountKeys) {
                    if(accountKeys[i].toBase58() === CANDY_MACHINE_ADDRESS) {
                        isPublish = true;
                    }
                }
            }
        }
        return isPublish;
    }

    const fetchLedgerInfo = async () => {
        const signature = props.signature;
         

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                try{

                    
                //const transactionData = await connection.getTransaction(signature);
                // const transactionData = await connection.getTransaction(signature, {
                //     maxSupportedTransactionVersion : 0
                // });
                
                const transactionData = props.transaction;

                const accountKeys = transactionData.transaction.message.staticAccountKeys;
              
                const fee = transactionData.meta.fee / LAMPORTS_PER_SOL;
                let userWallet = getCurrentWallet();
                

                setLedgerType(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('tx')+signature+'?cluster='+ cluster}>View</a>);

                if(signature === '2PAC9RZLVNmZhKzPwfkHoyXYKPEAGQ1iY1W7qjHQ9JwfNs1g2NrGWUBGWPvkygXuk1XzFHZYrFaLMY3MpYemw9wR') {
                    console.log('here123 - is transfer');
                    console.log(transactionData);
                    console.log(isTransferNft(transactionData.meta.logMessages) );
                    // console.log(accountKeys[4].toBase58());
                    // console.log(accountKeys[accountKeys.length - 1].toBase58());
                    // console.log(accountKeys.length );

                    for(var i in accountKeys) {
                        console.log(i+'. '+accountKeys[i].toBase58());
                    }
                }
 

                let isPublish = false;

                if(accountKeys[0].toBase58() === userWallet && accountKeys.length > 5) {
                    for(var i in accountKeys) {
                        if(accountKeys[i].toBase58() === CANDY_MACHINE_ADDRESS) {
                            isPublish = true;
                        }
                    }
                }
                if(!isPublish) {
                    //we check the messages
                    if(checkIfPublishTransaction(transactionData.meta.logMessages)) {
     
                        isPublish = true;
                    }
                }

                let usdcMintAddress = getUsdcMintAddress() ;

                //console.log(signature + ' - ' + accountKeys[accountKeys.length-1].toBase58());
                 
                if(false && transactionData.meta.postTokenBalances.length == 0) {
                    console.log(transactionData);
                    setIsFound(false);

                } else if(accountKeys.length >=3 && accountKeys[0].toBase58() === userWallet && accountKeys[2].toBase58() === '11111111111111111111111111111111'
            && isTransferNft(transactionData.meta.logMessages) === false ) {
                    const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;
                    const totalSol = amountOfSol - fee; // if sending, fee is deducted in the receiver amountOfSol + fee;

                    setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[1].toBase58()+'?cluster='+ cluster}>{accountKeys[1].toBase58()}</a>);
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[0].toBase58()+'?cluster='+ cluster}>{accountKeys[0].toBase58()}</a>);
                
                    setAmount(<Fragment>{totalSol.toFixed(5)} SOL</Fragment>);
                    setBalance( (transactionData.meta.postBalances[0]  / LAMPORTS_PER_SOL).toFixed(5) );

                    setTransactionType(<Fragment>Send SOL</Fragment>);
                    console.log('ggg=== ' + (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]));
                    console.log('ggg=== ' + (isTransferNft(transactionData.meta.logMessages)));

                    if(props.prevTransaction) {
                    
                        for(var i in props.prevTransaction.transaction.transaction.message.staticAccountKeys) {
                            //console.log(props.signature + "   "  + props.prevTransaction.transaction.transaction.message.staticAccountKeys[i].toBase58());
                            if(props.prevTransaction.transaction.transaction.message.staticAccountKeys[i].toBase58() == TOKEN_PROGRAM_ID) {
                                if(checkIfPreviousIsPublishCollection(props.prevTransaction, userWallet))
                                    setTransactionType(<Fragment>Publish Collection Deposit</Fragment>);
                            }
                        }
                    }

                    setIsFound(true);
                }else if(isPublish) {
                   //const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;
                   const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;
//&& accountKeys.length > 5
                   const totalSol = amountOfSol ;//+ fee;
                   //console.log('Posting used ' + totalSol + ' SOLs from ' + accountKeys[0].toBase58() );
 

                   setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[accountKeys.length - 1].toBase58()+'?cluster='+ cluster}>{accountKeys[accountKeys.length - 1].toBase58()}</a>);
                   setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[0].toBase58()+'?cluster='+ cluster}>{accountKeys[0].toBase58()}</a>);

                    setAmount(<Fragment>{totalSol.toFixed(5)} SOL</Fragment>);

                    setBalance( (transactionData.meta.postBalances[0]  / LAMPORTS_PER_SOL).toFixed(5) );
                    setTransactionType(<Fragment>Publish Collection</Fragment>);
                    setIsFound(true);
                } else if(accountKeys.length > 5 && accountKeys[5].toBase58() === userWallet && checkIfMintTransaction(transactionData.meta.logMessages)) {
                    setToWallet(<a  target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[5].toBase58()+'?cluster='+ cluster}>{accountKeys[5].toBase58()}</a>);
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[0].toBase58()+'?cluster='+ cluster}>{accountKeys[0].toBase58()}</a>);

                    const balance = (transactionData.meta.postBalances[5] ) / LAMPORTS_PER_SOL;
                    setBalance( balance.toFixed(5)  );
                    setBalance ('-');
                    setTransactionType(<Fragment><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('tx')+signature+'?cluster='+ cluster}>Mint NFT</a></Fragment>);
 
                    //setAmount(<Fragment>{mintAmount}</Fragment>);

                    await getMintAmountForCollectionNew(accountKeys[0].toBase58(), userWallet);

                    setIsFound(true);
                }else if(accountKeys[accountKeys.length-1].toBase58() === userWallet) {
                    //const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;
                    const amountOfSol =  (transactionData.meta.postBalances[1] - transactionData.meta.preBalances[1]  )/ LAMPORTS_PER_SOL;

                    //    console.log(transactionData);
                    //console.log('Received ' + amountOfSol + ' SOLs from ' + accountKeys[0].toBase58() );
                    setToWallet(<a  target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[accountKeys.length-1].toBase58()+'?cluster='+ cluster}>{accountKeys[accountKeys.length-1].toBase58()}</a>);
                    //  setAmount(<Fragment>{amountOfSol}</Fragment>);

                     setAmount(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[1].toBase58()+'?cluster='+ cluster}>{amountOfSol.toFixed(5)} USDC</a>);

                     //setAmount(<Fragment>{amountOfUsdc.toFixed(5)} USDC</Fragment>);

                    const balance = (transactionData.meta.postBalances[accountKeys.length-1] ) / LAMPORTS_PER_SOL;
                    setBalance( balance.toFixed(5)  );
                    setTransactionType(<Fragment>Mint NFT</Fragment>);
                    setIsFound(true);

                    

                     //console.log(props.nextTransaction.transaction.meta.postTokenBalances[1].mint == getUsdcMintAddress());

                    let postBalance;
                    let preBalance; 

                    let postBalanceRevShare;
                    let preBalanceRevShare;
                    
                    let postBalancePrev;
                    let preBalancePrev;

                    let revShareAmount = 0;
                    if(props.nextTransaction) {
                        for(var i in props.nextTransaction.transaction.meta.postTokenBalances) {
                            if(props.nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress && 
                            props.nextTransaction.transaction.meta.postTokenBalances[i].owner == userWallet 
                            ) {
                                postBalance = props.nextTransaction.transaction.meta.postTokenBalances[i];
                                break;
                            }
                        }
                        for(var i in props.nextTransaction.transaction.meta.postTokenBalances) {
                            if(props.nextTransaction.transaction.meta.preTokenBalances[i].mint == usdcMintAddress && 
                            props.nextTransaction.transaction.meta.preTokenBalances[i].owner == userWallet 
                            ) {
                                preBalance = props.nextTransaction.transaction.meta.preTokenBalances[i];
                                break;
                            }
                        }

                        for(var i in props.nextTransaction.transaction.meta.postTokenBalances) {
                            if(props.nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress
                            ) {
                                postBalanceRevShare = props.nextTransaction.transaction.meta.postTokenBalances[i];
                            }
                        }
                        for(var i in props.nextTransaction.transaction.meta.preTokenBalances) {
                            if(props.nextTransaction.transaction.meta.preTokenBalances[i].mint == usdcMintAddress
                            ) {
                                preBalanceRevShare = props.nextTransaction.transaction.meta.preTokenBalances[i];
                            }
                        } 
                    }

                    if(props.prevTransaction) {
                        for(var i in props.prevTransaction.transaction.meta.postTokenBalances) {
                            if(props.prevTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress && 
                            props.prevTransaction.transaction.meta.postTokenBalances[i].owner == userWallet 
                            ) {
                                postBalancePrev = props.prevTransaction.transaction.meta.postTokenBalances[i];
                                break;
                            }
                        }
                        for(var i in props.prevTransaction.transaction.meta.postTokenBalances) {
                            if(props.prevTransaction.transaction.meta.preTokenBalances[i].mint == usdcMintAddress && 
                            props.prevTransaction.transaction.meta.preTokenBalances[i].owner == userWallet 
                            ) {
                                preBalancePrev = props.prevTransaction.transaction.meta.preTokenBalances[i];
                                break;
                            }
                        }
 
                    }
 
                    if(postBalance && preBalance) {
                        // setTransactionType("Send USDC");
                            // console.log('123');

                        // const amountOfSol1 =  (transactionData.meta.preTokenBalances[0].uiTokenAmount.uiAmount - transactionData.meta.postTokenBalances[0].uiTokenAmount.uiAmount  );
                        // const totalSol1 = amountOfSol1;// - fee; 
                        //console.log(props.nextTransaction);

                        if(postBalanceRevShare && preBalanceRevShare) {
                            revShareAmount = postBalanceRevShare.uiTokenAmount.uiAmount - preBalanceRevShare.uiTokenAmount.uiAmount;
                        }
                        setTransactionType(<Fragment>Mint <a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[1].toBase58()+'?cluster='+ cluster}>NFT</a></Fragment>);

                        let amountOfUsdc = preBalance.uiTokenAmount.uiAmount - postBalance.uiTokenAmount.uiAmount;
                        
                        //amountOfUsdc = amountOfUsdc - revShareAmount;
                        if(amountOfUsdc.toFixed(5) == '0.00000' && postBalancePrev && preBalancePrev) {
                            amountOfUsdc = revShareAmount + (postBalancePrev.uiTokenAmount.uiAmount - preBalancePrev.uiTokenAmount.uiAmount) ; //+ revShareAmount;

                            setTransactionType(<Fragment>Mint <a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[1].toBase58()+'?cluster='+ cluster}>NFT</a></Fragment>);

                        }

                        //we set the rev share 
                        let toybotAppWallet = '';
                        for(var i in props.nextTransaction.transaction.meta.postTokenBalances) {
                            if(props.nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress
                            ) {
                                toybotAppWallet = props.nextTransaction.transaction.meta.postTokenBalances[i].owner;
                            }
                        }

                        setFromWalletRevShare(<Fragment><br /><span><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+userWallet+'?cluster='+ cluster}>{userWallet}</a></span></Fragment>);
                        setToWalletRevShare(<Fragment><br /><span><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+toybotAppWallet+'?cluster='+ cluster}>{toybotAppWallet}</a></span></Fragment>);

                        setAmountRevShareSoldToOwn(<Fragment><br />{'-' + revShareAmount.toFixed(5)} USDC</Fragment>);

                        setAmount(<Fragment>{amountOfUsdc.toFixed(5)} USDC</Fragment>);

                        setFromWallet(<Fragment></Fragment>);


                        setBalance( (postBalance.uiTokenAmount.uiAmount ).toFixed(5) );
                        //console.log('Minted nft');
                        //console.log("Getting data");

                        const keypair = Keypair.generate();
      
                        const metaplex = new Metaplex(connection);
                        metaplex.use(keypairIdentity(keypair));
                        const allData = await metaplex.nfts().findByMint({ "mintAddress" : new PublicKey(accountKeys[1].toBase58())});

                        // if(amountOfUsdc.toFixed(5) === '0.00000') {
                        //     setIsFound(false);
                        // }  
                        //if('2jTZqEwHGCtCTGkKad2RN11DSTj6maE83snTm9k7KvUa' == accountKeys[1].toBase58()) {
                            // console.log('ALL data' + accountKeys[1].toBase58());
                            // console.log(allData.collection.address.toBase58());
                            //console.log(allData);
                            await getCandyMachineAddress(allData.collection.address.toBase58(), amountOfUsdc, userWallet);
                        //}

                        
                        setTransactionTypeSoldToOwn(<Fragment>Sold <a target="_blank" className="viewTransaction type-own" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[1].toBase58()+'?cluster='+ cluster}>NFT</a></Fragment>);

                    }else{
                        setIsFound(false);

                    }

                }
                else if(
                    accountKeys[2].toBase58() === userWallet && 
                 ( (accountKeys.length > 13 && usdcMintAddress == accountKeys[13].toBase58())
                 || (accountKeys.length > 24 && accountKeys[24].toBase58() === '11111111111111111111111111111111')
                 || (accountKeys.length > 14 && accountKeys[14].toBase58() === '11111111111111111111111111111111')
                 || (accountKeys.length > 15 && accountKeys[15].toBase58() === '11111111111111111111111111111111' && usdcMintAddress == accountKeys[14].toBase58())
                 ))  {
                    //const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;
                    const amountOfSol =  (transactionData.meta.postTokenBalances[1] - transactionData.meta.preTokenBalances[1]  )/ LAMPORTS_PER_SOL;
 
 
  
                    setBalance( '' );
                    setIsFound(true);
                     
                    let postBalance;
                    let preBalance; 

                    let postBalanceRevShare;
                    let preBalanceRevShare;

                    if(props.nextTransaction) {
                        for(var i in props.nextTransaction.transaction.meta.postTokenBalances) {
                            if(props.nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress && 
                            props.nextTransaction.transaction.meta.postTokenBalances[i].owner == userWallet 
                            ) {
                                postBalance = props.nextTransaction.transaction.meta.postTokenBalances[i];
                                break;
                            }
                        }
                        for(var i in props.nextTransaction.transaction.meta.postTokenBalances) {
                            if(props.nextTransaction.transaction.meta.preTokenBalances[i].mint == usdcMintAddress && 
                            props.nextTransaction.transaction.meta.preTokenBalances[i].owner == userWallet 
                            ) {
                                preBalance = props.nextTransaction.transaction.meta.preTokenBalances[i];
                                break;
                            }
                        }

                        for(var i in props.nextTransaction.transaction.meta.postTokenBalances) {
                            if(props.nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress
                            ) {
                                postBalanceRevShare = props.nextTransaction.transaction.meta.postTokenBalances[i];
                            }
                        }
                        for(var i in props.nextTransaction.transaction.meta.preTokenBalances) {
                            if(props.nextTransaction.transaction.meta.preTokenBalances[i].mint == usdcMintAddress
                            ) {
                                preBalanceRevShare = props.nextTransaction.transaction.meta.preTokenBalances[i];
                            }
                        } 
                    }

                    if(postBalance && preBalance) {
                        let revShareAmount = 0;
                        let soldUsdcAmount = postBalance.uiTokenAmount.uiAmount - preBalance.uiTokenAmount.uiAmount;

                        if(postBalanceRevShare && preBalanceRevShare) {
                            revShareAmount = postBalanceRevShare.uiTokenAmount.uiAmount - preBalanceRevShare.uiTokenAmount.uiAmount;
                        }
                        soldUsdcAmount = soldUsdcAmount + revShareAmount;
                         

                        setFromWallet(<a  target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+props.nextTransaction.transaction.meta.postTokenBalances[0].owner+'?cluster='+ cluster}>{props.nextTransaction.transaction.meta.postTokenBalances[0].owner}</a>);
                        setToWallet(<a  target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+transactionData.meta.postTokenBalances[0].owner+'?cluster='+ cluster}>{transactionData.meta.postTokenBalances[0].owner}</a>);

                        let toybotAppWallet = '';
                        for(var i in props.nextTransaction.transaction.meta.postTokenBalances) {
                            if(props.nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress
                            ) {
                                toybotAppWallet = props.nextTransaction.transaction.meta.postTokenBalances[i].owner;
                            }
                        }
                        setFromWalletRevShare(<Fragment><br /><span><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+props.nextTransaction.transaction.meta.postTokenBalances[0].owner+'?cluster='+ cluster}>{props.nextTransaction.transaction.meta.postTokenBalances[0].owner}</a></span></Fragment>);
                        setToWalletRevShare(<Fragment><br /><span><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+toybotAppWallet+'?cluster='+ cluster}>{toybotAppWallet}</a></span></Fragment>);


                        setAmount(<Fragment>{soldUsdcAmount.toFixed(5)} USDC</Fragment>);
                        setAmountRevShare(<Fragment><br />{'-' + revShareAmount.toFixed(5)} USDC</Fragment>);
                        setBalance( postBalance.uiTokenAmount.uiAmount.toFixed(5) );
                        
                        let nftAddress = '';
                        for(var i in transactionData.meta.postTokenBalances) { 
                            if(transactionData.meta.postTokenBalances[i].uiTokenAmount.uiAmount === 1 
                                && transactionData.meta.postTokenBalances[i].mint !== usdcMintAddress
                            ) {
                                nftAddress = transactionData.meta.postTokenBalances[i].mint;
                                break;
                            }
                        }
                        setTransactionType(<Fragment>Sold <a  target="_blank" className="viewTransaction type-1" rel="noreferrer" href={getExplorerUrl('address')+nftAddress+'?cluster='+ cluster}>NFT</a></Fragment>);

                        if(soldUsdcAmount == 0 && revShareAmount == 0) {
                            setIsFound(false);
                        }
                    }else{
                        setIsFound(false);

                    }
                }else if(accountKeys.length > 21 && accountKeys[21].toBase58() === userWallet
                && (usdcMintAddress === accountKeys[14].toBase58())
                && (accountKeys.length > 24 && accountKeys[24].toBase58() === '11111111111111111111111111111111')
                 ) {
                    setTransactionType(<Fragment>Sold NFT</Fragment>);


                    let nftAddress = '';
                    for(var i in transactionData.meta.postTokenBalances) { 
                        if(transactionData.meta.postTokenBalances[i].uiTokenAmount.uiAmount === 1 
                            && transactionData.meta.postTokenBalances[i].mint !== usdcMintAddress
                        ) {
                            nftAddress = transactionData.meta.postTokenBalances[i].mint;
                            break;
                        }
                    }
                    setTransactionType(<Fragment>Sold <a target="_blank" className="viewTransaction type-2" rel="noreferrer" href={getExplorerUrl('address')+nftAddress+'?cluster='+ cluster}>NFT</a></Fragment>);


                    let postBalance;
                    let preBalance; 

                    let postBalanceRevShare;
                    let preBalanceRevShare;

                    for(var i in transactionData.meta.postTokenBalances) {
                        if(transactionData.meta.postTokenBalances[i].mint == usdcMintAddress && 
                            transactionData.meta.postTokenBalances[i].owner == userWallet 
                        ) {
                            postBalance = transactionData.meta.postTokenBalances[i];
                            break;
                        }
                    }
                    for(var i in transactionData.meta.postTokenBalances) {
                        if(transactionData.meta.preTokenBalances[i].mint == usdcMintAddress && 
                            transactionData.meta.preTokenBalances[i].owner == userWallet 
                        ) {
                            preBalance = transactionData.meta.preTokenBalances[i];
                            break;
                        }
                    }

                    for(var i in transactionData.meta.postTokenBalances) {
                        if(transactionData.meta.postTokenBalances[i].mint == usdcMintAddress
                        ) {
                            postBalanceRevShare = transactionData.meta.postTokenBalances[i];
                        }
                    }
                    for(var i in transactionData.meta.preTokenBalances) {
                        if(transactionData.meta.preTokenBalances[i].mint == usdcMintAddress
                        ) {
                            preBalanceRevShare = transactionData.meta.preTokenBalances[i];
                        }
                    } 


                    if(postBalance && preBalance) {
                        let revShareAmount = 0;
                        let soldUsdcAmount = postBalance.uiTokenAmount.uiAmount - preBalance.uiTokenAmount.uiAmount;

                        if(postBalanceRevShare && preBalanceRevShare) {
                            revShareAmount = postBalanceRevShare.uiTokenAmount.uiAmount - preBalanceRevShare.uiTokenAmount.uiAmount;
                        }
                        soldUsdcAmount = soldUsdcAmount + revShareAmount;
                         
                        if(soldUsdcAmount > 0) {
                            setAmount(<Fragment>{soldUsdcAmount.toFixed(5)} USDC</Fragment>);
                            setAmountRevShare(<Fragment><br />{'-' + revShareAmount.toFixed(5)} USDC</Fragment>);

                            setFromWallet(<a  target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+transactionData.meta.postTokenBalances[0].owner+'?cluster='+ cluster}>{transactionData.meta.postTokenBalances[0].owner}</a>);
                            setToWallet(<a  target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+userWallet+'?cluster='+ cluster}>{userWallet}</a>);

                            let toybotAppWallet = '';
                            for(var i in transactionData.meta.postTokenBalances) {
                                if(transactionData.meta.postTokenBalances[i].mint == usdcMintAddress
                                ) {
                                    toybotAppWallet = transactionData.meta.postTokenBalances[i].owner;
                                }
                            }
                            setFromWalletRevShare(<Fragment><br /><span><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+transactionData.meta.postTokenBalances[0].owner+'?cluster='+ cluster}>{transactionData.meta.postTokenBalances[0].owner}</a></span></Fragment>);
                            setToWalletRevShare(<Fragment><br /><span><a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+toybotAppWallet+'?cluster='+ cluster}>{toybotAppWallet}</a></span></Fragment>);




                            setBalance( postBalance.uiTokenAmount.uiAmount.toFixed(5) );
                            if(toybotAppWallet == '') {
                                setIsFound(false);
                            }else{
                                setIsFound(true);
                            }
                        }else{
                            setIsFound(false);
                        }
                        
                        
                    }else{
                        setIsFound(false);

                    } 
                }else if(accountKeys[1].toBase58() === userWallet) {
                    //const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;
                    const amountOfSol =  (transactionData.meta.postBalances[1] - transactionData.meta.preBalances[1]  )/ LAMPORTS_PER_SOL;

                    //    console.log(transactionData);
                    //console.log('Received ' + amountOfSol + ' SOLs from ' + accountKeys[0].toBase58() );
                    setToWallet(<a  target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[1].toBase58()+'?cluster='+ cluster}>{accountKeys[1].toBase58()}</a>);
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[0].toBase58()+'?cluster='+ cluster}>{accountKeys[0].toBase58()}</a>);
                     setAmount(<Fragment>{amountOfSol.toFixed(5)} SOL</Fragment>);

                    setBalance(  (transactionData.meta.postBalances[1]  / LAMPORTS_PER_SOL).toFixed(5) );
                    setTransactionType(<Fragment>Receive SOL</Fragment>);
                    if(amountOfSol > 0)
                        setIsFound(true);
                    else
                        setIsFound(false);
                }else if(accountKeys.length >=3 && accountKeys[0].toBase58() === userWallet 
                && isTransferNft(transactionData.meta.logMessages)) {

                    const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;
                    const totalSol = amountOfSol - fee; // if sending, fee is deducted in the receiver amountOfSol + fee;
                    //console.log(amountOfSol + ' Sending used ' + totalSol + ' SOLs from ' + accountKeys[0].toBase58() );
                    const toWallet = transactionData.meta.postTokenBalances.length > 0 ? transactionData.meta.postTokenBalances[1].owner : accountKeys[10].toBase58();
                    setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+toWallet+'?cluster='+ cluster}>{toWallet}</a>);
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+props.ownwallet+'?cluster='+ cluster}>{props.ownwallet}</a>);
                 
                     const nftToken = transactionData.meta.postTokenBalances.length > 0 ? transactionData.meta.postTokenBalances[0].mint : 'temp';

                     setAmount(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('tx')+signature+'?cluster='+ cluster}>NFT</a>);
  
                    setBalance( (transactionData.meta.postBalances[0]  / LAMPORTS_PER_SOL).toFixed(5) );

                    setTransactionType(<Fragment>Send NFT</Fragment>);

                    if(transactionData.meta.postTokenBalances.length > 0 && transactionData.meta.postTokenBalances[0].mint === getUsdcMintAddress()) {
                    
                        //onsole.log(transactionData);
                        const toWallet1 = transactionData.meta.postTokenBalances[0].owner;
                        setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+toWallet1+'?cluster='+ cluster}>{toWallet1}</a>);
                        

                        setTransactionType(<Fragment>Send USDC</Fragment>);

                        //const amountOfSol1 =  (transactionData.meta.preTokenBalances[0].uiTokenAmount.uiAmount - transactionData.meta.postTokenBalances[0].uiTokenAmount.uiAmount  );
                        const amountOfSol1 =  (transactionData.meta.postTokenBalances[1].uiTokenAmount.uiAmount);
                        const totalSol1 = amountOfSol1;// - fee; 

                        setBalance ( (totalSol1.toFixed(5)));

                        const amountTxn = (transactionData.meta.postTokenBalances[1].uiTokenAmount.uiAmount - transactionData.meta.preTokenBalances[1].uiTokenAmount.uiAmount)
                        setAmount( <Fragment>{(amountTxn ).toFixed(5) + " USDC"} </Fragment>);

                    }
                    setIsFound(true);
                }else if(accountKeys.length >=3 && accountKeys[0].toBase58() === userWallet 
                &&  accountKeys[accountKeys.length-1].toBase58() === splToken.TOKEN_PROGRAM_ID.toBase58()) {
                    const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;
                    const totalSol = amountOfSol - fee; // if sending, fee is deducted in the receiver amountOfSol + fee;
                    //console.log(amountOfSol + ' Sending used ' + totalSol + ' SOLs from ' + accountKeys[0].toBase58() );
                    const toWallet = transactionData.meta.postTokenBalances[1].owner;
                    setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+toWallet+'?cluster='+ cluster}>{toWallet}</a>);
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+props.ownwallet+'?cluster='+ cluster}>{props.ownwallet}</a>);
                 
                     const nftToken = transactionData.meta.postTokenBalances[0].mint;

                     setAmount(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+nftToken+'?cluster='+ cluster}>NFT</a>);
 
                    setBalance( (transactionData.meta.postBalances[0]  / LAMPORTS_PER_SOL).toFixed(5) );

                    setTransactionType(<Fragment>Send NFT</Fragment>);

                    if(transactionData.meta.postTokenBalances[0].mint === getUsdcMintAddress()) {
                    
                        //onsole.log(transactionData);
                        const toWallet1 = transactionData.meta.postTokenBalances[0].owner;
                        setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+toWallet1+'?cluster='+ cluster}>{toWallet1}</a>);
                        

                        setTransactionType(<Fragment>Send USDC</Fragment>);

                        //const amountOfSol1 =  (transactionData.meta.preTokenBalances[0].uiTokenAmount.uiAmount - transactionData.meta.postTokenBalances[0].uiTokenAmount.uiAmount  );
                        const amountOfSol1 =  (transactionData.meta.postTokenBalances[1].uiTokenAmount.uiAmount);
                        const totalSol1 = amountOfSol1;// - fee; 

                        setBalance ( (totalSol1.toFixed(5)));

                        const amountTxn = (transactionData.meta.postTokenBalances[1].uiTokenAmount.uiAmount - transactionData.meta.preTokenBalances[1].uiTokenAmount.uiAmount)
                        setAmount( <Fragment>{(amountTxn ).toFixed(5) + " USDC"} </Fragment>);

                    }
                    setIsFound(true);
                }else if( ((accountKeys.length >=4 && accountKeys[4].toBase58() === userWallet)
                || (accountKeys.length >=5 && accountKeys[5].toBase58() === userWallet))
                && accountKeys[accountKeys.length-1].toBase58() === splToken.TOKEN_PROGRAM_ID.toBase58()) {
                    const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;
                    const totalSol = amountOfSol - fee; // if sending, fee is deducted in the receiver amountOfSol + fee;
                    //console.log(amountOfSol + ' Sending used ' + totalSol + ' SOLs from ' + accountKeys[0].toBase58() );

                    const fromWallet = transactionData.meta.postTokenBalances[0].owner;

                        setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+props.ownwallet+'?cluster='+ cluster}>{props.ownwallet}</a>);
                        setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+accountKeys[0].toBase58()+'?cluster='+ cluster}>{accountKeys[0].toBase58()}</a>);
                    
                        const nftToken = transactionData.meta.postTokenBalances[0].mint;

                    setAmount(<a target="_blank" className="viewTransaction" rel="noreferrer" href={getExplorerUrl('address')+nftToken+'?cluster='+ cluster}>NFT</a>);

                    setBalance( (transactionData.meta.postBalances[0]  / LAMPORTS_PER_SOL).toFixed(5) );

                    setTransactionType(<Fragment>Receive NFT</Fragment>);
 
                    if(transactionData.meta.postTokenBalances[0].mint === getUsdcMintAddress()) {
                        setTransactionType(<Fragment>Receive USDC</Fragment>);

                        const amountOfSol1 =  (transactionData.meta.preTokenBalances[0].uiTokenAmount.uiAmount - transactionData.meta.postTokenBalances[0].uiTokenAmount.uiAmount  );
                        const totalSol1 = amountOfSol1;// - fee; 
 
                        setAmount(<Fragment>{totalSol1.toFixed(5)} USDC</Fragment>);

                        setBalance( (transactionData.meta.postTokenBalances[0].uiTokenAmount.uiAmount ).toFixed(5) );

                    }
                    setIsFound(true);
                }else{
                    ;
                }

                // if(signature === '2Tgk12xq3uF76GnuMGAQTxw8ySWeJPTQmhp3Xev4mSARC4w63TY6emyjLRN4zDY3FSXqfXMaLBaTuZSecvpoKLxN') {
                        

                   
                //     console.log(transactionData);

                //     for(var i in accountKeys) {
                //         console.log(i+'. '+accountKeys[i].toBase58());
                //     }
                // }
                

                    const date = new Date(transactionData.blockTime * 1000);
                    let txDate = date.toLocaleDateString('en-ca').replaceAll('-', '.');
                    setTxnDate(txDate);
                    //console.log(props.signature + "=" + txDate + "=");
                }catch(e) {
                        
                    // if(signature === '57yff54BUamQEt4bMWFetfYnes1JZHJMewJ1aeVQnfh3xHq2XZT3KFTg4AVneuTW3DArKHUqbVeTaL47Dr5G2cKt') {
                    //     console.log('here123');
                         console.log(signature);
                         console.log(e);
                        
                    //     console.log(await connection.getTransaction(signature, {
                    //         maxSupportedTransactionVersion : 0
                    //     }));

                    // }
                }
            }
        }

    }

    useEffect(() => {
        fetchLedgerInfo();
        //{props.signature}
    }, []);
    return (
        <Fragment>
            {
                isFound && txnDate != '' && props.type === 'desktop' && 
                <tr data-id={props.signature} className={fromWalletRevShare ? 'revshare-row' : ''}>
                    <td align="center">{txnDate}
                    </td>
                    <td align="center" className="ellipsis"><span>{fromWallet}</span>
                    {fromWalletRevShare ? fromWalletRevShare  : ''}
                    </td>
                    <td align="center" className="ellipsis"><span>{toWallet}</span>
                    {toWalletRevShare ? toWalletRevShare  : ''}
                    </td>
                    <td align="center">{transactionType}</td>
                    <td align="center">{amount}
                    {amountRevShare ? amountRevShare  : ''}
                    </td>
                    <td align="center">{balance}</td>
                    <td align="center">{ledgerType}
                    {ledgerTypeRevShare ? ledgerTypeRevShare  : ''}
                    </td>
                    
                </tr>

                
            }

            {
                isSoldToOwn && txnDate != '' && props.type === 'desktop' && 
                <tr data-id={props.signature} className={fromWalletRevShare ? 'revshare-row' : ''}>
                    <td align="center">{txnDate}
                    </td>
                    <td align="center" className="ellipsis"><span>{fromWallet}</span>
                    {fromWalletRevShare ? fromWalletRevShare  : ''}
                    </td>
                    <td align="center" className="ellipsis"><span>{toWallet}</span>
                    {toWalletRevShare ? toWalletRevShare  : ''}
                    </td>
                    <td align="center">{transactionTypeSoldToOwn}</td>
                    <td align="center">{amount}
                    {amountRevShareSoldToOwn ? amountRevShareSoldToOwn  : ''}
                    </td>
                    <td align="center">{balance}</td>
                    <td align="center">{ledgerType}
                    {ledgerTypeRevShare ? ledgerTypeRevShare  : ''}
                    </td>
                    
                </tr>

                
            }

            {
                isFound && txnDate != '' && props.type === 'mobile' && 
                
                <Fragment>

                    <table className="walletActivityTable" style={{ "borderSpacing" : 0}}>
                    <tbody>
                    <tr>
                        <td width="40%"><b>TIME</b></td>
                        <td width="60%">{txnDate}</td>
                    </tr>

                    <tr className={fromWalletRevShare ? 'revshare-row' : ''}>
                        <td width="40%"><b>FROM</b></td>
                        <td width="60%" className="ellipsis" ><span style={{ "left" : "0px", "width" : "100%"}}>{fromWallet}</span>
                        {fromWalletRevShare ? fromWalletRevShare  : ''}</td>
                    </tr>

                    <tr className={fromWalletRevShare ? 'revshare-row' : ''}>
                        <td width="40%"><b>TO</b></td>
                        <td width="60%" className="ellipsis" ><span style={{ "left" : "0px", "width" : "100%"}}>{toWallet}</span>
                        {toWalletRevShare ? toWalletRevShare  : ''}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>TYPE</b></td>
                        <td width="60%">{transactionType}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>AMOUNT</b></td>
                        <td width="60%">{amount}
                        {amountRevShare ? amountRevShare  : ''}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>BALANCE</b></td>
                        <td width="60%">{balance}</td>			
                    </tr>

                    <tr>			
                        <td width="40%"><b>TRANSACTION</b></td>
                        <td width="60%">{ledgerType}
                        {ledgerTypeRevShare ? ledgerTypeRevShare  : ''}</td>
                    </tr>
                    </tbody>
                    </table>

                    <br />		

                </Fragment>

                
            }

            {
                isSoldToOwn && txnDate != '' && props.type === 'mobile' && 
                
                <Fragment>

                    <table className="walletActivityTable" style={{ "borderSpacing" : 0}}>
                    <tbody>
                    <tr>
                        <td width="40%"><b>TIME</b></td>
                        <td width="60%">{txnDate}</td>
                    </tr>

                    <tr className={fromWalletRevShare ? 'revshare-row' : ''}>
                        <td width="40%"><b>FROM</b></td>
                        <td width="60%" className="ellipsis" ><span style={{ "left" : "0px", "width" : "100%"}}>{fromWallet}</span>
                        {fromWalletRevShare ? fromWalletRevShare  : ''}</td>
                    </tr>

                    <tr className={fromWalletRevShare ? 'revshare-row' : ''}>
                        <td width="40%"><b>TO</b></td>
                        <td width="60%" className="ellipsis" ><span style={{ "left" : "0px", "width" : "100%"}}>{toWallet}</span>
                        {toWalletRevShare ? toWalletRevShare  : ''}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>TYPE</b></td>
                        <td width="60%">{transactionTypeSoldToOwn}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>AMOUNT</b></td>
                        <td width="60%">{amount}
                        {amountRevShareSoldToOwn ? amountRevShareSoldToOwn  : ''}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>BALANCE</b></td>
                        <td width="60%">{balance}</td>			
                    </tr>

                    <tr>			
                        <td width="40%"><b>TRANSACTION</b></td>
                        <td width="60%">{ledgerType}
                        {ledgerTypeRevShare ? ledgerTypeRevShare  : ''}</td>
                    </tr>
                    </tbody>
                    </table>

                    <br />		

                </Fragment>

                
            }
        </Fragment>

    )

}

export default LedgerItem;