import { Fragment, useEffect, useState } from "react";
import { backend_api_url, getUserSessionData, isUserAdmin } from "./constants";
import MachineSol from "./MachineSol";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Modal from 'react-bootstrap/Modal';

const MachineListContainer = (props: any) => {

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const [copied, setCopied] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [message, setMessage] = useState<string>("");


    const copyNow = (address) => {
        navigator.clipboard.writeText(address);
        setCopied(true);
        handleShow();
        setMessage('Copied to clipboard'); 
    }

    const fetchInfo = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/all', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    showInfo(data.machines);
                    
                }
            });
	};
  
    const showInfo = async (lists: any[]): Promise<any> => {
 
          //console.log(allListings)
         setListingJsx( lists.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
            <tr>
                <td>{info.date}</td>
                <td>
                    {
                        info.is_draft === 1 && <Fragment>{info.name}</Fragment>
                    }
                    {
                        info.is_draft === 0 && <a rel="noreferrer" href={"/collection/" + info.address} target="_blank">{info.name}</a>
                    }
                </td>
                <td><a  rel="noreferrer" href={"/user/" + info.creatorUserName} target="_blank">{info.creatorUserName}</a></td>

                <td>
                    
                {
                        info.is_draft === 1 && <a  rel="noreferrer" href={"/edit-collection/"+info.id+"/"+info.user_id+"/"} target="_blank">visit</a>
                    }
                    {
                        info.is_draft === 0 && <a  rel="noreferrer" href={"/collection/" + info.address + '/settings'} target="_blank">visit</a>
                    }
                    
                    </td>

                <td>{info.edition}</td>
                <td>{info.size}</td>
                <td>$ {info.price}</td>
                <td>{info.status}</td>
                <td>{info.sold}</td>
                <td>$ {info.revenue}</td> 


                <td>
                    <a  rel="noreferrer" href={"/collection-wallet/" + info.address} target="_blank">visit</a>
                </td>
                <td>
                    <a  rel="noreferrer" href={"/collection-wallet/" + info.address + '/activity'} target="_blank">visit</a>
                </td>
                <td>
                    <MachineSol address={info.address} index={index}></MachineSol>
                </td>

            </tr>
            </Fragment>) );
 
     };
 

	useEffect(() => {
		
        //fetchAllMyGachas();
        if(!isUserAdmin()) {
            window.location.href = '/';
        }
        fetchInfo();
	  }, []);

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">
               <a href="/admin/users">Users</a>&nbsp;|&nbsp;               
               <a href="/admin/creators">Creators</a>&nbsp;|&nbsp;               
               <a href="/admin/collections">Collections</a>&nbsp;|&nbsp;               
               <a href="/admin/purchases">Purchases</a>&nbsp;|&nbsp; 
               <a href="/admin/actions">Actions</a>

               <table className="walletActivityTable admin-infos"  style={{ "borderSpacing" : 0}} >
                <tbody>
                    <tr>
                    <td align="center"><b>TIME</b></td>
                    <td align="center"><b>COLLECTION NAME</b></td>
                    <td align="center"><b>CREATOR</b></td>
                    <td align="center"><b>SETTINGS</b></td>
                    <td align="center"><b>EDITION</b></td>
                    <td align="center"><b>SIZE</b></td>
                    <td align="center"><b>PRICE</b></td>
                    <td align="center"><b>STATUS</b></td>
                    <td align="center"><b>SOLD</b></td>
                    <td align="center"><b>REVENUE</b></td>
                    <td align="center"><b>WALLET</b></td>
                    <td align="center"><b>ACTIVITY</b></td>
                    <td align="center"><b>BAL</b></td>
                    </tr>	

                    {listingJsx}
                
                </tbody>
                </table>
            </div>
                
            <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton={true}> 
                </Modal.Header>
                    <Modal.Body>
                    <div style={{ "textAlign" : "center"}}>
                        {message}
                    </div>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal> 
                
         
        </Fragment>
    );
}

export default MachineListContainer;