import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom"; 
import { PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';

const PaginatedDesigns = (props : any) => {
  
    const [isProcessing, setIsProcessing] = useState(false);

    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [loadedData, setLoadedData] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    const itemsPerPageRef = useRef<HTMLSelectElement>(null);
    const [page, setPage] = React.useState(0)

    const [validRecords, setValidRecords] = useState([]);



    const getValidItems = () => {
        let allItems = props.items;
        let validItems = [];
        for(var i in allItems) {
            
            if(allItems[i] && allItems[i] != null)
                validItems.push(allItems[i]);
        }
        return validItems;
    }
    const setupPaginatedItems = async (newItemsPerPage, refresh) => {
        setIsProcessing(true);
        let validItems = refresh ? getValidItems() : [...validRecords]; //getValidItems();

        //console.log(validItems);
        
        setValidRecords(validItems);

        const pageCount = Math.ceil(validItems.length / itemsPerPage);
        setTotalItems(validItems.length);
        setPageCount(pageCount);
        loadAllItems(0, newItemsPerPage, validItems);
        setPage(0);
    }
    
    const loadAllItems = async (page, newItemsPerPage, validItems) => {

        //let validItems = [...validRecords]; //getValidItems();

        const itemOffset = (page * newItemsPerPage) % validItems.length;
        const endOffset = itemOffset + newItemsPerPage;
        const currentItems = validItems.slice(itemOffset, endOffset);
    
        // console.log(props.items);

        // console.log(items);
        // console.log(itemOffset);
        // console.log(endOffset);
        
        // console.log(currentItems);

        const jsxElem = [];

        for(var i in currentItems) {
            if(currentItems[i].type === 'image') {
                jsxElem.push(props.renderUploadedImage(currentItems[i].data, currentItems[i].index));
            } else {
                jsxElem.push(props.renderUploadedJSON(currentItems[i].data, currentItems[i].index));

            }
        }
        // console.log(currentItems);
        // console.log(jsxElem);
        // console.log('start');

        setLoadedData(jsxElem);
        // console.log('end');
        
        setIsProcessing(false);
        props.getEstimateSol();
    }

    
    const handlePageClick = (event) => { 
        setPage(event.selected);
        loadAllItems(event.selected, itemsPerPage, validRecords);
 
     };
  
     const updateItemsPerPage = () => {
        let newItemsPerPage = parseInt( itemsPerPageRef.current!.value );
        setItemsPerPage(newItemsPerPage);
        setupPaginatedItems(newItemsPerPage, false);
     }
    useEffect(() => {
        setupPaginatedItems(itemsPerPage, true);
        props.resetNumOfDesigns();
    }, [props.items]);

 
   


    return (
        <Fragment>
             {totalItems > 0  && loadedData}

            {
            props.isProcessing && totalItems === 0 && 
            <div><b>PROCESSING...</b></div>	
            }
            
{/* {props.items} */}
            {
            totalItems > 20 ? <div style={{"width": "100%", "textAlign" : "center"}}>
                
            <div>View By: <select ref={itemsPerPageRef} onChange={updateItemsPerPage}  style={{"background": "#000","height" : "30px", "color": "#fff"}}> 
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                </select></div>
            <ReactPaginate

            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"

            forcePage={page}

            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"

            renderOnZeroPageCount={null}
            /></div> : ""
            }
        </Fragment>

    );
}

export default PaginatedDesigns;