import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { cluster, getCurrentWallet, getUserSessionData, isShowLedgerInfo, wait} from "./constants";
import { PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';

import { SolanaWallet } from "@web3auth/solana-provider";

    
import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import LedgerItem from "./LedgerItem"; 
 

const WalletActivity = (props : any) => {
 
    const { connection } = useConnection();

    const [isFetching, setIsFetching] = useState(false);
    const [lastSignature, setLastSignature] = useState("");

    const [ledgerJsx, setLedgerJsx] = useState<JSX.Element[] | null>([])
    const [ledgerMobileJsx, setLedgerMobileJsx] = useState<JSX.Element[] | null>([])
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);

    const [renderedElements, setRenderedElements] = useState([]);
    const [renderedElementsMobile, setRenderedElementsMobile] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [allTransactions, setAllTransactions] = useState([]);

    const [loadedData, setLoadedData] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [isEnd, setIsEnd] = useState(false);

    const [loadText, setLoadText] = useState('Load Transactions');
    const [hasLoaded, setHasLoaded] = useState(false);

    const walletAddress = "2k5AXX4guW9XwRQ1AKCpAuUqgWDpQpwFfpVFh3hnm2Ha"
// WALLET TRANSACTION SEARCH
  /*
    const parseWallet = async (walletAddress) => {
      
      const response = await fetch(url);
      const data = await response.json();
      console.log("wallet transactions: ", data);
    };
    */
    const fetchAndParseTransactions = async () => {
      let lastSignature = null;
      let allTransactions = [];

      let allowedType = [
       'COMPRESSED_NFT_VERIFY_CREATOR',
       'COMPRESSED_NFT_MINT', 
       'COMPRESSED_NFT_TRANSFER', 
       
       'NFT_MINT', 
       'TRANSFER']

      const userData = getUserSessionData();
      if(userData) {
        const walletAddress = getCurrentWallet();
        let url = process.env.REACT_APP_HELIUS_API + 'v0/addresses/'+walletAddress+'/transactions?api-key='+process.env.REACT_APP_HELIUS_API_KEY;
        let newUrl = url;
        while (true) {
          
          if (lastSignature) {
            newUrl = url + `&before=${lastSignature}`;
          }
          const response = await fetch(newUrl+"&t="+new Date());
          const transactions = await response.json();
      
          if (transactions && transactions.length > 0) {
            console.log("Fetched transactions: ", transactions);

            for(var i in transactions) {
              allTransactions.push(transactions[i]);
            }
            lastSignature = transactions[transactions.length - 1].signature;
          } else {
            console.log("No more transactions available.");
            break;
          }
        }
      }
      console.log(allTransactions);
    };
    

    //parseWallet();

    const checkTxn = async () => {

      await fetchAndParseTransactions();
      return;

      const userData = getUserSessionData();
      if(userData) {
          setUsernameJsx(<a href={"/wallet"}>{userData.username}'s</a>);
          if(userData.wallet) {
              let pubkey =  new PublicKey(getCurrentWallet());

              let isCont = false;
              let lastSignature1 = "";
              let allTransactions = [];
              do{
                  isCont = false;
                  let parameter = {};
                  parameter['limit'] = 50;
                  if(lastSignature1) {
                      parameter['before'] = lastSignature1;
                  }
                  const signatures = await connection.getSignaturesForAddress(pubkey, parameter,  "finalized");
                  // console.log(signatures.length);
                  if(signatures.length > 0) {
                      lastSignature1 = signatures[signatures.length - 1].signature;
                       isCont = true;
                      for(var i in signatures) {
                          allTransactions.push(signatures[i]);
                      }
                  }

                  //break;
              }while(isCont);
              // console.log(allTxns);
               
              setupPaginatedItems(allTransactions);

               
              
          }
      }
  }
 
  const getTransactionInfo = async(signature) => {
    let info = null;
    for(var i in loadedData) {
      if(loadedData[i].signature === signature) {
        info = {'signature' : signature, 'transaction' : loadedData[i].transaction}; 
        

      }
    }
    if(info) {
      loadedData.push({"signature" : info.signature, "transaction": info.transaction});
      setLoadedData(loadedData);
      return info;

    }
    let transactionData;
    do{
      transactionData = await retrieveInfo(signature);
      if(transactionData.result || transactionData.transaction) {
        break;
      } else {
        await wait(3000);
        //console.log("awaiting");
        //console.log(transactionData);
      }
    }while(true)


    info =  {'signature' : signature, 'transaction' : transactionData};

    if(info) {
      loadedData.push({"signature" : info.signature, "transaction": info.transaction});
      setLoadedData(loadedData);
    }
    return info;
  }

  const retrieveInfo = async (signature) => {
    const transactionData = await connection.getTransaction(signature, {
      maxSupportedTransactionVersion : 0
    });

    return transactionData;
  }

  const getBulkTransaction = async ( currentItems) => {
     
    const transactionList = await Promise.all(
      currentItems.map(async (signature: any) => {
        try {

          for(var i in loadedData) {
            if(loadedData[i].signature === signature)
              return {'signature' : signature, 'transaction' : loadedData[i].transaction}; 
          }

          const transactionData = await connection.getTransaction(signature, {
            maxSupportedTransactionVersion : 0
          });

          return {'signature' : signature, 'transaction' : transactionData};
  
        } catch (err: any) {
          return null;
        }
        
  
      })
    );

    for(var i in transactionList) {
      loadedData.push({"signature" : transactionList[i].signature, "transaction": transactionList[i].transaction})
    }

    setLoadedData(loadedData);

    return transactionList;
  }

  const getNextItems = async (allTransactionsList, sigLast) => {
    let start = false;
    let sig = sigLast;

    let items = [];
    //let counter = 0;
    for(var i in allTransactionsList) {
      //counter++;
       if(start === false && (sig ===  allTransactionsList[i].signature || sig === '')) {
        start = true;
        if(sig !== '')
          continue;
      }
      if(start){
        sig = allTransactionsList[i].signature;

        items.push (allTransactionsList[i].signature);
        
      } 
      if(items.length === itemsPerPage) {
        break;
      }
    }

    //console.log(items);

    

    return {"items": items, 'loadedInfo' : await getBulkTransaction(items)} ;
  }

  const loadAllLedgerNew = async (allTransactionsList) => {
    setIsProcessing(true);
      let start = false;  
      let userWallet = getCurrentWallet();

      let newTransactionList = [];
      let txns;
      let accountKeys = [];
      let prevVal;
      let curVal;
      let isAdd = false;
      
      let isShow = false;
      
      let isCont = false;
      let newItems = null;
      let sig = lastSignature;
      do{
        isCont = true;
        newItems = await getNextItems(allTransactionsList, sig);
 

        if(newItems && newItems.items) {

          if(newItems.items.length === 0) {
            isCont = false;
            setIsEnd(true);
          }else{

            for(var i in newItems.items) {
                isShow = false;
                
        
                  const transactionData = await getTransactionInfo(newItems.items[i]);
                  
                  isAdd = false;
                  if( transactionData) {
                    txns = transactionData.transaction;
                    if(txns.transaction && txns.transaction.message) {
                      accountKeys = txns.transaction.message.staticAccountKeys;
        
                      if(accountKeys[accountKeys.length-1].toBase58() === userWallet) {
                        curVal = (txns.meta.postBalances[accountKeys.length-1] ) / LAMPORTS_PER_SOL 
                        if(prevVal !== curVal) {
                          prevVal = curVal;
                          isAdd = true;
                        }
                      }else{
                        isAdd = true;
                      }
                    }
            
                    isAdd = true;
                    allTransactionsList[i].index = i;
                    let nextTxn = allTransactionsList[ (parseInt(i)+1).toString() ];
                    let prevTxn = allTransactionsList[ (parseInt(i)-1).toString() ];
          
                      //w can get prev
                        for(var k in allTransactionsList) {
                          if(allTransactionsList[k].signature === allTransactionsList[i].signature)
                            break;
                          prevTxn = allTransactionsList[k];
                          
                        }
                      
                        for(var k in allTransactionsList) {
                          if(parseInt(k)-1 > 0 && allTransactionsList[(parseInt(k)-1).toString()].signature === allTransactionsList[i].signature) {
                            nextTxn = allTransactionsList[k];
                            break;
                          }
                          
                        }
                    
                        if(nextTxn) {
                          nextTxn = await getTransactionInfo(nextTxn.signature);
                        }
                        if(prevTxn) {
                          prevTxn = await getTransactionInfo(prevTxn.signature);
                        }
        
        
                        transactionData.nextTransaction = nextTxn;
                        transactionData.prevTransaction = prevTxn;
        
                  }
                  sig = transactionData.signature;
                  setLastSignature(sig);

                  if(allTransactionsList.length === 0 || allTransactionsList[(allTransactionsList.length-1).toString()].signature === sig)
                    setIsEnd(true);

                  if(isAdd){ 
                    isShow = isShowLedgerInfo(transactionData);
                    
                    //console.log(transactionData.signature)
                    //console.log(isShow)
        
                    if(isShow) {
                      newTransactionList.push(transactionData);

                    }
                  }
                
                //console.log(transactionData);
                //console.log("length: " + newTransactionList.length);
        
                if(newTransactionList.length === itemsPerPage) {
                  isCont = false;
                  break;
                }
        
        
            }
          }
        }

      }while(isCont && isEnd === false);

      
 
    const userData = getUserSessionData(); 
 
    //setIsProcessing(false);
 
    const elem = newTransactionList.map( (transaction) => <LedgerItem ownwallet={userData.wallet} key={transaction.signature} type="desktop" prevTransaction={transaction.prevTransaction} nextTransaction={transaction.nextTransaction} transaction={transaction.transaction} signature={transaction.signature}/>);
    const elem1 = newTransactionList.map( (transaction) => <LedgerItem ownwallet={userData.wallet} key={transaction.signature} type="mobile" prevTransaction={transaction.prevTransaction} nextTransaction={transaction.nextTransaction} transaction={transaction.transaction} signature={transaction.signature}/>);

    setLedgerJsx((prevElements) => [...prevElements, ...elem]);
    setLedgerMobileJsx((prevElements) => [...prevElements, ...elem1] );

    setIsProcessing(false);

  }

  const loadMoreItems = () => {
    if(!hasLoaded) {
      setIsProcessing(true);
      checkTxn();
      setHasLoaded(true);
      setLoadText('Load More');
    }else{
      loadAllLedgerNew(allTransactions);

    }
  } 
 
    const setupPaginatedItems = (allTransactionsList) => {
      //const pageCount = Math.ceil(allTransactionsList.length / itemsPerPage);
      setTotalItems(allTransactionsList.length);
      //setPageCount(pageCount);
      loadAllLedgerNew(allTransactionsList);
      setAllTransactions(allTransactionsList); 
    }

    useEffect(() => {
        if(!getUserSessionData()) { 
            window.location.href = "/";
        }else{
          //setIsProcessing(true);
          //checkTxn();

          const userData = getUserSessionData();
          if(userData) {
              setUsernameJsx(<a href={"/wallet"}>{userData.username}'s</a>);
          }
        }
    }, []);

    props.setClassInfo('walletActivityPg');
 
   


    return (
        <Fragment>

<div className="contents">

<h5 className="outer">{usernameJsx} &gt;&gt; Wallet Activity</h5>

<div className="tblDesktop">
    {/* cellpadding="0" cellspacing="0" */}
<table className="walletActivityTable"  style={{ "borderSpacing" : 0}} >
  <tbody>
    <tr>
      <td align="center"><b>TIME</b></td>
      <td align="center"><b>FROM</b></td>
      <td align="center"><b>TO</b></td>
      <td align="center"><b>TYPE</b></td>
      <td align="center"><b>AMOUNT</b></td>
      <td align="center"><b>BALANCE</b></td>
      <td align="center"><b>TRANSACTION</b></td>
    </tr>	

    {ledgerJsx}
 
  </tbody>
</table>
</div>

<div className="tblMobile">
    {/* cellpadding="2" cellspacing="0" */}

{/* {renderedElementsMobile} */}

    {ledgerMobileJsx}

    
</div>		

{isProcessing && <p className="bigger-font" style={{"textAlign": "center"}}>Processing...</p>}
{!isProcessing && !isEnd && <div className="bigger-font" style={{"textAlign": "center"}}><button className="buttonLink btn-logout " onClick={loadMoreItems}>{loadText}</button> This may take time.</div>}


</div>

        
        </Fragment>

    );
}

export default WalletActivity;