import { Fragment, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import { getCurrentWalletPublicKey, getUserSessionData, getBalance, getPublisherNew, getConnectionConfig, createGachaCollection, getPrivateKey, getPrivateKeyBase58, continueAssetUpload, getPublicWallet, doZipUpload} from "./constants";
import { toast } from "react-toastify";
import { useConnection } from '@solana/wallet-adapter-react';
import { Keypair, PublicKey, LAMPORTS_PER_SOL, Transaction, SystemProgram} from "@solana/web3.js";
import base58 from "bs58";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import fs from 'fs'
import { SolanaWallet } from "@web3auth/solana-provider";


interface UploadInfo {
	name: string;
	file: string;
    actualFile: File;
}
 
const ListingForm = (props: any) => {

    const [uploadedFiles, setUploadedFiles] = useState([])
    const [uploadedMetaFiles, setUploadedMetaFiles] = useState([])
    const [assetJsx, setAssetJsx] = useState<JSX.Element | null>(null)

    const [fileLimit, setFileLimit] = useState(false);

    const nameInput = useRef<HTMLInputElement>(null);
    const editionInput = useRef<HTMLSelectElement>(null);
    const numOfNftInput = useRef<HTMLInputElement>(null);
    const tokenAcceptedInput = useRef<HTMLSelectElement>(null);
    const priceInput = useRef<HTMLInputElement>(null);
    const enforceRoyaltiesInput = useRef<HTMLSelectElement>(null);
    const royaltiesInput = useRef<HTMLInputElement>(null);
    const descriptionInput = useRef<HTMLTextAreaElement>(null);

    const hiddenFileInput = useRef<any>(null);
    const [avatarImage, setAvatarImage] =  useState<string>("/avatar-bak.png");

    const [allFiles, setAllFiles] =  useState<File[]>([]);
    const [tags, setTags] = useState([]);
    const [minSol, setMinSol] = useState<number>();
    const [solBalance, setSolBalance] = useState<number>(0);

    const [uploadedItems, setUploadedItems]  = useState<number>(0);
    const [toBeUploaded, setToBeUploaded]  = useState<number>(0);
    const [stepName, setStepName] = useState<string>('');

    const [editionType, setEditionType] = useState<string>('open');

    const [allFilesNew, setAllFilesNew] = useState<UploadInfo[]>([])
    const [allFilesNewJson, setAllFilesNewJson] = useState<UploadInfo[]>([])

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const { connection } = useConnection();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let params = useParams();

    const isWalletReady = () : boolean => {

        const walletPubKey = getCurrentWalletPublicKey();

		return (
            walletPubKey != null
		);
	};

    const loadWalletBalance = async () => {

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) { 

                const pubkey =  new PublicKey(userData.wallet);

                const balance = await getBalance(pubkey, connection);
                setSolBalance(balance);
                return (balance);
            }
        }
        return 0;
    }

    const changeEdition = (event: any) => {
        event.preventDefault();
        setEditionType(editionInput.current!.value);
    }

    
    const showPrivateKey = async (event: any) => {
        event.preventDefault();

        if(getUserSessionData() == null || props.provider == null) {
            props.loginWeb3Auth();
            return;
        }

        const web3authKey = await getPrivateKeyBase58(props.provider);
        const pubkey = await getPublicWallet(props.provider);

        console.log("Private Key: "+web3authKey);
        console.log("Public Key: "+pubkey);
        
        alert('Private key: '+web3authKey + "   Public Key: " + pubkey);

    }

    
    const sendInitialSolToGachaWallet = async (solAmount, fromWallet, toWallet) => {
        // console.log(solParams);
        //handleClose();
        // alert('Send sol now');

        
        const amount = solAmount * LAMPORTS_PER_SOL; // hardcoded to 1 SOL for now

        const solanaWallet = new SolanaWallet(props.provider);

        const pubWal = getCurrentWalletPublicKey();
        const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
        const TransactionInstruction = SystemProgram.transfer({
        fromPubkey: new PublicKey(fromWallet),
        toPubkey: new PublicKey(toWallet),
        lamports: amount,
        });
        const transaction = new Transaction({
        recentBlockhash: blockhash,
        feePayer: pubWal,
        }).add(TransactionInstruction);
        
        
        const { signature } = await solanaWallet.signAndSendTransaction(transaction);

        console.log(signature);
        // toast.success("Transferred Successfully", {
        //     className : "success-toast"
        // });
        props.loadSolBalance();

    }

    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

    const formSubmitHandler = async (event: any) => {

        event.preventDefault();
        
        let errMsg : string;
        
        if(getUserSessionData() == null || props.provider == null) {
            props.loginWeb3Auth();
            return;
        }
        const userData = getUserSessionData();

        // const publisher = getCurrentWalletPublicKey();

        // const publisherPrivate = await getPrivateKey(props.provider);
 
        const web3authKey = await getPrivateKeyBase58(props.provider);
         // console.log('Public Key: ' + publisher.toBase58());
        // console.log('Private Key: ' + publisherPrivate);
        // console.log('Private Key: ' + web3authKey);

      
		if (isWalletReady()) {
            const balance = await loadWalletBalance();
            if(balance < 2) {
                showToastError("Needs more SOLs to be able to create this gacha collection");
                return;
            } 

            const listingAccSeed = Keypair.generate().publicKey.toBase58();
            const listingAcc = getKeypairFromSeed(listingAccSeed);

            // console.log(listingAccSeed);
            // console.log(listingAcc.secretKey);
            // console.log(listingAcc.publicKey.toString());

            const privkey = new Uint8Array(listingAcc.secretKey); // content of id.json here
	        //console.log(base58.encode(privkey));

            let gachaWalletPrivateKey = base58.encode(privkey);
            let gachaWalletPublicKey = listingAcc.publicKey.toString();

            //gachaWalletPrivateKey = 'Ega19ngpe7rQnKw3q1yHbTiKSuYDeNZAKLbb6qR3dhkk5Gr9c2ZvKxh6Y2MvdqGzncbPY6P1oBWyuEuV274Winp';
            //gachaWalletPublicKey = 'Ew6idyGVNaXppcQBttWfBDdF7VcV2HYMeyqPs53r2jRE';

            const params1 = {
                gachaWallet: gachaWalletPrivateKey,
                gachaPubWallet: gachaWalletPublicKey,

                creator : web3authKey, //publisher.toBase58(),
                token: userData.token,
                secret: userData.secret,
                
                name: nameInput.current!.value,
                numOfNft: editionInput.current!.value === 'limited' ? numOfNftInput.current!.value : 0,
    
                numOfImages: allFilesNew.length, 
    
                edition: editionInput.current!.value,
    
                tokenAccepted: tokenAcceptedInput.current!.value,
                price: priceInput.current!.value,
                enforceRoyalties: enforceRoyaltiesInput.current!.value,
                royalties: royaltiesInput.current!.value,
    
                description: descriptionInput.current!.value,
                
                // allFiles: allFilesNew,
                // metaFiles: allFilesNewJson,
                avatar: avatarImage
                //assets: payload.assets,
                
            };

            const params2 = {
                 
                allFiles: allFilesNew,
                metaFiles: allFilesNewJson, 
                
            };

            setToBeUploaded(allFilesNew.length);
 
            await sendInitialSolToGachaWallet(2, userData.wallet, gachaWalletPublicKey);
          
			try {

                if(!isEdit ) {
                    const balance = await loadWalletBalance();
                    if(minSol > balance) {

                        showToastError("Needs more SOLs to be able to create this posting");
                        return;
                    }
                }
                handleShow();

                //const tag: string[] = selectedTags;

                const tag : string[] = tags.map( tagInfo => tagInfo.text);

                const publisher = await getPublisherNew(props.provider);

                const web3authProvider = await props.web3auth.connect();
 
                const config = getConnectionConfig();
                 // Initialize signMsgFn by drawing out the private key first
                 /*
                const signMsgFn = await signMsgCurriedFn(props.provider);
                const listingClient = new ListingClient(config.cluster, getCurrentWalletPublicKey(), signMsgFn);
                */
                // const solanaWallet = new SolanaWallet(web3authProvider);
               
                const allContents = allFilesNew.map(  file => {
                     return { content: file.file };
                }); 
                 
                if(allContents.length === 0) {
                    handleClose();
                    showToastError("Image is required.");
                    return;
                }
                
                
                const options = { defaultProtocol: 'https', attributes: {
                    rel: 'nofollow'
                  } };
                const newContent = '';//linkifyHtml(convertToHTML(editorState.getCurrentContent()), options);

                if(isEdit) {
                
                    const txnId : string = params.txnId as string;
                   
                    let newContents = [];
                    let isFound  = false;
                    let content = '';
                    
                    props.loadSolBalance();
 

                }else{
                   
                    const candyMachineData = await createGachaCollection('', params1,params2, doZipUpload, handleClose, setUploadedItems, setStepName, false, false);
                    //console.log('New Machine');
                    //console.log(candyMachineData);
                    
                    props.loadSolBalance();

                }
                //handleClose();
			} catch (err: any) {
                console.log(err)
				errMsg = (err as Error).message;
                showToastError(errMsg);
                handleClose();
			}
		}else{
            showToastError("Please connect your wallet");

        }
	};  

    const getKeypairFromSeed = (seed: string): Keypair => {
        const seedBytes = base58.decode(seed);
        return Keypair.fromSeed(seedBytes);
    };

    const showToastError = (message) => {
       
        toast.dismiss();
      setTimeout(function(){
          toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
              autoClose: false,
              //closeButton: false
          });
      }, 500);
  } 


  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
             
            if(file.type == 'application/json') {
                uploadedMetaFiles.push(file);


                const reader = new FileReader();
                reader.readAsText(file);
                
                reader.onload = (event) => {
                    const imageString = event.target?.result as string;
            
                    setAllFilesNewJson(existing => [...existing, { actualFile: file, name: file.name, file: JSON.parse(imageString)}]);
            
                };

            }else{
                uploaded.push(file);
                
                const reader = new FileReader();
                reader.readAsDataURL(file);
                
                reader.onload = (event) => {
                    const imageString = event.target?.result as string;
            
                    setAllFilesNew(existing => [...existing, { actualFile: file, name: file.name, file: imageString}]);
            
                };
            }
            /*
            if (uploaded.length === MAX_COUNT) setFileLimit(true);
            if (uploaded.length > MAX_COUNT) {
                alert(`You can only add a maximum of ${MAX_COUNT} files`);
                setFileLimit(false);
                limitExceeded = true;
                return true;
            }
            */
        }
    })

  
    
    /*
    if(allFilesNew.length > 0) {
        setPreviewImageJsx(allFilesNew.map( (image, index) => <img alt={image.name} key={generateRandomKey(index)} className="asset-image" src={image.file} /> ));
    }else{
        setPreviewImageJsx([assetJsx]);
    }
    */

    if (!limitExceeded) setUploadedFiles(uploaded)

}

const removeAsset = (file) => {
 
    setAllFilesNew(
        allFilesNew.filter(a =>
          a.name !== file.name
        )
      );

      let jsonName = file.name.split('.')[0] + '.json';

      setUploadedMetaFiles(
        uploadedMetaFiles.filter(a =>
          a.name !== jsonName
        )
      );  
    console.log(allFilesNew);
    console.log(uploadedMetaFiles);
}
const generateRandomKey =(index) => {
    return new Date().getTime() + index;
  }

const handleFileEvent =  (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleUploadFiles(chosenFiles);
}
 
const handleFileClick = () => {
    hiddenFileInput.current.click();
}
const handleChange = (event: any) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
        const imageString = event.target?.result as string;
         setAvatarImage(imageString);

    };
}; 

    return (
            <Fragment>
                    Create New Collection

                    <button style={ {"backgroundColor" : "red"}} onClick={showPrivateKey} type="button" className="btn btn-info">Show Private Key</button>

                    <Form id="frmCreatPost" onSubmit={formSubmitHandler}>
                    <div className="row g-3">

                        <div className="col-md-6">
                           
                            <img  alt="" className="gacha-avatar" onClick={handleFileClick} src={avatarImage}/>
                            <p style={{"fontSize" : "12px"}}>Click to update</p>
                            <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{display: 'none'}}
                                />
                                

                        </div>
                        <div className="col-md-6">
                          
                        
  <div className="col-md-6">
    <label className="form-label">Collection Name</label>
    <input type="text" className="form-control" id="inputEmail4" required ref={nameInput}></input>
  </div>

  <div className="col-md-4">
    <label  className="form-label">Open or Limited Edition</label>
    <select id="inputState" className="form-select" ref={editionInput} onChange={changeEdition}>
      <option value="open">Open</option>
      <option value="limited">Limited</option>
    </select>
  </div>

        {
            editionType === 'limited' && 
                <div className="col-md-6">
                    <label className="form-label">Max # of NFTs to Mint: </label>
                    <input type="number" className="form-control" id="inputEmail4" required ref={numOfNftInput}></input>
                </div>
            
        }
  

  <div className="col-md-4">
    <label  className="form-label">Token Accepted</label>
    <select id="inputState" className="form-select" ref={tokenAcceptedInput}>
      <option value="USDC">USDC</option>
      <option value="USDT">USDT</option>
      <option value="SOL">SOL</option>
    </select>
  </div>

  <div className="col-md-6">
    <label className="form-label">Price Per Mint: </label>
    <input type="text" className="form-control" id="inputEmail4" required ref={priceInput}></input>
  </div>

  <div className="col-md-4">
    <label  className="form-label">Enforce Royalties?</label>
    <select id="inputState" className="form-select" ref={enforceRoyaltiesInput}>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  </div>

  <div className="col-md-4">
    <label  className="form-label">Royalty (in %)</label>
    <input type="text" className="form-control" id="inputEmail4" required ref={royaltiesInput}></input>
  </div>


</div>
</div>
<div className="row g-3">
    <div className="col-md-11">

        <label  className="form-label">About the collection</label>
        <textarea className="form-control" id="inputEmail4" required ref={descriptionInput}></textarea>
    </div>

    <div className="col-md-12">
       
    <input id='fileUpload' type='file' multiple
                    onChange={handleFileEvent}
			/>
                <label  className="form-label">Note: upload images + json file for meta data</label>

    </div>

    <div className="col-md-12">
        <label  className="form-label">Enable Rarity?</label>
        <select id="inputState" className="form-select">
            <option value="Yes">Yes</option>
            <option value="No">No</option>
        </select>
        <a href="#">assign Rarity</a>
    </div>

    <div className="row uploaded-files-list">
    {/* {previewImageJsx} */}
			{
                allFilesNew.map( (image, index) => <div className='col-md-1' key={index}>
                    <img alt={image.name} key={generateRandomKey(index)} className="asset-image" src={image.file} />
                    <br />
                    <a href="javascript:;" onClick={() => removeAsset(image)}>remove</a>
                    </div> )
            }


    </div>
    <div className="col-md-12">
        <button className="btn btn-info">Publish Collection</button>

    </div>
</div>
                    </Form>


                    <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton={false}>
            <Modal.Title>Processing... {stepName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
 
                <div className="custom-loader"></div>
 
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

            </Fragment>
        );
}
export default ListingForm;