import React, {Fragment, useEffect, useState} from "react";
import { backend_api_url, getUserSessionData, cluster, settingsSlider } from "./constants";
import { useConnection } from "@solana/wallet-adapter-react";
import { Keypair } from "@solana/web3.js";
import { Metaplex, PublicKey, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import { toast } from "react-toastify";
import Slider from "react-slick";

const CollectionList = (props: any) => {
 
    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)
    const [num, setNum] = useState(0)

    
    const [renderedElements, setRenderedElements] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const delay = 100; // Adjust the delay (in milliseconds) between rendering elements

        const timer = setTimeout(() => {
        if (listingJsx && currentIndex < listingJsx.length) {
            setRenderedElements((prevElements) => [...prevElements, listingJsx[currentIndex]]);
            setCurrentIndex(currentIndex + 1);
        }
        }, delay);

        return () => clearTimeout(timer);
    }, [currentIndex, listingJsx]);

    const generateRandomKey =(index) => {
        return new Date().getTime() + index;
    }

    const fetchGachas = async (type, search): Promise<any> => {
        
        // const userData = getUserSessionData();

        const params = {
            // token: userData.token,
            // secret: userData.secret,
            type: type,
            search: search

        }
        let apiURL = backend_api_url + 'api/v1/machine/get-collections';
        if(type === 'search') {
            params.type = 'collection';
            apiURL = backend_api_url + 'api/v1/machine/search';
        }
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(apiURL, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) { 
                    
                    setNum(data.machines.length);

                    setListingJsx( data.machines.map( (gacha, index) => <Fragment key={generateRandomKey(index)}><a style={{"textAlign": 'center'}} href={'/collection/'+gacha.candy_machine_address} className={props!.useCarousel ? 'imgSliderDetails' : ''}><img alt="" src={gacha.avatar} /><br />{gacha.name}</a></Fragment>) );

                }
            });
    

        
	};
 

    useEffect(() => {
        fetchGachas(props.type, props.search);
	  }, []);

     
    settingsSlider.infinite = num >= 5 ? true : false;

    settingsSlider.infinite = false;

    return (
        <Fragment>
                {(listingJsx === null || listingJsx.length === 0) && props.type === 'search' && <div style={{"position": "relative", "top": "-10px", "marginBottom" : "10px", "fontSize" : "18px"}}>No Results</div>}
                 

                {
                    props!.useCarousel &&  listingJsx && renderedElements.length > 0 && 
                    <Slider  {...settingsSlider}>
                            {
                                renderedElements
                            }

                    </Slider>
                } 

                {
                    !props!.useCarousel && renderedElements.length > 0 && renderedElements
                     
                } 
        </Fragment>
    );
}

export default CollectionList;