import React, {Fragment, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { backend_api_url, chunkArray, cluster, getExplorerUrl } from "./constants";
import { PublicKey } from '@solana/web3.js';
import { useConnection } from '@solana/wallet-adapter-react';
import CollectionList from "./CollectionList";
import RecentlyMinted from "./RecentlyMinted";
import Modal from 'react-bootstrap/Modal';
import RecentlyJoined from "./RecentlyJoined";


const SearchView = (props : any) => {

    props.setClassInfo("homePg");

    let params = useParams(); 

    const [allNftImages, setAllNftImages] = useState<string[] | any>();
    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);

    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [selectedNftAddress, setSelectedNftAddress] = useState("");
    const [selectedNftMintAddress, setSelectedNftMintAddress] = useState("");
    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)
    const [allNfts, setAllNfts] = useState<any[]>(null)
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);

    const [listingNftJsx, setListingNftJsx] = useState<JSX.Element[] | null>(null);

    const generateRandomKey =(index) => {
        return new Date().getTime() + index;
    }

    const searchNft = (search)  => {
        
        // const userData = getUserSessionData();

        const params = {
            search: search,
            type: 'nft'
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/search', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) { 

                    setAllNfts(data.nfts);
                    localStorage.setItem('mintedNfts', JSON.stringify(data.nfts));
                    setListingNftJsx( data.nfts.map( (asset, index) => <Fragment key={index + (new Date()).getTime()}>
                    <a style={{"textAlign": 'center'}} className={props.useCarousel ? 'imgSliderDetails' : ''} href="#NftDetail1" onClick={() => showNftData(asset.nft_address)} data-address={asset.nft_address} >
                    <img alt="" src={asset.arweave_url} /><br />{asset.nft_name.split(".")[0]}</a></Fragment>) );
                }
            });
	};

    const showCollectionLink = async(collectionAddress, collectionName, address, nftName) => {

        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setPreviewCollectionName(<a href={"/collection/" + data.address}>{data.name}</a>);
                    setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={getExplorerUrl('address')+address+'?cluster='+ cluster}>{ data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);

                }else{
                    //not in the system, so display address to external url 
                    setPreviewCollectionName(<Fragment>{collectionName}</Fragment>);

                }
            });
            return;
      }
 
    const showNftData = (address) => {
        
        //console.log(allNfts);
        let nfts = allNfts === null ? JSON.parse(localStorage.getItem('mintedNfts')) : allNfts;

        if(allNfts) {
            localStorage.removeItem('mintedNfts');
        }
        // console.log(nfts);
        setUsernameJsx(<Fragment></Fragment>);
        for(var i in nfts) {
            if(nfts[i].nft_address === address) {
                 //console.log(nfts[i]);
                
                //setPreviewCollectionName(nfts[i].collection ? nfts[i].collection : '');


                let nftName = nfts[i].nft_name.split(".")[0];


                setPreviewCollectionName(<a href={"/collection/" + nfts[i].collectionAddress}>{nfts[i].collection}</a>);

                setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href='#nft'>{ nfts[i].collection.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);

                setPreviewImageDesc(nfts[i].description);

                setPreviewImageUrl(nfts[i].arweave_url);

                console.log(nfts[i].nft_address);

                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({})
                };
                fetch(address, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if(data.attributes) {
                                //console.log('show att');
                                const attributes = Array.isArray(data.attributes) ? data.attributes : JSON.parse(data.attributes);  
                                //console.log(attributes);
                                const properties = chunkArray(attributes, 2);
                                    // console.log(properties);
                                // setPropertiesJsx(attributes.map( (asset : any, index : number) => {
                                //     return asset && <Fragment key={index + (new Date()).getTime()}>
                                //             <div className="custom-column">{asset.trait_type} : {asset.value}</div>
                                //     </Fragment>;
                                // }));
            
                                const chunkedJSX = [];
            
                                for (let i = 0; i < properties.length; i ++) {
                                    let elem = properties[i];
            
                                    if(elem.length === 2) {
                                        chunkedJSX.push(
                                            <div className='row-elem' key={i}>
                                                {elem[0] &&  <div className="custom-column">{elem[0].trait_type} : <span className="prop-value">{elem[0].value}</span></div>} 
                                                {elem[1] &&  <div className="custom-column">{elem[1].trait_type} : <span className="prop-value">{elem[1].value}</span></div>} 
                                            </div>
                                        );
                                    }else{
                                        chunkedJSX.push(
                                            <div className='row-elem' key={i}>
                                                {elem[0] &&  <div className="custom-column">{elem[0].trait_type} : <span className="prop-value">{elem[0].value}</span></div>} 
                                            </div>
                                        );
                                    }
                                }
                                setPropertiesJsx (chunkedJSX);
                            
                        }
                    });

                
                // setPropertiesJsx()
            }
        }

        handleShowPreview();
    // }else{
    //      props.loginWeb3Auth();
    // }
}
    useEffect(() => {
        searchNft(params.searchTitle);

    },[params.searchTitle]);

    return (
        <Fragment>
            <div className="contents">
                
                <div className="collections popularCollections">
                <h5>Search Results for: {params.searchTitle}</h5>

                <div className="collectionsDiv">
                    <h5>COLLECTIONS</h5>
                    <CollectionList type="search" search={params.searchTitle}></CollectionList>
                </div>	

                <div className="collectionsDiv">
                    <h5>NFTS</h5>
                    {(listingNftJsx === null || listingNftJsx.length === 0) && <div style={{"position": "relative", "top": "-10px", "marginBottom" : "10px", "fontSize" : "18px"}}>No Results</div>}
                    {listingNftJsx}
                </div>

                <div className="collectionsDiv">
                    <h5>USERS</h5>
                    <RecentlyJoined type="search" search={params.searchTitle}></RecentlyJoined>
                </div>

                </div>

            </div>

            <Modal className="modal-preview" show={showPreview} onHide={handleClosePreview}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>{previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px"} }>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    { previewCollectionName && <Fragment><p className="pull-left">COLLECTION : {previewCollectionName}</p></Fragment>}
 
                        <p className="pull-left">DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                        


                        <p className="pull-left">PROPERTIES<br/></p>
                            <div className="custom-row">
                                {
                                    propertiesJsx ? propertiesJsx : 'N/A'
                                }
                            </div> 
                    <br />

                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>

        </Fragment>

    );
}

export default SearchView;