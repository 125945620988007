import React, {Fragment, useEffect, useRef, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import {createAccount, getBalance, cluster, getConnectionConfig, getUserSessionData, backend_api_url} from "../constants";
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';

const ForCreators = (props: any) => {


    return (
        <Fragment>
        <main>
  <section className="headline">
	<h1>FOR CREATORS</h1>
  </section>

  
  <section className="white">
	<div className="padding">
    {/* style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', margin: '20px', color: '#333' }} */}
  <div >
            <h2>Unique Merch for Fans and Followers</h2>
            <p>Creators can use Toybot NFTs to provide a differentiated product for their followers to enjoy. NFTs are unique digital assets on a public blockchain that fans can own, much like posters, stickers, and toys.</p>
            <p>NFTs come in sets called collections and are sold randomly, much like a toy from a <a target="_blank" href="https://en.wikipedia.org/wiki/Gashapon">gashapon</a> or Japanese capsule machine. Thus, everyone gets a surprise when an NFT is minted or bought.</p>

            <h2>Fast and Easy to Create</h2>
            <p>With Toybot, creators can build and sell their own NFT collections to their communities within a few days. Simply start with existing digital art, make minor changes, and convert them into NFTs. Creators can sell two types of collections:</p>
            <ul>
                <li><strong>OPEN</strong> has a defined number of designs that fans can collect. As fans buy an NFT, they can get duplicates.</li>
                <li><strong>LIMITED</strong> only has unique designs. So, each fan will always get their very own NFT, unlike any other.</li>
            </ul>

            <h2>High Margins, No Inventory Cost, and Worldwide Distribution</h2>
            <p>Perhaps the biggest benefit to creators. Toybot NFTs only cost very little to make. You also don’t have inventory to store in a warehouse nor shipping cost. And you can sell to anyone on the Internet from day 1 using the Toybot site.</p>
            <p>Compare that to the other merchandise you’re selling today. It’s really a no-brainer to expand your products to include Toybot NFTs.</p>

            <h2>Important: No Speculation</h2>
            <p>Toybot only works with creators to build and sell NFT merch for fans to enjoy as is. Toybot does not promise any future price appreciation, and the creators we work with are required to do the same.</p>

            <h2>FAQs</h2>
            <div>
                <div>
                    <strong>Q: What are NFTs?</strong>
                    <p>A: NFTs are non-fungible tokens. These are unique digital assets on a blockchain. Since blockchains are public and decentralized (not controlled by anyone), they belong solely to their owners.</p>
                </div>
                <br />
                <div>
                    <strong>Q: What blockchain is Toybot using?</strong>
                    <p>A: Toybot NFTs are on the Solana blockchain. Solana uses the SOL crypto token.</p>
                </div>
                <br />
                <div>
                    <strong>Q: How do I make Toybot NFTs for my followers?</strong>
                    <p>A: Reach out to <a href="mailto:hello@toybot.app">hello@toybot.app</a> to schedule a call. Once we determine a fit, we’ll use internal tools to create the NFTs and put them into a collection for you. At this time, there is no way for creators to build NFTs themselves.</p>
                </div>
                <br />
                <div>
                    <strong>Q: What do I need to know before starting?</strong>
                    <p>A: Mainly are you looking to sell an Open (a fixed set of designs) or Limited (each design is unique) collection, as each type requires different creative assets. You’ll need to know how many NFTs you’ll sell in each collection and how much each NFT will sell for. Finally, we’ll need to know how you are going to sell the NFTs.</p>
                </div>
                <br />
                <div>
                    <strong>Q: How much does it cost to build an NFT collection to sell?</strong>
                    <p>A: Minimal fees are required to set up the NFTs on the Solana blockchain, so Toybot passes this to the creator. Toybot does not make money when a creator builds a collection.</p>
                </div>
                <br />
                <div>
                    <strong>Q: How does Toybot make money?</strong>
                    <p>A: Toybot only makes money when creators make money. After removing credit card fees, we take a small percentage of NFT sales.</p>
                </div>

                <div>
                    <strong>Q: How do I sell the NFTs?</strong>
                    <p>A: Toybot NFTs are sold through the Toybot site, through a mint / buy function that dispenses a random NFT. Toybot collects payments and redeems them to you on a monthly basis.</p>
                </div>
                <br />
                <div>
                    <strong>Q: What does Toybot expect from the creators we work with?</strong>
                    <p>A: Creators are expected to promote their Toybot NFT collections to their followers to the best of their ability. Also, Toybot NFTs are merchandise and not for speculation, so creators cannot make promises of future price appreciation.</p>
                </div>
            </div>

            <h2>Build Your Own NFT Collection Now?</h2>
            <p>Email us at <a href="mailto:hello@toybot.app">hello@toybot.app</a></p>
        </div>
	</div>  
  </section>
</main>
         

        </Fragment>
    );
}
export default ForCreators;