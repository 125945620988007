import React, {Fragment, useEffect, useRef, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import {createAccount, getBalance, cluster, getConnectionConfig, getUserSessionData, backend_api_url} from "../constants";
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';

const HowItWorks = (props: any) => {


    return (
        <Fragment>
        <main>
  <section className="headline">
	<h1>HOW IT WORKS</h1>
  </section>

  
  <section className="white">
	<div className="padding">
	{/* style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', margin: '20px', color: '#333' }} */}
  <div >
            <h2>Support Awesome Creators</h2>
            <p>Your favorite creators, artists, and designers use Toybot to sell their own designs as NFT merch for you to enjoy. The NFTs come in sets called collections.</p>

            <h2>A Surprise for Every Purchase</h2>
            <p>When you mint / buy an NFT from each collection, you get a random design, much like a <a target="_blank" href="https://en.wikipedia.org/wiki/Gashapon">gashapon</a> or Japanese capsule machine.</p>
            <p>If the collection is OPEN, you will get one design from a group of designs. In Open collections, you can collect all the individual designs, and you might get duplicate NFTs.</p>
            <p>If the collection is LIMITED, you will get a unique design. In Limited collections, there are no duplicates at all. Each NFT is one-of-a-kind, and only you will own it!</p>

            <h2>Finite Number of NFTs in Each Collection</h2>
            <p>Each collection has a pre-determined number of NFTs inside them. Once the last NFT is sold, the collection is completed and no other NFTs can be minted. So, you should make sure you mint an NFT before they run out.</p>

            <h2>Important: Toybot NFTs are Merch</h2>
            <p>Toybot NFTs are meant to be creator merchandise like posters, stickers, toys, etc. These are made to support the creator and to be enjoyed as is. If you are speculating on price, do NOT buy Toybot NFTs.</p>

            <h2>Minting / Buying an NFT</h2>
            <p>Follow these steps:</p>
            <ol>
                <li>Navigate to a creator’s NFT collection page. For example: Toybot’s <a target="_blank" href="https://toybot.app/collection/8Z2b7eiTr42VcgN1oDBQE71Wz4ubqxdq47hRqFRUGXcj">Hex Pilots</a> collection</li>
                <li>Click the Mint button in the middle of the page
                    <ul>
                        <li>If you are not logged in, it will prompt you to log in or create an account</li>
                        <li>To log in / create an account, you’ll be asked to sign in with Google (though you can use other accounts like Facebook, Reddit, Discord, Twitter/X, etc.)</li>
                        <li>Once you create an account, Toybot will create a crypto wallet for you, to hold your NFTs – once you purchase an NFT, you can move them to your other wallets from there</li>
                    </ul>
                </li>
                <li>To buy an NFT, you need credits. To put it simply: 1 credit = $1
                    <ul>
                        <li>You can use a credit card to buy credits - simply follow up the check-out process</li>
                        <li>At this time, we do not accept crypto tokens for payments</li>
                    </ul>
                </li>
                <li>Once purchased, a window will open and you will receive a random surprise NFT</li>
                <li>To view or transfer your NFT, navigate to your wallet page</li>
            </ol>

            <div>
                <h2>FAQS</h2>
                
                <div>
                    <strong>Q: What are NFTs?</strong>
                    <p>A: NFTs are non-fungible tokens. These are unique digital assets on a blockchain. Since blockchains are public and decentralized (not controlled by anyone), they belong solely to you.</p>
                </div>
                <br />
                <div>
                    <strong>Q: What blockchain is Toybot using?</strong>
                    <p>A: Toybot NFTs are on the Solana blockchain. Solana uses the SOL crypto token.</p>
                </div>
                <br />
                <div>
                    <strong>Q: I don’t have crypto. How can I buy an NFT?</strong>
                    <p>A: We make this easy, you can use your credit card to buy credits. Credits are used to buy Toybot NFTs. 1 credit = $1. Credits are not crypto tokens. At this time, we don’t accept crypto for Toybot NFTs.</p>
                </div>
                <br />
                <div>
                    <strong>Q: I don’t have a crypto wallet. Where will my NFTs go?</strong>
                    <p>A: Each Toybot account has its own wallet, to store NFTs bought from Toybot. From here, you can view your NFTs or transfer them to an external wallet.</p>
                </div>
                <br />
                <div>
                    <strong>Q: How do I transfer my NFT to another wallet?</strong>
                    <p>A: Find the NFT you want to transfer in your wallet and click on it. It will open a panel where you can enter a wallet address to send the NFT to. A couple of important items to remember:</p>
                    <ul>
                        <li>You need a small amount of SOL (under 0.01 SOL) to make this transfer – you either have to buy them from us, or send SOLs to your Toybot wallet (using its address), and</li>
                        <li>Once an NFT is sent, we can NOT undo it. The NFT is transmitted through the blockchain to a wallet beyond our control, so make sure you put the exact wallet address. This is your responsibility.</li>
                    </ul>
                </div>
                <br />
                <div>
                    <strong>Q: Can I sell my NFT? Will their price appreciate?</strong>
                    <p>A: Once you buy an NFT, you can do whatever you want with it. That said, Toybot NFTs are meant to be creator merchandise – think posters, stickers, toys from the artist or designer. These are made to support the creator and to be enjoyed as is. To be clear, there is no promise of price appreciation. In fact, you shouldn’t mint an NFT if you only care about reselling them for a higher price down the road.</p>
                </div>
                <br />
                <div>
                    <strong>Q: Can I get a refund?</strong>
                    <p>A: We do NOT offer refunds once an NFT is sold. So, before you mint and confirm, make sure you really want one. We will not take the NFT back nor send credits to your account. We can refund unused credits though.</p>
                </div>
                
                
                <h2>Need More Help?</h2>
            <p>Email us at <a href="mailto:hello@toybot.app">hello@toybot.app</a></p>

 


            </div>
        </div>
  
	</div>  
  </section>
</main>
         

        </Fragment>
    );
}
export default HowItWorks;