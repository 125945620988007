import { Fragment, useEffect, useRef, useState } from "react";
import { backend_api_url, getUserSessionData, isUserAdmin } from "./constants";
import { Typeahead } from "react-bootstrap-typeahead";
import Modal from 'react-bootstrap/Modal';

const PromoCodesContainer = (props: any) => {

    const codeInput = useRef<HTMLInputElement>(null);
    const numberInput = useRef<HTMLInputElement>(null);

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const [options, setOptions] = useState<any[]>([]);
 
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [currentId, setCurrentId] = useState(0);

    
    const [showAdjust, setShowAdjust] = useState(false);
    const handleCloseAdjust = () => setShowAdjust(false);
    const handleShowAdjust = () => setShowAdjust(true);

    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [singleSelections, setSingleSelections] = useState([]);

    const [showClear, setShowClear] = useState(false);
    const handleCloseClear = () => setShowClear(false);
    const handleShowClear = () => setShowClear(true);
    const [deleteId, setDeleteId] = useState(0);

    const [showClear1, setShowClear1] = useState(false);
    const handleCloseClear1 = () => setShowClear1(false);
    const handleShowClear1 = () => setShowClear1(true);
    const [endId, setEndId] = useState(0);



    const fetchAllGachas = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/all', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    setOptions(data.dataList); 

                }
            });
	};
 
   
    
    const confirmAdjustment = ( ) => {
        // console.log(id);
        // console.log(e.target.value);
        setHasError(false);

        if(singleSelections.length === 0) {
            setHasError(true);
            setErrorMessage("Please select collection");
            return;
        }

        if( parseInt(numberInput.current.value) > 0) {
            ;
        } else {
            setHasError(true);
            setErrorMessage("Number of Free NFT is required");
            return;
        } 

        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);
        setHasError(false);
        const params = {
            token: userData.token,
            secret: userData.secret,
            id: singleSelections[0].id,
            code: codeInput.current.value,
            num: parseInt(numberInput.current.value),
            promoId: currentId
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/add-promo', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    ;//showAllUsers(data.users);
                    handleShowAdjust();
                    loadPromos();
                } else if(data.status === 2) {
                    

                        setHasError(true);
                        setErrorMessage("Promo Code should be unique.");
                    
            
                }
            });
    }
 
    const loadPromos = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/promo-codes', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    showInfo(data.items);
                    
                }
            });
	};

    const editPromo = (e, id) => {
        e.preventDefault();
        const userData = getUserSessionData();
 
        const params = {
            token: userData.token,
            secret: userData.secret,
            id: id
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/promo-info', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    //showInfo(data.items);
                    
                    setCurrentId(data.info.id);
                    codeInput.current.value = data.info.code;
                    numberInput.current.value = data.info.num_of_nft;
 
                    setOptions(data.dataList); 

                    if(data.dataList.length > 0) {
                        for(var i in data.dataList) {
                            console.log(data.dataList[i].id +"  === " +data.info.gacha_id);

                          if(data.dataList[i].id === data.info.gacha_id) {

                            setSingleSelections([data.dataList[i]]);
                          }
                        }
                      }

                }
            });
    }

    const activatePromo = (e, id) => {
        e.preventDefault();
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
            id: id
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/activate-promo', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    handleShowAdjust();
                    loadPromos();
                }
            });
    }

    const pausePromo = (e, id) => {
        e.preventDefault();
        const userData = getUserSessionData();
 
        const params = {
            token: userData.token,
            secret: userData.secret,
            id: id
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/pause-promo', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    ;//showAllUsers(data.users);
                    handleShowAdjust();
                    loadPromos();
                }
            });
    }

    const endPromo = (e, id) => {
        e.preventDefault();
        
        setEndId(id);

        handleShowClear1();
    }

    const endPromoNow = () => {
        handleCloseClear1();

        const userData = getUserSessionData();
 
        const params = {
            token: userData.token,
            secret: userData.secret,
            id: endId
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/end-promo', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    ;//showAllUsers(data.users);
                    handleShowAdjust();
                    loadPromos();
                }
            });
    }

    const deletePromo = (e, id) => {
        e.preventDefault();
        
        setDeleteId(id);

        handleShowClear();
    }

    const deleteNow = () => {

        handleCloseClear();
        const userData = getUserSessionData();
 
        const params = {
            token: userData.token,
            secret: userData.secret,
            id: deleteId
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/delete-promo', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    ;//showAllUsers(data.users);
                    handleShowAdjust();
                    loadPromos();
                }
            });
    }

    const showInfo = async (lists: any[]): Promise<any> => {
 
        //console.log(allListings)
       setListingJsx( lists.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
          <tr>
              <td>{info.date}</td>
              <td> 
              <a href={'#edit' + info.id} onClick={(e) => editPromo(e, info.id)}>{info.name}</a>
              </td>
              <td>
                {
                    info.status == 1 && <Fragment><a  rel="noreferrer" href={"/collection/"+info.address+"/code/" + info.code} target="_blank">{info.code}</a></Fragment>
                }
                {
                    info.status != 1 && <Fragment>{info.code}</Fragment>
                }
                
            </td>

              <td>
              {info.num_of_nft}
                  </td>

              <td>{info.num_of_claimed}</td>
              <td>{info.status_display}</td>
              <td>
                  

                  {
                    info.status == 0 && <Fragment><a href="#activate" onClick={(e) => activatePromo(e,info.id)}>Activate</a> - </Fragment>
                  }
                  {
                    info.status == 1 && <Fragment><a href="#pause" onClick={(e) => pausePromo(e,info.id)}>Pause</a> - </Fragment>
                  }

                    {
                    info.status != 2 && <Fragment><a href="#pause" onClick={(e, ) => endPromo(e, info.id)}>End</a> - </Fragment>
                  }

                  {
                    <Fragment><a href="#delete" onClick={(e, ) => deletePromo(e, info.id)}>Delete</a></Fragment>
                  }

            </td> 
 

          </tr>
          </Fragment>) );

   };


	useEffect(() => {
		
        //fetchAllMyGachas();
        if(!isUserAdmin()) {
            window.location.href = '/';
        }
        fetchAllGachas();
        loadPromos();
	  }, []);


      const state = {
        disabled: false,
        dropup: false,
        flip: false,
        highlightOnlyResult: true,
        minLength: 0,
        open: undefined,
      };

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">
               <a href="/admin/users">Users</a>&nbsp;|&nbsp;               
               <a href="/admin/creators">Creators</a>&nbsp;|&nbsp;               
               <a href="/admin/collections">Collections</a>&nbsp;|&nbsp;               
               <a href="/admin/purchases">Purchases</a>&nbsp;|&nbsp; 
               <a href="/admin/actions">Actions</a>

                <br /><br /><br />
               <div>
               <h5 className="outer">Promo Codes</h5> 

                {hasError && <Fragment><div style={{"textAlign" : "center", "color" : "red"}} className="alert alert-danger">{errorMessage}</div></Fragment>}
               <table style={{"border" : 0}} className="createCollectionTable">
      <tbody>
      
  
        <tr>
          <td  align="right">Collection</td>
          <td className="collection-typeahead"> 
              <Typeahead
                {...state} 
                id="basic-typeahead-single"
                labelKey="name"
                onChange={setSingleSelections}
                options={options}
                placeholder="Choose Collection"
                selected={singleSelections} 
              /> 
          </td>
        </tr> 

        <tr>
          <td  align="right">Code</td>
          <td> 
            <input type="text" className="textbox" required  ref={codeInput}/>

          </td>
        </tr> 
 
        <tr>
            <td align="right"># Free NFTs</td>
            <td>
            <input type="text" className="textbox" required  ref={numberInput}/>
            </td>	 
        </tr>
         

      </tbody>
    </table>

    <div className="createCollectionSubmit" style={{"textAlign" : "center", "marginTop" : "30px"}}>
 <button type="button" onClick={confirmAdjustment} className="buttonLink btn-custom">SAVE</button>

</div>  
                </div> 
                <div>

                <table className="walletActivityTable admin-infos"  style={{ "borderSpacing" : 0}} >
                <tbody>
                    <tr>
                    <td align="center"><b>Date</b></td>
                    <td align="center"><b>Collection</b></td>
                    <td align="center"><b>Code</b></td>
                    <td align="center"><b>Max # Free</b></td>
                    <td align="center"><b># Redeemed</b></td>
                    <td align="center"><b>Status</b></td>
                    <td align="center"><b>Actions</b></td> 
                    </tr>	

                    {listingJsx}
                
                </tbody>
                </table>


                </div>
            </div>
                
         
            <Modal  className="modal-preview"  show={showAdjust} onHide={handleCloseAdjust} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        Operation Successful
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 

            <Modal  className="modal-error"  show={showClear} onHide={handleCloseClear} backdrop="static">
            <Modal.Header closeButton={true}>
            <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div style={{ "textAlign" : "center"}}>
                    Are you sure you want to delete this promo code?
                </div>

                <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                    <button onClick={handleCloseClear} className="buttonLink btn-generic">CANCEL</button>

                    <button onClick={deleteNow} className="buttonLink btn-generic">CONFIRM</button>
                </div>
                
            
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

        <Modal  className="modal-error"  show={showClear1} onHide={handleCloseClear1} backdrop="static">
            <Modal.Header closeButton={true}>
            <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div style={{ "textAlign" : "center"}}>
                    Are you sure you want to end this promo code?
                </div>

                <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                    <button onClick={handleCloseClear1} className="buttonLink btn-generic">CANCEL</button>

                    <button onClick={endPromoNow} className="buttonLink btn-generic">CONFIRM</button>
                </div>
                
            
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

             
            
        </Fragment>
    );
}

export default PromoCodesContainer;