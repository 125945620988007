import React, {Fragment, useEffect, useState} from "react";
import { Link, useParams } from "react-router-dom";
import LatestListings from "./LatestListings";
import RecentlyMinted from "./RecentlyMinted";
import CollectionList from "./CollectionList";
import Slider from "react-slick";
import { backend_api_url, settingsHomeBannerSlider, settingsSlider } from "./constants";
import RecentlyJoined from "./RecentlyJoined";
import Modal from 'react-bootstrap/Modal';
import CollectionListIg from "./CollectionListIg";

const MainContainerIg = (props: any) => {
 
    const goToAddLink = (e) => {
        e.preventDefault();
        checkLogin();
    }

    const [mintError, setMintError] = useState("");
    const [mintErrorHeader, setMintErrorHeader] = useState("");
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const checkLogin = () => {
        window.location.href = "/add";
    }

    const paramsPage = useParams();

    const activateAccount = () => {


    if(!paramsPage.id || !paramsPage.email)
        return;
        

    const params = {
        id: paramsPage.id,
        email: paramsPage.email
    } 
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/activate', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 0) {
                //toast.error(data.message);
                setMintErrorHeader("Notification");
                setMintError(data.message);
                handleShowError();
                
            }else if(data.status === 1) {
                //show complete account
                //props.handleShowCompleteAccount();
 
                //toast.success("Account Activated Successfully");
                
                setMintErrorHeader("Notification");
                setMintError("Account Confirmed Successfully");
                handleShowError();

                const userInfo = {
                    token: data.token,
                    secret: data.secret,
                    username: data.username,
                    wallet: data.wallet,
                    isNew: 0
                } 
                localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(userInfo));

            }
        });
}

    useEffect(() => {
        activateAccount();
    }, [paramsPage.id, paramsPage.email]);

    props.setClassInfo("homePg");

    return (
        <Fragment>
            <div className="contents">
                <div className="collections ig latestCollections">
                    <h5>LATEST COLLECTIONS</h5> 
                    <div id="ImageSlider">
                        <div id="ImageSliderDiv" className="slider slider-minted">
                            <CollectionListIg useCarousel={false} type="latest" search=""></CollectionListIg>
                        </div>
                    </div>
 
            
                </div>

            </div>
             
            <Modal  className="modal-processing"  show={showError} onHide={handleCloseError} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>{mintErrorHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                    <div style={{"textAlign": "center"}}>
                        {mintError}
                    </div>
    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 

        </Fragment>
    );
}

export default MainContainerIg;