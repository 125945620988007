import React, {Fragment, useEffect, useRef, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import {createAccount, getBalance, cluster, getConnectionConfig, getUserSessionData, backend_api_url} from "./../constants";
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';

const Support = (props: any) => {


    return (
        <Fragment>
        
        <main>
  <section className="headline">
	<h1>Customer Support</h1>
  </section>

  
  <section className="white">
	<div className="padding">
    <p><b>Need help?</b> Call <a href="tel:+1-510-499-8673">1-510-499-8673</a></p>

	  <br />
	   <p>For all other inquires, please reach out to:</p>
       <br />
	  <p>Toybot.app<br />

707 Continental Circle #312 <br />
Mountain View, California<br /></p>
	  
    </div>
  </section>
</main>
 


         

        </Fragment>
    );
}
export default Support;