import React, {Fragment, useState, useEffect} from "react"; 
 import { useConnection } from '@solana/wallet-adapter-react';
import {  PublicKey} from "@solana/web3.js";

import { getBalance } from "./constants";


const MachineSol = (props: any) => { 
    const [amount, setAmount] = useState<string>('');
    const [viewable, setViewable] = useState(false);

    const { connection } = useConnection();

    

    const fetchMachineBalance = async () => { 
        try{
            setViewable(true);
            let address = props.address
            const balance = await getBalance(new PublicKey(address), connection);
            setAmount(balance.toFixed(3));
 
            //console.log(props.signature + "=" + txDate + "=");
        }catch(e) {
            setAmount("0.000");     
        }  
    }

    useEffect(() => {
        if(props.address == 'Pending') {
            setViewable(true);
            setAmount("0.000");     
        }else if(true || props.index < 10) {

            fetchMachineBalance();
        }
        //{props.signature}
    }, []);
    return (
        <Fragment>
           {viewable  &&  amount  }
           {!viewable  &&  <a href={'#' + props.address} onClick={fetchMachineBalance}>View Balance</a>  }

        </Fragment>

    )

}

export default MachineSol;