import { Fragment } from 'react';
import { PublicKey , Keypair, LAMPORTS_PER_SOL, GetProgramAccountsFilter} from "@solana/web3.js";
import type { Connection, Cluster } from '@solana/web3.js';
import bs58 from 'bs58';
import nacl from "tweetnacl";
import RPC from "./SolanaRpc"; 
import axios from "axios";
import base58 from "bs58";
import { toast } from "react-toastify";
import fs from 'fs'
import { SolanaWallet } from '@web3auth/solana-provider';
import * as splToken from "@solana/spl-token";


import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { TrustWalletAdapter } from '@solana/wallet-adapter-wallets';
import { DefaultDraftInlineStyle } from 'draft-js';

export interface Application {
	name: string;
	publicKey: string;
}
 
export const backend_api_url : string  =  process.env.REACT_APP_BACKEND_SERVER_URL as string;

export const cluster: string = process.env.REACT_APP_CLUSTER  as string;
export const stripe_pub_key: string = process.env.REACT_APP_STRIPE_PUB_KEY  as string;

export const createAccount = () : Keypair => {
	const keypair = Keypair.generate();
	return keypair
};

export const GACHA_API =   process.env.REACT_APP_REST_BACKEND_SERVER_URL as string; //"https://api.toybot.app/api/";// "https://api.nectarin.net/api/";
export const USDC_DEVNET_ACCOUNT = "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr";
export const USDC_MAINNET_ACCOUNT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
export const TOKEN_PROGRAM_ID = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
export const CANDY_MACHINE_ADDRESS = "CndyV3LdqHUfDLmE5naZjVN8rBZz4tqhdefbAnjHG3JR";

const generateZipFile = async (params, withJson) => {
	const allImages = params.allFiles;
	
	let zip = new JSZip();
   
	await Promise.all(
		allImages.map(async (asset: any) => {
			try {
				const getBase64StringFromDataURL =  asset.file.replace('data:', '').replace(/^.+,/, '');
				zip.file(asset.actualFile.name, getBase64StringFromDataURL, {base64: true});

				return {'name' : asset.name, 'file' : asset.file};
			} catch (err: any) {
				return null;
			}
			

		})
	);

	if(withJson) {
		const metaFiles = params.metaFiles;

		await Promise.all(
			metaFiles.map(async (asset: any) => {
				try {
					zip.file(asset.actualFile.name, JSON.stringify(asset.file));
	
					return {'name' : asset.name};
				} catch (err: any) {
					return null;
				}
				
	
			})
		);
	}
  
	let zipFile = await zip.generateAsync({type:"blob"})
	
		
	var file = new File([zipFile], "images.zip", {type: zipFile.type});
	return file;
}

const chunk = (arr, size) =>
	Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
	  arr.slice(i * size, i * size + size)
	);

const generateChunkZipFile = async (params, withJson, uuid) => {
	const allImages = params.allFiles;
	
	
	let allRecords = [];
	
	await Promise.all(
		allImages.map(async (asset: any) => {
			try {
				const getBase64StringFromDataURL =  asset.file.replace('data:', '').replace(/^.+,/, '');

				
				allRecords.push({'name' : asset.actualFile.name, 'type' : "base64", 'data' : getBase64StringFromDataURL});

				return {'name' : asset.name, 'file' : asset.file};
			} catch (err: any) {
				return null;
			}
			

		})
	);

	if(withJson) {
		const metaFiles = params.metaFiles;

		await Promise.all(
			metaFiles.map(async (asset: any) => {
				try {
					
					allRecords.push({'name' : asset.actualFile.name, 'type' : "json", 'data' : JSON.stringify(asset.file)});

					return {'name' : asset.name};
				} catch (err: any) {
					return null;
				}
				
	
			})
		);
	}

	//console.log(allRecords.length);

	//console.log(allRecords);

	let chunkSize = 1000; //2000;
	let chunkFiles = chunk(allRecords, chunkSize);

	//console.log(chunkFiles);

	let allZipFiles = [];
	for(var x in chunkFiles) {
		let file = await generateChunkZipFileNow(chunkFiles[x], x, uuid);
		allZipFiles.push({'index' : x, 'length' : chunkFiles[x].length,  'zip' : file});
	}

	return allZipFiles;

	

}

const generateChunkZipFileNow = async(chunkFiles, index, uuid) => {
	let zip = new JSZip();
   
	for(var i in chunkFiles) {
		let asset = chunkFiles[i];
		if(asset.type === 'base64')
			zip.file(asset.name, asset.data, {base64: true});
		else if(asset.type === 'json')
			zip.file(asset.name, asset.data);
	} 

	
	let zipFile = await zip.generateAsync({type:"blob"})
	
		
	var file = new File([zipFile], "images_"+uuid+"_"+index+".zip", {type: zipFile.type});
	return file;
}

export const base64ToFile = (base64String: string, fileName: string) => {
	let arr: any = base64String.split(","),
	  mime = arr[0].match(/:(.*?);/)[1],
	  bstr = atob(arr[1]),
	  n = bstr.length,
	  u8arr = new Uint8Array(n)
  ;
	while (n--) {
	  u8arr[n] = bstr.charCodeAt(n)
  ;
	}
	return new File([u8arr], fileName, { type: mime });
  };

  export const zipFileToFile = (file, fileName: string) => {
	
	return new File([file.data], fileName, { type: file.mime });
  };

export const doZipUpload = async(params: any,params2:any, candyMachineData : any, candyMachineId : any, hideProcessing: any, setUploadedItems: any, setStepName: any, setIsCreateSuccess : any) => {
	
	const allImages = params2.allFiles;

	//var file = await generateZipFile(params2, false);

	let bulkId = await bulkUploadToArweaveNew(params.creator, candyMachineId);
	
	setStepName("Bulk Uploading Images");

	if(bulkId === false) {
		toast.error("Upload failed, please try again");
		hideProcessing();
		return false;
	}


	//poll for bulk upload
	//bulk upload meta
	//poll for bulk upload meta
	//upload asset to backend
	//bulk insert items to gacha

	const uploadedNfts1 = []; 

	const allMetaFiles = params2.metaFiles;
	let jsonFile;
	for(var i in allImages) {
		for(var j in allMetaFiles) {
			var fileNameIndex = allImages[i].name.split(".")[0];
			if(fileNameIndex+'.json' === allMetaFiles[j].name) {
				jsonFile = allMetaFiles[j].file;

				uploadedNfts1.push({
					name: fileNameIndex,
					//symbol: params.symbol,
					//description: params.description,
					attributes: jsonFile.attributes,

					filename: allImages[i].name,

					image: "temp",
					uri: 'temp',
					metadata_uri: 'temp'
				});
			}
		}
	}

	await uploadAssetToBackendv2(params.creator, params.description,params.symbol, uploadedNfts1, candyMachineData, candyMachineId, bulkId);
	
	setStepName("Candy Machine is being processed");

	pollForCandyMachine(candyMachineId, setStepName, hideProcessing, candyMachineData.gachaMachine, setIsCreateSuccess);


	return;
	
}

export const pollForBulkUploadProgress = async(bulkId : any) : Promise<any> =>{

 
	let trial = 0;
	let fetchGacha = false;
	do{
		fetchGacha = await axios.get(GACHA_API + "upload/bulk/"+bulkId+'?=t'+trial+'&d='+new Date()).then((response) => {
			//console.log(response.status, response.data);
			const dataResp = response.data;
			if(dataResp.status === 'COMPLETED')
				return dataResp.data;
			return false;
		}).catch((error) => {
			console.log(error);
			return false;
		});

		if(!fetchGacha) {
			trial++;
			await wait(5000);
		}else{

		}
		//console.log(trial);
		//console.log(fetchGacha);
	}while(!fetchGacha  && trial < 200);

	return fetchGacha;
}

export const pollForCandyMachine = async(candyMachineId: any, setStepName: any, hideProcessing: any, address: any, setIsCreateSuccess : any)  => {

 
	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		candyMachineId: candyMachineId

	}
 
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};

	fetch(backend_api_url + 'api/v1/machine/progress?d='+new Date(), requestOptions)
		.then(response => response.json())
		.then(data => {
			if(data.status === 1) {
 
				//if(hideProcessing) {
					setIsCreateSuccess(address, 'Toy Machine Created');
					//("Candy Machine Created Successfully");
					//hideProcessing();
				//}
				
				//arweave upload progress
			} else if(data.status === 2 || data.status === 5) {
 
					setIsCreateSuccess(address, "Request Submitted");
				
				
			}else{
				setStepName(data.message);

				setTimeout(function() {
					pollForCandyMachine(candyMachineId, setStepName, hideProcessing, address, setIsCreateSuccess);
				}, 5000);
			}
		});
}

export const pollForBulkUploadMetaDataProgress = async(bulkId : any) : Promise<any> =>{

 
	let trial = 0;
	let fetchGacha = false;
	let doCount = true;
	do{
		doCount = true;
		fetchGacha = await axios.get(GACHA_API + "upload/metadata/bulk/"+bulkId+'?=t'+trial+'&d='+new Date()).then((response) => {
			//console.log(response.status, response.data);
			const dataResp = response.data;
			if(dataResp.status === 'COMPLETED')
				return dataResp.bulk;
			else if(dataResp.status === 'PENDING') {
				doCount = false;
			}
			return false;
		}).catch((error) => {
			//console.log(error);
			return false;
		});

		if(!fetchGacha) {
			if(doCount)
				trial++;
			await wait(5000);
		}else{

		}
		//console.log(trial);
		//console.log(fetchGacha);
	}while(!fetchGacha  && trial < 2000);

	return fetchGacha;
}


export const bulkUploadToArweaveNew = async(creator: any, candyMachineId : any) : Promise<any> => {
	   
	
	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		candyMachineId : candyMachineId,
		creator : creator,

	}
 
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};
	let resp = await fetch(backend_api_url + 'api/v1/machine/upload-arweave', requestOptions)
		.then(response => response.json())
		.then(result => {
			return result.bulkId;
		});

	return resp;
 
}


export const bulkUploadToArweave_old = async(creator: any, file : File) : Promise<any> => {
	  
	var formdata = new FormData();
	formdata.append("file", file, file.name);
	formdata.append("creator", creator);

 
	var requestOptions = {
		method: 'POST',
		body: formdata,
	};
	
	let trial = 0;
	let resp = false;
	do{
		resp = await fetch(GACHA_API + 'upload/bulk', requestOptions)
		.then(response => response.json())
		.then(result => { 
			return result.data.bulkId;
		})
		.catch((error) => {

			toast.error(error.response);

			return false;
		});;

		if(!resp) {
			trial++;
			await wait(5000);
		}else{

		}

	}while(!resp  && trial < 2000);
	
	
	return resp;
 
}

export const bulkUploadMetadata = async(creator: any, allMetaFiles: any, metaData: any) : Promise<any> => {
	  
	const allItems = [];
	let jsonFile;

	for(var i in metaData) {
		for(var j in allMetaFiles) {
			var fileNameIndex = metaData[i].originalFilename.split(".")[0];
			if(fileNameIndex+'.json' === allMetaFiles[j].name) {
				jsonFile = allMetaFiles[j].file;

				allItems.push({
					name: jsonFile.image,
					symbol: jsonFile.symbol,
					description: jsonFile.description,
					image: metaData[i].image
				});

				break;
			}
		}
	}
 
	

	 


	const gachaData = {
		creator: creator,
		params: allItems 
	};

	let resp = false;
	resp = await axios.post(GACHA_API + "upload/metadata/bulk", gachaData).then((response) => {
		const dataResp = response.data;
		return dataResp.data;
	}).catch((error) => { 
		toast.error(error.response.message);

		return false;
	});
	return resp;
 
 
}

export const continueAssetUpload = async(params: any, candyMachineData : any, candyMachineId : any, hideProcessing: any, setUploadedItems: any, setStepName: any) => {
	
	
	const allImages = params.allFiles;

	const uploadedNfts = await Promise.all(
		allImages.map(async (asset: any) => {
			let assetUrl: string;
			try {
				let retData = await uploadToArweave(params.creator, asset);
				 
				assetUrl = retData;

				
				let metaDataUri = await uploadToArweaveMetadata(params.creator,  asset, params.metaFiles, assetUrl);
				
				//console.log(metaDataUri);

				return {'name' : asset.name, 'uri' : assetUrl, 'metadata_uri' : metaDataUri};

			} catch (err: any) {
				return null;
			}
			

		})
	);

	await uploadAssetToBackend(uploadedNfts, candyMachineData, candyMachineId);

	await insertItemsToGacha(params, candyMachineData, uploadedNfts, setUploadedItems, setStepName);

	if(hideProcessing) {
		toast.success("Candy Machine Created Successfully");
		hideProcessing();
	}
	return uploadedNfts;
}
 
export const wait = function (ms = 1000) {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
};

const saveMintedGacha = async(candyMachineAddress, responseData) => {

	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		data: responseData,
		candy_machine_address: candyMachineAddress

	}
 
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};
	fetch(backend_api_url + 'api/v1/users/save-mint', requestOptions)
		.then(response => response.json())
		.then(data => {
			
		});


} 
 
export const giveNewGacha = async(params : any, setMintErrorMessage : any, handleClose: any) => {
	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		candy_machine_address: params.candyMachineAddress,
		receiver: params.receiver,
		assetId: params.assetId
	}
 
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};
	fetch(backend_api_url + 'api/v1/users/give-new', requestOptions)
		.then(response => response.json())
		.then(data => {

			if(data.status === 1) {
				 //showSuccessfulMint(data);
				 setMintErrorMessage("Transferred Successfully", "Notification");

				 handleClose();
				//pollForMintProgress(data.pendingId, data.masterNftId, params.candyMachineAddress, showSuccessfulMint, setMintErrorMessage, setShowBuyCrypto, handleClose);
			} else {
				let header = 'Error';
 				let errorMessage = data.message;
				
				if(errorMessage.indexOf('more items') > 0) {
					errorMessage = 'Sold Out. All NFTs have been minted.';
					header = 'Sold Out';
				}
				setMintErrorMessage(errorMessage, header);

				// if(errorMessage.indexOf('not have enough') > 0) {
				// 	setShowBuyCrypto(true);
				// }
				handleClose();
			}

			
		});
}

export const mintNewGacha = async(params : any, showToastError: any, setMintErrorMessage : any, setShowBuyCrypto : any, handleClose : any, showSuccessfulMint : any, promo) => {
	 
	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		candy_machine_address: params.candyMachineAddress,
		receiver: params.receiver,
		paymentIntentId: params.paymentIntentId,
		code: promo
		//isNew: params.isNew,
	}
 
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};
	fetch(backend_api_url + 'api/v1/users/mint-new', requestOptions)
		.then(response => response.json())
		.then(data => {

			if(data.status === 1) {
				 showSuccessfulMint(data);
				 handleClose();
				//pollForMintProgress(data.pendingId, data.masterNftId, params.candyMachineAddress, showSuccessfulMint, setMintErrorMessage, setShowBuyCrypto, handleClose);
			} else {
				let header = 'Error';
 				let errorMessage = data.message;
				
				if(errorMessage.indexOf('more items') > 0) {
					errorMessage = 'Sold Out. All NFTs have been minted.';
					header = 'Sold Out';
				}
				setMintErrorMessage(errorMessage, header);

				// if(errorMessage.indexOf('not have enough') > 0) {
				// 	setShowBuyCrypto(true);
				// }
				handleClose();
			}

			
		});


}


export const pollForMintProgress = async(pendingId: any, masterNftId: any, candy_machine_address: any, showSuccessfulMint : any, setMintErrorMessage : any, setShowBuyCrypto : any, handleClose : any)  => {

 
	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		candy_machine_address: candy_machine_address,
		pendingId : pendingId,
		id: masterNftId
	}
 
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};

	fetch(backend_api_url + 'api/v1/users/mint-progress?d='+new Date(), requestOptions)
		.then(response => response.json())
		.then(data => {
			if(data.status === 1) {
 
				showSuccessfulMint(data.address);
				handleClose();
			}else if(data.status === 2) {
				setTimeout(function() {
					pollForMintProgress(pendingId, masterNftId, candy_machine_address, showSuccessfulMint, setMintErrorMessage, setShowBuyCrypto, handleClose);
				}, 5000);
			}else{
				let header = 'Error';
				let errorMessage = data.message;
			   
				if(errorMessage.indexOf('more items') > 0) {
					errorMessage = 'Sold Out. All NFTs have been minted.';
					header = 'Sold Out';
				}
				setMintErrorMessage(errorMessage, header);

				if(errorMessage.indexOf('not have enough') > 0) {
					setShowBuyCrypto(true);
				}
				
			}
		});
}

export const mintNewGachaOld = async(params : any, showToastError: any, setMintErrorMessage : any, setShowBuyCrypto : any) => {
	 
	let gachaData = {};

	if(params.edition === 'limited') {
		gachaData = {
			minter: params.receiver, // params.creator,
		};
		
	}else if(params.edition === 'open') {
		gachaData = {
			minter: params.gachaWallet, // params.creator,
			editionReceiver: params.receiver
		};
	}

	const resp = await axios.post(GACHA_API + "gacha/"+params.candyMachineAddress+"/mint", gachaData).then((response) => {
		
		
		const dataResp = response.data;

		

		return dataResp;
	}).catch((error) => {
		let header = 'Error';
		//console.log(error.response.data);
		let errorMessage = "Wallet does not have enough USDC. Send USDC to " + params.currentWallet;
		if(error.response.data.error && error.response.data.error === 'AppError') {
			if(error.response.data.message.indexOf('more items') > 0) {
				errorMessage = 'Sold Out. All NFTs have been minted.';
			}else{
				errorMessage = error.response.data.message;
			}
			
		}else if(error.response.data.error && error.response.data.error === 'BlockchainFailure') {
			errorMessage = 'Blockchain Minting failed';
		}
		if(errorMessage.indexOf('more items') > 0) {
			errorMessage = 'Sold Out. All NFTs have been minted.';
			header = 'Sold Out';
		}
		setMintErrorMessage(errorMessage, header);

		if(errorMessage.indexOf('not have enough') > 0) {
			setShowBuyCrypto(true);
		}

		return false;
	});;

	if(resp) {
		await saveMintedGacha(params.candyMachineAddress, resp);
	}
	return resp;
}

/**
 * 
 * @param params This will insert the items in gacha
 * @param candyMachineData 
 * @param uploadedNfts 
 * @returns 
 */
export const insertItemsToGacha = async(params: any, candyMachineData : any, uploadedNfts : any, setUploadedItems: any, setStepName: any) => {
	const allItems = [];

	for(var i in uploadedNfts) {
		allItems.push({
			"name": uploadedNfts[i].name,
            "uri": uploadedNfts[i].metadata_uri
		});
	}
	await insertItemsToGachaProcess(params, candyMachineData, allItems);

	await pollForBulkUploadItemProgress(candyMachineData, setUploadedItems);
}

export const getGachaInfo = async(candyMachineData, setUploadedItems: any) : Promise<any> =>{
	const candyMachineAddress = candyMachineData.candyMachineAddr;

	let loadedItems = 0;
	let fetchGacha = await axios.get(GACHA_API + "gacha/"+candyMachineAddress+"?t="+new Date()).then((response) => {
			
		const dataResp = response.data;
		if(dataResp.status === 'COMPLETED')
			return dataResp.data;
	}).catch((error) => {
		console.log(error);
		return 0;
	});
	loadedItems = fetchGacha.itemsLoaded;

	//console.log("Loaded Items: " + loadedItems);
	setUploadedItems(loadedItems);
	return loadedItems;
}

export const getGachaMachineInfo = async(candyMachineAddress) : Promise<any> =>{
  
	let fetchGacha = await axios.get(GACHA_API + "gacha/"+candyMachineAddress+"?t="+new Date()).then((response) => {
			
		const dataResp = response.data;
		if(dataResp.status === 'COMPLETED')
			return dataResp.data;
	}).catch((error) => {
		console.log(error);
		return 0;
	}); 
	return fetchGacha;
}


export const pollForBulkUploadItemProgress = async(candyMachineData, setUploadedItems : any) : Promise<any> =>{
	const candyMachineAddress = candyMachineData.candyMachineAddr;

 
	let trial = 0;
	let fetchGacha = false;
	let doCount = true;
	let counterChecker = 0;
 	do{
		doCount = true;
		fetchGacha = await axios.get(GACHA_API + "gacha/"+candyMachineAddress+"/batchItems?t="+new Date()).then((response) => {
			
			const dataResp = response.data;
			if(dataResp.status === 'COMPLETED')
				return dataResp.txIds;
			else if(dataResp.status === 'PENDING') {
				doCount = false;
				if(counterChecker % 4 == 0) {
					getGachaInfo(candyMachineData, setUploadedItems);
				}
			}
			return false;
		}).catch((error) => {
			console.log(error);
			return false;
		});

		if(!fetchGacha) {
			if(doCount)
				trial++;
			await wait(5000);
		}  
		counterChecker++;
	}while(!fetchGacha  && trial < 2000);

	return fetchGacha;
}

export const insertItemsToGachaProcess = async(params: any, candyMachineData : any, allItems : any) => {

	const candyMachineAddress = candyMachineData.candyMachineAddr;

	const gachaData = {
		creator: params.creator,
		items: allItems 
	};

	let resp = false;
	resp = await axios.post(GACHA_API + "gacha/"+candyMachineAddress+"/batchItems", gachaData).then((response) => {
		const dataResp = response.data;
		return dataResp;
	}).catch((error) => { 
		toast.error(error.response.message);

		return false;
	});
	return resp;
	
}
 
export const uploadAssetToBackend = async(nftAssets: any, candyMachineData : any, candyMachineId: any) => {

	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		assets: nftAssets,
		candyMachineData : candyMachineData,
		candyMachineId : candyMachineId,

	}
 
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};
	fetch(backend_api_url + 'api/v1/machine/add-assets', requestOptions)
		.then(response => response.json())
		.then(data => {
			
		});

}

export const uploadAssetToBackendv2 = async(creator: any, description: any, symbol:any, nftAssets: any, candyMachineData : any, candyMachineId: any, bulkIdArweave: any) => {

	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		assets: nftAssets,
		description: description,
		symbol: symbol,
		bulkIdArweave: bulkIdArweave,
		candyMachineData : candyMachineData,
		candyMachineId : candyMachineId,
		creator: creator
	}
 
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};
	fetch(backend_api_url + 'api/v1/machine/add-assets-v2', requestOptions)
		.then(response => response.json())
		.then(data => {
			
		});

}

export const uploadToArweaveMetadata = async(creator: any, data : any, allMetaFiles: any, image: string) : Promise<any> => {
	let jsonFile;
	for(var i in allMetaFiles) {
		var fileNameIndex = data.name.split(".")[0];
		if(fileNameIndex+'.json' === allMetaFiles[i].name) {
			jsonFile = allMetaFiles[i].file;
			break;
		}
	}

	
	let resp = false;
	if(jsonFile) {
		
		const metadata = {
			creator: creator,
			name: jsonFile.image,
			symbol: jsonFile.symbol,
			description: jsonFile.description,
			image: image
		};

		//console.log(gachaData);
		let trial = 0;
		do{
			resp = await axios.post(GACHA_API + "upload/metadata", metadata).then((response) => {
				let data = response.data.uri;
				//console.log(response.data.uri);
				return data;
			}).catch((error) => { 
				toast.error(error.response);
				return false;
			});
	

			if(!resp) {
				trial++;
				await wait(5000);
			}else{

			}

		}while(!resp  && trial < 10);

	}
	return resp;

}
export const uploadToArweave = async(creator: any, data : any) : Promise<any> => {
	  
	var formdata = new FormData();
 formdata.append("file", data.actualFile, data.name);
formdata.append("creator", creator);

var requestOptions = {
  method: 'POST',
  body: formdata,
};
 
let trial = 0;
let resp = false;
do{
	resp = await fetch(GACHA_API + 'upload/image', requestOptions)
	.then(response => response.json())
	.then(result => { 
		return result.image;
	})
	.catch((error) => {

		toast.error(error.response);

		return false;
	});;

	if(!resp) {
		trial++;
		await wait(5000);
	}else{

	}

}while(!resp  && trial < 10);
 
 
return resp;
 
}


export const createGachaCollection = async (gachaSymbol: string, params : any,params2: any, callback : any, hideProcessing : any, setUploadedItems: any, setStepName: any, setIsCreateSuccess: any, sendInitialSolToGachaWalletNew: any) => {
	//console.log(params);
 
	setUploadedItems(0);

	let candyMachineParameters = {};
 
	let gachaData = {};
	let totalAvailable = 0;
	if(params.edition === 'limited') {
		totalAvailable = params.numOfImages;
		if(params.numOfNft > 0) {
			
			totalAvailable = params.numOfNft;
			if(params.numOfNft < params.numOfImages) {
				totalAvailable = params.numOfImages;
			}
		} 
		candyMachineParameters = {
			stablecoin: params.tokenAccepted,
			edition: params.edition,
			maxEditionSupply: params.numOfNft,
			itemsAvailable: totalAvailable,
			sellerFeeBasisPoints: params.enforceRoyalties === 'Yes' ? params.royalties : 0,
			usdPerMint: params.price,
			symbol: gachaSymbol
		};
		
		

	}else if(params.edition === 'open') {
		totalAvailable = params.numOfImages;
		if(params.numOfNftOpen != '#') {
			totalAvailable = params.numOfNftOpen;

			if(params.numOfNftOpen < params.numOfImages) {
				totalAvailable = params.numOfImages;
			}
		}else{
			totalAvailable = 9999;

			if(9999 < params.numOfImages) {
				totalAvailable = params.numOfImages;
			}
		}
		candyMachineParameters = {
			stablecoin: params.tokenAccepted,
			edition: params.edition,
			itemsAvailable: totalAvailable,
			sellerFeeBasisPoints: params.enforceRoyalties === 'Yes' ? params.royalties : 0,
			usdPerMint: params.price,
			symbol: gachaSymbol
		};

	}

	let royalties = [
		{
			creator: params.user_wallet,
			share: 90
		}
	];

	if(params.user_wallet !== params.logged_in_wallet) {
		royalties = [
			{
				creator: params.user_wallet,
				share: 90
			}
			// ,
			// {
			// 	creator: params.logged_in_wallet,
			// 	share: 0
			// }
		];
	}

	gachaData = {
		creator: params.creator,
		collectionMint: {
			name: params.name,
			uri: backend_api_url + 'gacha-' +  params.unique_id
		},
		editionType : params.edition,
		itemsAvailable: totalAvailable,
		sellerFeeBasisPoints: params.enforceRoyalties === 'Yes' ? params.royalties  : 0,
		royalties : royalties,
	};


	//console.log(gachaData); 
	//return;
	//need to transfer 2 SOL to gacha wallet

	setStepName('Creating Toy Machine');

	//let addressId = await saveGachaAddress(params);
 
	let data;
	axios.post(GACHA_API + "v2/gacha", gachaData).then((response) => {
		//console.log(response.status, response.data);
		data = response.data.data;
		if(data.gachaMachine) {
			

			saveGachaMachine(params,params2, data, callback, hideProcessing, setUploadedItems, setStepName, setIsCreateSuccess, sendInitialSolToGachaWalletNew);
			
		}
	});

}

export const saveGachaAddress = async (params: any) => {
	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		params: params
	}

	let addressId;

	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params1)
	};
	fetch(backend_api_url + 'api/v1/machine/add-address', requestOptions)
		.then(response => response.json())
		.then(data => {
			
			addressId = data.id;
		});
	return addressId;
}

const getCollectionMintAddress = (data : any) : string => {
	return data.data.collectionMintPubkey;
}
 
export const pollToyMachine = async (candyMachineAddress) => {
	let trial = 0;
	let fetchGacha = false;
	do{
		fetchGacha = await axios.get(GACHA_API + "v2/gacha/"+candyMachineAddress+"?t="+new Date()).then((response) => {
			//console.log(response.status, response.data);
			const dataResp = response.data;
			if(dataResp.status === 'COMPLETED')
				return dataResp;
			return false;
		}).catch((error) => {
			console.log(error);
			return false;
		});

		if(!fetchGacha) {
			trial++;
			await wait(5000);
		}
		
	}while(!fetchGacha  && trial < 2000);

	if(!fetchGacha) {
		toast.error("Failed to create toy machine, please try again");

	}
}

export const saveGachaMachine = async (params: any,params2: any, dataInfo: any, callback : any, hideProcessing : any, setUploadedItems : any, setStepName: any, setIsCreateSuccess: any, sendInitialSolToGachaWalletNew : any) => {
        



	// const candyMachineAddress = dataInfo.gachaMachine;

	// let trial = 0;
	// let fetchGacha = false;
	/*
	do{
		fetchGacha = await axios.get(GACHA_API + "v2/gacha/"+candyMachineAddress+"?t="+new Date()).then((response) => {
			//console.log(response.status, response.data);
			const dataResp = response.data;
			if(dataResp.status === 'COMPLETED')
				return dataResp;
			return false;
		}).catch((error) => {
			console.log(error);
			return false;
		});

		if(!fetchGacha) {
			trial++;
			await wait(5000);
		}
		
	}while(!fetchGacha  && trial < 2000);
 
	if(!fetchGacha) {
		toast.error("Failed to create toy machine, please try again");

	}
	*/
	setStepName("Toy Machine Created");

	const userData = getUserSessionData();

	const params1 = {
		token: userData.token,
		secret: userData.secret,
		params: params,
		candyMachineData : dataInfo,
		collectionMintPubkey: ''
		// addressId: addressId
	}

	//if(true) {
		//await sendInitialSolToGachaWalletNew(params);

		let candyMachineId;
 
		params1.collectionMintPubkey = 'test'; // getCollectionMintAddress(fetchGacha);

		const requestOptions = {
			method: 'POST',
			body: JSON.stringify(params1)
		};
		fetch(backend_api_url + 'api/v1/machine/add', requestOptions)
			.then(response => response.json())
			.then(data => {
				
				candyMachineId = data.id;
				const candyMachineData = dataInfo;
				callback(params, params2, candyMachineData, data.id, hideProcessing, setUploadedItems, setStepName, setIsCreateSuccess);
			});
		return candyMachineId;
	//} 
	/*
	else {
		let candyMachineId;

		const requestOptions = {
			method: 'POST',
			body: JSON.stringify(params1)
		};
		fetch(backend_api_url + 'api/v1/machine/add', requestOptions)
			.then(response => response.json())
			.then(data => {
				
				candyMachineId = data.id;
				//const candyMachineData = dataInfo;
				//callback(params, candyMachineData, data.id, hideProcessing, setUploadedItems, setStepName, setIsCreateSuccess);
			});
		return candyMachineId;
	}
	*/
	return false;
}

const generateRandomString = (length) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
	  const randomIndex = Math.floor(Math.random() * charactersLength);
	  result += characters.charAt(randomIndex);
	}
	return result;
  }

export const saveDraftImages = async (id, params2, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError) => {

	const userData = getUserSessionData();
  
	const formData = new FormData();
 
	formData.append('fileLength', '0');

	var files = [];
	var uuid = generateRandomString(5);
	var file = null;

	if(hasImageChanges) {
		//files = await generateChunkZipFile(params2, true, uuid);
		file = await generateZipFile(params2, true);

	}

	
	const CHUNK_SIZE = 8000000;//10000000; // 10MB
	let start = 0;
	let end = CHUNK_SIZE;
	let chunks = [];
	
	if(file && (params2.allFiles.length > 0 || params2.metaFiles.length > 0)) {
		while (  start < file.size) {
			let chunk = file.slice(start, end);
			chunks.push(chunk);
			start = end;
			end = start + CHUNK_SIZE;
		}
	}
	//console.log(chunks);
	//saveDraftImagesNow(id, files, uuid, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError, 0)
	await saveDraftImagesNow2(id, chunks, uuid, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError, 0, 0, 10);
}


export const saveDraftImagesNow2 = async (id, chunks, uuid, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError, index, retries, maxRetries) => {

	const userData = getUserSessionData();
  
	const formData = new FormData();
 
	let fLength = '0';
	let isMerge = '0';

	
	let indexLength = "";
	if(hasImageChanges ) {
		if(chunks.length > 0) {
			isMerge = (index == chunks.length - 1 ? '1' : '0');
			fLength = ''+chunks.length;

			formData.append('zipFile', chunks[index]);
			formData.append('name', "part."+index);

			formData.append('index', index);
			formData.append('fullSize', chunks.length);
			formData.append('uuid', uuid);
			indexLength = index + "_" + chunks.length +"_"+isMerge;
		} else {
			formData.append('isClear', "1");

		}
	}

	formData.append('fileLength', fLength);
	formData.append('isMerge', isMerge);


	formData.append('token', userData.token);
	formData.append('secret', userData.secret);
	formData.append('candyMachineId', id);
	formData.append('uuid', uuid); 

	axios.post(backend_api_url + 'api/v1/machine/save-chunks-new?'+indexLength+"&t="+new Date() , formData)
	.then((response) => {
		let data = response.data; 
 
		if(chunks.length === 0 || index === chunks.length - 1) {

			setHasImageChanges(false);
			handleCloseSaving();
			if(params.isReadOnly) 
				showToastError("Candy Machine Saved Successfully");
			else
				showToastError("Candy Machine Draft Saved Successfully");

			if(data.status > 0) {
				
			} else if(window.location.href.indexOf("edit-collection") == -1){
				window.location.href = '/edit-collection/'+data.id+'/'+data.user_id;

			}
		} else {
			//upload next
			saveDraftImagesNow2(id, chunks, uuid, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError, index+1, 0, maxRetries);

		}
	}).catch(error => {
		retries++

		if(retries >= maxRetries) 
			console.log(`Too many  request retries.`);
		else {
			wait(1000);
			console.log("Retry number: " + retries)
			saveDraftImagesNow2(id, chunks, uuid, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError, index, retries, maxRetries);
		}
	});
}


export const saveDraftImagesNow = async (id, files, uuid, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError, index) => {

	const userData = getUserSessionData();
  
	const formData = new FormData();
 
	formData.append('fileLength', '0');
	formData.append('isMerge', '0');

	let indexLength = "";
	if(hasImageChanges ) {
		if(files.length > 0) {
			let isMerge = (index == files.length - 1 ? '1' : '0');
			formData.append('zipFile', files[index].zip);
			formData.append('fileLength', files[index].length);

			formData.append('index', files[index].index);
			formData.append('fullSize', files.length);
			formData.append('uuid', uuid);
			formData.append('isMerge', isMerge);
			indexLength = files[index].index + "_" + files.length +"_"+isMerge;
		} else {
			formData.append('isClear', "1");

		}
	}

	formData.append('token', userData.token);
	formData.append('secret', userData.secret);
	formData.append('candyMachineId', id);
	formData.append('uuid', uuid);

	axios.post(backend_api_url + 'api/v1/machine/save-chunks?'+indexLength+"&t="+new Date() , formData)
	.then((response) => {
		let data = response.data; 
 
		if(files.length === 0 || index === files.length - 1) {

			setHasImageChanges(false);
			handleCloseSaving();
			if(params.isReadOnly) 
				showToastError("Candy Machine Saved Successfully");
			else
				showToastError("Candy Machine Draft Saved Successfully");

			if(data.status > 0) {
				
			} else if(window.location.href.indexOf("edit-collection") == -1){
				window.location.href = '/edit-collection/'+data.id+'/'+data.user_id;

			}
		} else {
			//upload next
			saveDraftImagesNow(id, files, uuid, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError, index+1);

		}
	});
}

export const saveDraftGacha = async (params, params2, showToastError, handleCloseSaving, hasImageChanges, setHasImageChanges) => {
	//console.log("generating zip");


// console.log("generated zip");
// console.log(file);
 
		const userData = getUserSessionData();

		// const params1 = {
		// 	token: userData.token,
		// 	secret: userData.secret,
		// 	params: params,
		// 	//candyMachineData : dataInfo,
		// }

		let candyMachineId;

		const formData = new FormData();

		/*
		formData.append('fileLength', '0');

		if(hasImageChanges) {
			var file = await generateZipFile(params2, true);
			formData.append('zipFile', file);
			formData.append('fileLength', params2.allFiles.length);
		}
		*/
		formData.append('token', userData.token);
		formData.append('secret', userData.secret);
		formData.append('data', JSON.stringify(params));
		
		
		axios.post(backend_api_url + 'api/v1/machine/save-draft', formData)
		.then((response) => {
			let data = response.data;
			//console.log(response.data);

			saveDraftImages(data.id, params2, hasImageChanges, setHasImageChanges, handleCloseSaving, params, showToastError);

			/*
			setHasImageChanges(false);
			candyMachineId = data.id;
			handleCloseSaving();
			if(params.isReadOnly) 
				showToastError("Candy Machine Saved Successfully");
			else
				showToastError("Candy Machine Draft Saved Successfully");

			if(data.status > 0) {
				
			} else {
				window.location.href = '/edit-collection/'+data.id+'/'+data.user_id;

			}
			*/
		});
		
		return candyMachineId;
	
}
 
export const getBalance = async (publicKey: PublicKey, connection: Connection) => {

	const lamports = await connection.getBalance(publicKey);
	return lamports / LAMPORTS_PER_SOL;
};


export const getUserBalance = async (setBalance: any) =>   {
	//setUsdcToken(0); 

	let amount = 0;
	const userData = getUserSessionData();
	if(userData) {
		
		if(userData.wallet) {
			
			const params = {
				token: userData.token,
				secret: userData.secret,
			}

			const requestOptions = {
				method: 'POST',
				body: JSON.stringify(params)
			};

			const response = await fetch(backend_api_url + 'api/v1/users/balance', requestOptions);
			const json = await response.json();

			setBalance(json.balance);
		}
	}
	return amount;
}

export const saveUserReload = async (amount, txnId, type) =>   {
	//setUsdcToken(0); 

	const userData = getUserSessionData();
	if(userData) {
		
		if(userData.wallet) {
			
			const params = {
				token: userData.token,
				secret: userData.secret,
				amount: amount,
				txnId: txnId,
				type : type
			}

			const requestOptions = {
				method: 'POST',
				body: JSON.stringify(params)
			};

			const response = await fetch(backend_api_url + 'api/v1/users/add-balance', requestOptions);
			const json = await response.json();
 
		}
	}
	return amount;
}


export const getUsdcTokenBalance = async (connection) =>   {
	//setUsdcToken(0); 

	let amount = 0;
	const userData = getUserSessionData();
	if(userData) {
		
		if(userData.wallet) {
			const wallet = userData.wallet;
			const filters:GetProgramAccountsFilter[] = [
				{
				dataSize: 165,    //size of account (bytes)
				},
				{
				memcmp: {
					offset: 32,     //location of our query in the account (bytes)
					bytes: wallet,  //our search criteria, a base58 encoded string
				}  
				},
				{
					memcmp: {
					offset: 0, //number of bytes
					bytes: getUsdcMintAddress(), //base58 encoded string
					},
				}
			];
			const accounts = await connection.getParsedProgramAccounts(
				TOKEN_PROGRAM_ID,
				{filters: filters}
			);

			// console.log(accounts);

			// console.log(`Found ${accounts.length} token account(s) for wallet ${wallet}.`);
			accounts.forEach((account, i) => {
				//Parse the account data
				const parsedAccountInfo:any = account.account.data;
				const mintAddress:string = parsedAccountInfo["parsed"]["info"]["mint"];
				const tokenBalance: number = parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
				//Log results
				if(mintAddress === getUsdcMintAddress()) {
				//   console.log(parsedAccountInfo);
				// console.log(`Token Account No. ${i + 1}: ${account.pubkey.toString()}`);
				// console.log(`--Token Mint: ${mintAddress}`);
				// console.log(`--Token Balance: ${tokenBalance}`);
					//setUsdcToken(tokenBalance  );
					 
					amount = tokenBalance;
				}
			});
		}
	}
	return amount;
}

 
export const getPublisherNew = async (provider) => {
          
	const rpc = new RPC(provider);
	const privateKey = await rpc.getPrivateKey();

	let secretKeyUnit8Format = new Uint8Array(Buffer.from(privateKey, 'hex'));
	const publisher = Keypair.fromSecretKey(
		secretKeyUnit8Format
	  );
	
	return publisher;      
} 

export const getPrivateKeyBase58 = async (provider) => {
          
	const privateKey = await provider.request({
		method: "solanaPrivateKey",
	  });
  
	  let secretKeyUnit8Format = new Uint8Array(Buffer.from(privateKey, 'hex'));
	  const publisher = Keypair.fromSecretKey(
		secretKeyUnit8Format
		);
		
	  
	  const privkey = new Uint8Array(publisher.secretKey); // content of id.json here

	  return (base58.encode(privkey));

		/*
	const rpc = new RPC(provider);
	const privateKey = await rpc.getPrivateKey();
	
	let secretKeyUnit8Format = new Uint8Array(Buffer.from(privateKey, 'hex'));
	const publisher = Keypair.fromSecretKey(
		secretKeyUnit8Format
	  );
	  
	
	const privkey = new Uint8Array(publisher.secretKey); // content of id.json here
	return (base58.encode(privkey));
		*/
} 


export const getPublicWallet = async (provider) => {
          
	const rpc = new RPC(provider);
	const privateKey = await rpc.getPrivateKey();
	
	let secretKeyUnit8Format = new Uint8Array(Buffer.from(privateKey, 'hex'));
	const publisher = Keypair.fromSecretKey(
		secretKeyUnit8Format
	  );
	let pubKey = new PublicKey(publisher.publicKey);
 
	
	return (pubKey.toString());
  
  
} 

export const getPrivateKey = async (provider) => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const privateKey = await rpc.getPrivateKey();
    return (privateKey);
  };

export const getPublisher = async () => {
	const walletSecret = await getUserWalletSigner();
	 
	const publisher = Keypair.fromSecretKey(
		bs58.decode(walletSecret)
	  );

	return publisher;      
}

export const getPaypalClientId = () => {
	return process.env.REACT_APP_PAYPAL_CLIENT_ID;
}

export const getUserWalletSigner = async () => {
	const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)  as string);

	const params = {
		token: userData.token,
		secret: userData.secret,
	}

	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params)
	};

	const response = await fetch(backend_api_url + 'api/v1/users/wallet-secret', requestOptions);
	const json = await response.json();

	return json.primary_wallet_secret;
   
}

export const getUserSessionData = () => {
	return JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)  as string);

}

export const getCurrentWalletPublicKey = ()  => {

	const userData = getUserSessionData();
	if(userData) {
		
		if(userData.wallet) {
			const pubWal = new PublicKey(userData.wallet);
			return pubWal;
		}
	}
	return null;
}

const selectClusterFromChainId = (chainId: string): Cluster => {
	switch (chainId) {
	  case "0x1": {
		return "mainnet-beta";
	  }
	  case "0x2": {
		return "testnet";
	  }
	  default: {
		return "devnet";
	  }
	}
  };

  export interface ConnectionConfig {
	clientId: string;
	chainId: string;
	rpcTarget: string;
	cluster: Cluster;
  }



  export const getUsdcMintAddress = () => {
	

	const chainId = process.env.REACT_APP_WEB_AUTH_CHAIN_NET || "";
	const cluster = selectClusterFromChainId(chainId);
	
	if(cluster === 'mainnet-beta') {
		return USDC_MAINNET_ACCOUNT;
	}
	return USDC_DEVNET_ACCOUNT;
  }

export const getConnectionConfig = (): ConnectionConfig => {
	const chainId = process.env.REACT_APP_WEB_AUTH_CHAIN_NET || "";
	const cluster = selectClusterFromChainId(chainId);
	return {
	  clientId: process.env.REACT_APP_WEB_AUTH_CLIENT_ID || "",
	  chainId: process.env.REACT_APP_WEB_AUTH_CHAIN_NET || "",
	  rpcTarget: process.env.REACT_APP_WEB_AUTH_RPC_TARGET || "",
	  cluster,
	};
  };
  
  export const signMsgCurriedFn = async (provider): Promise<
  (message: Uint8Array) => Promise<Uint8Array>
> => {
  // Retrieve the PrivateKey first.
  if (!provider) {
	throw new Error("`web3auth` or `web3auth.provider` is not defined.");
  }
  const privateKeyStr = (await provider.request({
	method: "solanaPrivateKey",
  })) as string;
  const publisher = Keypair.fromSecretKey(Buffer.from(privateKeyStr, "hex"));

  // Return a closure with privateKey in scope
  return async (message: Uint8Array): Promise<Uint8Array> => {
	const signature = nacl.sign.detached(message, publisher.secretKey);
	return signature;
  };
};

 
export const settingsSlider = {
	dots: true,
	infinite: true,
	speed: 300,
	slidesToShow: 5,
	slidesToScroll: 1,
	autoplay: true,
	responsive: [
		{
		  breakpoint: 1140,
		  settings: {
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: true,
			dots: true
		  }
		},
		{
		  breakpoint: 1024,
		  settings: {
			slidesToShow: 3,
			slidesToScroll: 1
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
			slidesToShow: 3,
			slidesToScroll: 1
		  }
		},
		{
		  breakpoint: 520,
		  settings: {
			slidesToShow: 2,
			slidesToScroll: 1
		  }
		},
		{
		  breakpoint: 420,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1
		  }
		}
		
	  ]
  };

  export const settingsHomeBannerSlider ={
	dots: true,
	infinite: true,
	speed: 300,
	arrows: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	responsive: [
		{
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true
            }
          }
		
	  ]
  }

  export const chunkArray = (arr, chunkSize) => {
	const chunkedArray = [];
	
	for (let i = 0; i < arr.length; i += chunkSize) {
	  chunkedArray.push(arr.slice(i, i + chunkSize));
	}
  
	return chunkedArray;
  }

  export const getClientStripeSecretReload = async (amount) => {
	const userData = getUserSessionData();

	let data = JSON.stringify({
		  token: userData.token,
		  secret: userData.secret,
		  amount:  amount
	   });
 
	const response = await fetch(
		backend_api_url + 'api/v1/users/stripe-reload?d='+new Date(),
		{
		method: "POST",
		//headers: { "Content-Type": "application/json" },
		body: data
	  });
	  const resp = await response.json();
	  return resp; 
  }
  
  export const getPromoStatus = async (candyMachineAddress, promo) => {
	const userData = getUserSessionData();
	

	let data = JSON.stringify({
		  token: isUserLoggedIn ? userData.token : '',
		  secret: isUserLoggedIn ? userData.secret : '',
		  candyMachineAddress:  candyMachineAddress,
		  code : promo
	   });
 
	const response = await fetch(
		backend_api_url + 'api/v1/users/promo-status?d='+new Date(),
		{
		method: "POST",
		//headers: { "Content-Type": "application/json" },
		body: data
	  });
	  const resp = await response.json();
	  return resp; 
  }

  export const getClientStripeSecret = async (candyMachineAddress, amountReload, isUserLoggedIn, promo) => {
	const userData = getUserSessionData();
	

	let data = JSON.stringify({
		  token: isUserLoggedIn ? userData.token : '',
		  secret: isUserLoggedIn ? userData.secret : '',
		  candyMachineAddress:  candyMachineAddress,
		  amountReload: amountReload,
		  isUserLoggedIn : isUserLoggedIn ? 1 : 0,
		  code : promo
	   });
 
	const response = await fetch(
		backend_api_url + 'api/v1/users/stripe-payment?d='+new Date(),
		{
		method: "POST",
		//headers: { "Content-Type": "application/json" },
		body: data
	  });
	  const resp = await response.json();
	  return resp; 
  }

  export const isUserLoggedIn = () => {
		if(getUserSessionData() == null) {
			return false;
		}
		return true;
	}

  export const checkUserBalanceForMint = async (candyMachineAddress, promo) => {
	const userData = getUserSessionData();

	let data = JSON.stringify({
		  token: userData.token,
		  secret: userData.secret,
		  candyMachineAddress:  candyMachineAddress,
		  promoCode : promo
	});
 
	const response = await fetch(
		backend_api_url + 'api/v1/users/check-balance?d='+new Date(),
		{
		method: "POST",
		//headers: { "Content-Type": "application/json" },
		body: data
	  });
	  const resp = await response.json();
	  return resp; 
  }

  export const getClientOnRampSecret = async (type) => {
	const userData = getUserSessionData();

	let data = JSON.stringify({
		  token: userData.token,
		  secret: userData.secret,
		  destination_currency:  "sol",
		  destination_exchange_amount: "1",
		  destination_network: "solana",
	   });

	if(type === 'USDC') {
		data = JSON.stringify({
			token: userData.token,
			secret: userData.secret,
			destination_currency:  "usdc",
			destination_exchange_amount: "20",
			destination_network: "solana",
		 });
	}

	const response = await fetch(
		backend_api_url + 'api/v1/users/on-ramp?d='+new Date(),
		{
		method: "POST",
		//headers: { "Content-Type": "application/json" },
		body: data
	  });
	  const { clientSecret } = await response.json();
	  return clientSecret;
	  
  }

  export const getCurrentWallet = () => {
	//return "Amp8oNEtrGZSpAU9DYKFbYA8gT8KZKdmuM2FWRUHHZe7";
	//return 'FNwiu2wwAPP9CKmy58i84qXEmdakNkb3pxmyooQxUSCq'; //davids wallet
	//return 'ERVcrM5KtzBukB69n7PK18uifMDjKkLr9rogjiPiZdDp';
	
	//return 'HKXj8JdNF8z1SVMPZHPkRsZ33PdT9erVSaGY6grP3Pyo';

	const userData = getUserSessionData();
	if(userData) {
		return userData.wallet;
	}
	return '';
  }

  export const checkIfPublishTransaction = (loggedMessage) : boolean => {

	let toCheck = [];
	toCheck.push('Program log: IX: Create');
	toCheck.push('Program log: Instruction: InitializeMint2');
	toCheck.push('Program log: Instruction: MintTo');
	toCheck.push('Program log: Instruction: InitializeImmutableOwner');
	toCheck.push('Program log: Create');
	toCheck.push('Program log: IX: Mint');

	var isFound = 0;
	for(var k in toCheck) {
		for(var i in loggedMessage) {
			if(loggedMessage[i].indexOf(toCheck[k]) >= 0) {
				isFound++;
			}
		}
	}
	return isFound === 6 ? true : false;
  }

  export const isTransferNft = (loggedMessage) : boolean => {

	let toCheck = [];
	toCheck.push('Program log: Instruction: ReplaceLeaf');
	toCheck.push('Program log: Fast-forwarding proof'); 
	toCheck.push('Instruction: Transfer'); 

	

	var isFound = 0;
	for(var k in toCheck) {
		for(var i in loggedMessage) {
			if(loggedMessage[i].indexOf(toCheck[k]) >= 0) {
				isFound++;
			}
		}
	}
	return isFound === 3 ? true : false;
  }

  export const checkIfMintTransaction = (loggedMessage) : boolean => {

	let toCheck = [];
	toCheck.push('Program log: Instruction: Transfer');
	toCheck.push('Program log: Instruction: ReplaceLeaf');
	toCheck.push('Program log: Attempting to fill in proof');
	toCheck.push('Program log: Leaf Index');

	var isFound = 0;
	for(var k in toCheck) {
		for(var i in loggedMessage) {
			if(loggedMessage[i].indexOf(toCheck[k]) >= 0) {
				isFound++;
			}
		}
	}
	return isFound === 4 ? true : false;
  }
 
  export const isShowLedgerInfoNew = (transaction) => {
	let allowedType = [
        //'COMPRESSED_NFT_VERIFY_CREATOR',
        'COMPRESSED_NFT_MINT', 
        'COMPRESSED_NFT_TRANSFER', 
        
        'NFT_MINT', 
        'TRANSFER']

	for(var i in allowedType) {
		if(allowedType[i] === transaction.type) {
			
			if(transaction.type === 'COMPRESSED_NFT_MINT') {
				if(transaction.events.compressed[0].oldLeafOwner == null) {
					return false;
				} else {
					return true;
				}
			} else if(transaction.type === 'COMPRESSED_NFT_TRANSFER') {
				return true;
			} else if(transaction.type === 'NFT_MINT') {
			   
				return true;

			} else if(transaction.type === 'TRANSFER') {
				let description = transaction.description.split(' ');;

				if(description[3] == 'total') {
					return false;
				}
				return true;
			}
		}
	}
	return false;
  }
  export const isShowLedgerInfo = (transaction) => {
	const userData = getUserSessionData();
 
	if(userData) {
		const signature = transaction.signature;
		const nextTransaction = transaction.nextTransaction;
		const prevTransaction = transaction.prevTransaction;
 

		if(userData.wallet) {
 
			try{

			const transactionData = transaction.transaction;

			//console.log(transaction);

			const accountKeys = transactionData.transaction.message.staticAccountKeys;
			 
			let userWallet = getCurrentWallet();
			

			let isPublish = false;

			if(accountKeys[0].toBase58() === userWallet && accountKeys.length > 5) {
				for(var i in accountKeys) {
					if(accountKeys[i].toBase58() === CANDY_MACHINE_ADDRESS) {
						isPublish = true;
					}
				}
			}

			if(!isPublish) {
				//we check the messages
				if(checkIfPublishTransaction(transactionData.meta.logMessages)) {

					isPublish = true;
				}
			}

			if(signature === '2KRCRcHyBms8uBwDX5TTvi2UjmiqdQNvUTHyUjRUjwvGkY1Uj6GVZPEPn8cACF3bySpQwhczTt5HoMqFBf3C1wJn') {
				console.log('account keys');
				console.log('is publish' + isPublish);
				
				for(var i in accountKeys) {
					console.log(i+'. '+accountKeys[i].toBase58());
				}
			}


			let usdcMintAddress = getUsdcMintAddress() ;

			//console.log(signature + ' - ' + accountKeys[accountKeys.length-1].toBase58());
				
			if(accountKeys.length >=3 && accountKeys[0].toBase58() === userWallet && accountKeys[2].toBase58() == '11111111111111111111111111111111' ) {
				return true;
			}else if(isPublish) {
				return true;
			} else if (accountKeys.length > 5 && accountKeys[5].toBase58() === userWallet && checkIfMintTransaction(transactionData.meta.logMessages)) {
				return true;
			}
			else if(accountKeys[accountKeys.length-1].toBase58() === userWallet  ) {
				
				let postBalance;
				let preBalance;  

				if(nextTransaction) {
					for(var i in nextTransaction.transaction.meta.postTokenBalances) {
						if(nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress && 
						nextTransaction.transaction.meta.postTokenBalances[i].owner == userWallet 
						) {
							postBalance = nextTransaction.transaction.meta.postTokenBalances[i];
							break;
						}
					}
					for(var i in nextTransaction.transaction.meta.postTokenBalances) {
						if(nextTransaction.transaction.meta.preTokenBalances[i].mint == usdcMintAddress && 
						nextTransaction.transaction.meta.preTokenBalances[i].owner == userWallet 
						) {
							preBalance = nextTransaction.transaction.meta.preTokenBalances[i];
							break;
						}
					} 
				}
 

				if(postBalance && preBalance) {
					return true;
				}else{
					return false;

				}

			}
			else if(
				accountKeys[2].toBase58() === userWallet && 
				( (accountKeys.length > 13 && usdcMintAddress == accountKeys[13].toBase58())
				|| (accountKeys.length > 24 && accountKeys[24].toBase58() === '11111111111111111111111111111111')
				|| (accountKeys.length > 14 && accountKeys[14].toBase58() === '11111111111111111111111111111111')
				|| (accountKeys.length > 15 && accountKeys[15].toBase58() === '11111111111111111111111111111111' && usdcMintAddress == accountKeys[14].toBase58())
				))  {
				//const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;
 
					
				let postBalance;
				let preBalance; 

				let postBalanceRevShare;
				let preBalanceRevShare;

				if(nextTransaction) {
					for(var i in nextTransaction.transaction.meta.postTokenBalances) {
						if(nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress && 
						nextTransaction.transaction.meta.postTokenBalances[i].owner == userWallet 
						) {
							postBalance = nextTransaction.transaction.meta.postTokenBalances[i];
							break;
						}
					}
					for(var i in nextTransaction.transaction.meta.postTokenBalances) {
						if(nextTransaction.transaction.meta.preTokenBalances[i].mint == usdcMintAddress && 
						nextTransaction.transaction.meta.preTokenBalances[i].owner == userWallet 
						) {
							preBalance = nextTransaction.transaction.meta.preTokenBalances[i];
							break;
						}
					}

					for(var i in nextTransaction.transaction.meta.postTokenBalances) {
						if(nextTransaction.transaction.meta.postTokenBalances[i].mint == usdcMintAddress
						) {
							postBalanceRevShare = nextTransaction.transaction.meta.postTokenBalances[i];
						}
					}
					for(var i in nextTransaction.transaction.meta.preTokenBalances) {
						if(nextTransaction.transaction.meta.preTokenBalances[i].mint == usdcMintAddress
						) {
							preBalanceRevShare = nextTransaction.transaction.meta.preTokenBalances[i];
						}
					} 
				}

				if(postBalance && preBalance) {
					let revShareAmount = 0;
					let soldUsdcAmount = postBalance.uiTokenAmount.uiAmount - preBalance.uiTokenAmount.uiAmount;

					if(postBalanceRevShare && preBalanceRevShare) {
						revShareAmount = postBalanceRevShare.uiTokenAmount.uiAmount - preBalanceRevShare.uiTokenAmount.uiAmount;
					}
					soldUsdcAmount = soldUsdcAmount + revShareAmount;
						


					if(soldUsdcAmount == 0 && revShareAmount == 0) {
						return false;
					}
					return true;
				}else{
					return false;
				}
			}else if(accountKeys.length > 21 && accountKeys[21].toBase58() === userWallet
			&& (usdcMintAddress === accountKeys[14].toBase58())
			&& (accountKeys.length > 24 && accountKeys[24].toBase58() === '11111111111111111111111111111111')
				) {
				


				let nftAddress = '';
				for(var i in transactionData.meta.postTokenBalances) { 
					if(transactionData.meta.postTokenBalances[i].uiTokenAmount.uiAmount === 1 
						&& transactionData.meta.postTokenBalances[i].mint !== usdcMintAddress
					) {
						nftAddress = transactionData.meta.postTokenBalances[i].mint;
						break;
					}
				}
				

				let postBalance;
				let preBalance; 

				let postBalanceRevShare;
				let preBalanceRevShare;

				for(var i in transactionData.meta.postTokenBalances) {
					if(transactionData.meta.postTokenBalances[i].mint == usdcMintAddress && 
						transactionData.meta.postTokenBalances[i].owner == userWallet 
					) {
						postBalance = transactionData.meta.postTokenBalances[i];
						break;
					}
				}
				for(var i in transactionData.meta.postTokenBalances) {
					if(transactionData.meta.preTokenBalances[i].mint == usdcMintAddress && 
						transactionData.meta.preTokenBalances[i].owner == userWallet 
					) {
						preBalance = transactionData.meta.preTokenBalances[i];
						break;
					}
				}

				for(var i in transactionData.meta.postTokenBalances) {
					if(transactionData.meta.postTokenBalances[i].mint == usdcMintAddress
					) {
						postBalanceRevShare = transactionData.meta.postTokenBalances[i];
					}
				}
				for(var i in transactionData.meta.preTokenBalances) {
					if(transactionData.meta.preTokenBalances[i].mint == usdcMintAddress
					) {
						preBalanceRevShare = transactionData.meta.preTokenBalances[i];
					}
				} 


				if(postBalance && preBalance) {
					let revShareAmount = 0;
					let soldUsdcAmount = postBalance.uiTokenAmount.uiAmount - preBalance.uiTokenAmount.uiAmount;

					if(postBalanceRevShare && preBalanceRevShare) {
						revShareAmount = postBalanceRevShare.uiTokenAmount.uiAmount - preBalanceRevShare.uiTokenAmount.uiAmount;
					}
					soldUsdcAmount = soldUsdcAmount + revShareAmount;
						
					if(soldUsdcAmount > 0) {
							
						let toybotAppWallet = '';
						for(var i in transactionData.meta.postTokenBalances) {
							if(transactionData.meta.postTokenBalances[i].mint == usdcMintAddress
							) {
								toybotAppWallet = transactionData.meta.postTokenBalances[i].owner;
							}
						}
						
						if(toybotAppWallet == '') {
							return false;
						}else{
							return true;
						}
					}else{
						return false;
					}
					
					
				}else{
					return false;

				} 
			}else if(accountKeys[1].toBase58() === userWallet) {
				const amountOfSol =  (transactionData.meta.postBalances[1] - transactionData.meta.preBalances[1]  )/ LAMPORTS_PER_SOL;

				if(amountOfSol > 0)
					return true;
				else
					return false;
			}else if(accountKeys.length >=3 && accountKeys[0].toBase58() === userWallet 
			&& isTransferNft(transactionData.meta.logMessages)) {
				return true;
			}
			else if(accountKeys.length >=3 && accountKeys[0].toBase58() === userWallet 
			&& accountKeys[accountKeys.length-1].toBase58() === splToken.TOKEN_PROGRAM_ID.toBase58()) {
				return true;
			}else if( ((accountKeys.length >=4 && accountKeys[4].toBase58() === userWallet)
			|| (accountKeys.length >=5 && accountKeys[5].toBase58() === userWallet))
			&& accountKeys[accountKeys.length-1].toBase58() === splToken.TOKEN_PROGRAM_ID.toBase58()) {
				return true;
			}else{
				;
			}

			}catch(e) {
						
			}
		}
	}
	return false;
}

export const getCollectionNameByAssetId = async(assetId) => {

	const params = {
		assetId: assetId
	}

	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params)
	};
	
	const name = fetch(backend_api_url + 'api/v1/machine/get-info-by-asset-id', requestOptions)
		.then(response => response.json())
		.then(data => {
			if(data.status === 1) {
				return data.name
			} 
			return 'Collection';
		});
	return name;
  }

export const getSignaturesForAsset = async(assetId) => {
 
  const response = await fetch(process.env.REACT_APP_WEB_AUTH_RPC_TARGET, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      jsonrpc: '2.0',
	  id: 'id'+new Date().getTime(),
      method: 'getSignaturesForAsset',
      params: {
        id: assetId,
        page: 1, // Starts at 1
        limit: 1000, // Limit 1000 per request.
      },
    }),
  });
  const { result } = await response.json();
  //console.log("Signatures: ", result);
 
  return result;
// Signatures:  {
//  total: 2,
//  limit: 1000,
//  page: 1,
//  items: [
//    [
//      '5nLi8m72bU6PBcz4Xrk23P6KTGy9ufF92kZiQXjTv9ELgkUxrNaiCGhMF4vh6RAcisw9DEQWJt9ogM3G2uCuwwV7',
//      'MintToCollectionV1'
//    ]
//    [
//      '323Ag4J69gagBt3neUvajNauMydiXZTmXYSfdK5swWcK1iwCUypcXv45UFcy5PTt136G9gtQ45oyPJRs1f2zFZ3v',
//      'Transfer'
//    ],
//  ]

}

export const getAssetProof = async (assetId) => {
	const response = await fetch(process.env.REACT_APP_WEB_AUTH_RPC_TARGET, {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json',
	  },
	  body: JSON.stringify({
		jsonrpc: '2.0',
		id: 'id'+new Date().getTime(),
		method: 'getAssetProof',
		params: {
		  id: assetId
		},
	  }),
	});
	const result = await response.json();
	//console.log("Assets Proof: ", result);
	return result.result;
};

export const isUserAdmin = () => {
	const userData = getUserSessionData();
	if(userData) {
		if(userData.isam === 1) {
			return true;
		}
	}
	return false;
}

export const getAsset = async (assetId) => {
	const response = await fetch(process.env.REACT_APP_WEB_AUTH_RPC_TARGET, {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json',
	  },
	  body: JSON.stringify({
		jsonrpc: '2.0',
		id: 'id'+new Date().getTime(),
		method: 'getAsset',
		params: {
		  id: assetId
		},
	  }),
	});
	const result = await response.json();
	//console.log("Assets: ", result);
	return result.result;
};

export const getPromoCode = () => {
	return localStorage.getItem("promo_"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET) ? localStorage.getItem("promo_"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET) : '';

}

export const clearPromoCode = () => {
 		localStorage.removeItem("promo_"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET);

}


export const checkPendingData = (claimNow: any) => {
	if(localStorage.getItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET)) {
		//try to reddem
		if(claimNow) {

			const userData = getUserSessionData();
            const pendingInfo = JSON.parse(localStorage.getItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET)  as string);

			claimNow( userData, pendingInfo);
		}

		// window.location.href = "/collection/"+pendingInfo.gacha+"/claim";
	}
}

export const getAssetsByOwner = async (address) => {
	// Code goes here
	
	let url = process.env.REACT_APP_WEB_AUTH_RPC_TARGET;
	url = process.env.REACT_APP_HELIUS_RPC + '?api-key='+process.env.REACT_APP_HELIUS_API_KEY;

	const response = await fetch(url, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/json',
		},
		body: JSON.stringify({
		  jsonrpc: '2.0',
		  id: 'id'+new Date().getTime(),
		  method: 'getAssetsByOwner',
		  params: {
						// Example wallet
			ownerAddress: address,
			page: 1,
			limit: 1000
		  },
		}),
	  });

	const { result } = await response.json();
	const groupedResults = {};

		//console.log(result);
 
	const allAssets = [];
	for (let i = 0; i < result.items.length; i++) {
		const ownerAddress = result.items[i].ownership.owner;

		let collectionAddress = '';
		if(result.items[i].grouping.length) {
			for(var k in result.items[i].grouping ) {
				if(result.items[i].grouping[k].group_key === 'collection') {
					collectionAddress = result.items[i].grouping[k].group_value;
				}
			}
		}

		const asset = {
			id: result.items[i].id,
			name: result.items[i].content.metadata.name,
			uri: result.items[i].content.json_uri,
			image: result.items[i].content.links.image,
			type: result.items[i].compression.compressed ? 'cnft' : 'nft',
			collectionAddress: collectionAddress,
			mintAddress: result.items[i].compression.compressed ? '' : result.items[i].id,
			assetId: result.items[i].compression.compressed ? result.items[i].id : '',
			creators: result.items[i].creators
		};

		if (groupedResults.hasOwnProperty(ownerAddress)) {
			// Add the asset to the existing group
			groupedResults[ownerAddress].assets.push(asset);
		} else {
			// Create a new group for the owner
			groupedResults[ownerAddress] = {
				assets: [asset],
			};
		}
		if(asset.image) {
			allAssets.push(asset);
		}
	}
	return (allAssets);
};

export const getExplorerUrl = (type) => {
	
	const chainId = process.env.REACT_APP_WEB_AUTH_CHAIN_NET || "";
 	
	 

	switch (chainId) {
		case "0x1": {
			if(type == 'tx') {
				return "https://solscan.io/tx/";
			}
		    return "https://solscan.io/address/";
		} 
		default: {
			if(type == 'tx') {
				return "https://explorer.solana.com/tx/";
			}
		    return "https://explorer.solana.com/address/";
		}
	  }


}

export const saveSolPurchase = async (payload) => {
	
	const userData = getUserSessionData();
	if(userData) {
		
		if(userData.wallet) {
			
			
			let cryptoAmount=  Number.parseFloat(payload.session.quote.destination_crypto_amount).toFixed(4);

			const params = {
				token: userData.token,
				secret: userData.secret,
				amount: cryptoAmount,
				txnId: payload.session.quote.blockchain_tx_id
			}

			const requestOptions = {
				method: 'POST',
				body: JSON.stringify(params)
			};

			const response = await fetch(backend_api_url + 'api/v1/users/add-balance-sol', requestOptions);
			const json = await response.json();
 
		}
	}
	
}