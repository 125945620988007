import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { backend_api_url, cluster, getAsset, getAssetProof, getAssetsByOwner, getBalance, getClientOnRampSecret, getClientStripeSecret, getClientStripeSecretReload, getCollectionNameByAssetId, getConnectionConfig, getExplorerUrl, getPaypalClientId, getPrivateKeyBase58, getPublisherNew, getSignaturesForAsset, getUsdcMintAddress, getUsdcTokenBalance, getUserBalance, getUserSessionData, saveUserReload, settingsSlider, stripe_pub_key} from "./constants";
import { AccountMeta, PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl, GetProgramAccountsFilter, sendAndConfirmTransaction, ComputeBudgetProgram } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';

import { SolanaWallet } from "@web3auth/solana-provider";

     
import { getOrCreateAssociatedTokenAccount  } from "@solana/spl-token";

import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import RecentlyMinted from "./RecentlyMinted";
import Slider from "react-slick";
import * as splToken from "@solana/spl-token";
import OnRamp from "./OnRamp";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { PROGRAM_ID as MPL_BUBBLEGUM_PROGRAM_ID } from '@metaplex-foundation/mpl-bubblegum'
//import { publicKey } from '@metaplex-foundation/umi';
//import { mplBubblegum } from '@metaplex-foundation/mpl-bubblegum';
//import { publicKeyBytes } from '@metaplex-foundation/umi'

import { createTransferInstruction } from "@metaplex-foundation/mpl-bubblegum";
 
import {
  ConcurrentMerkleTreeAccount,
  SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
  SPL_NOOP_PROGRAM_ID,
} from "@solana/spl-account-compression";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import base58 from "bs58";
import CheckoutFormOld from "./CheckoutFormOld";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

//const { Token, TOKEN_PROGRAM_ID, Account, Transaction, SystemProgram } = require('@solana/spl-token');

const WalletInfo = (props : any) => {
 
    const amountInput = useRef<HTMLInputElement>(null);
    const addressInput = useRef<HTMLInputElement>(null);
 
    const [show, setShow] = useState(false);

    const [isShowAll, setIsShowAll] = useState(false);


    const [showNftSend, setShowNftSend] = useState(false);
    const handleShowNftSend = () => setShowNftSend(true);

    const handleShow = () => setShow(true);

    //const [solParams, setSolParams] = useState(null);

    const { connection } = useConnection();

 
    const [ledgerJsx, setLedgerJsx] = useState<JSX.Element[] | null>(null)

    const [allNfts, setAllNfts] = useState<any[]>(null)

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)
    const [listingJsxByCollection, setListingJsxByCollection] = useState<JSX.Element[] | null>(null)

    const [showBy, setShowBy] = useState("recent");

    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);


    const [showAllWarning, setShowAllWarning] = useState(false);
    const handleCloseAllWarning = () => setShowAllWarning(false);
    const handleShowAllWarning = () => setShowAllWarning(true);

    const [showPreviewSend, setShowPreviewSend] = useState(false);
    const handleClosePreviewSend = () => setShowPreviewSend(false);
    const handleShowPreviewSend = () => setShowPreviewSend(true);

    const [showNeedSol, setShowNeedSol] = useState(false);
    const handleCloseNeedSol = () => setShowNeedSol(false);
    const handleShowNeedSol = () => setShowNeedSol(true);

    const [reloadBalance, setReloadBalance] = useState("100");

    const [balance, setBalance] = useState<number>(0);

    
    const tokenTypeInput = useRef<any>(null);
    const solAmountInput = useRef<any>(null);
    const toAddressInput = useRef<any>(null);

    const toAddressNftInput = useRef<any>(null);

    const [username, setUsername] = useState("");
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);

    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [selectedNftAddress, setSelectedNftAddress] = useState("");
    const [selectedNftType, setSelectedNftType] = useState("");

    const [selectedNftMintAddress, setSelectedNftMintAddress] = useState("");


    const [num, setNum] = useState(0)
    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)

    const [sendTokenTitle, setSendTokenTitle] = useState("");
    const [selectedTokenType, setSelectedTokenType] = useState("");
    const [destinationWallet, setDestinationWallet] = useState("");
    const [numOfToken, setNumOfToken] = useState("");

    const [sendNftTitle, setSendNftTitle] = useState("");
    const [sendTokenErrorMessage, setSendTokenErrorMessage] = useState("");
    const [sendNftErrorMessage, setSendNftErrorMessage] = useState("");

    

    const [renderedElements, setRenderedElements] = useState([]);
    const [renderedElementsNew, setRenderedElementsNew] = useState([]);

    const [byRecentNft, setByRecentNft] = useState([]);
    const [byCollectionNft, setByCollectionNft] = useState([]);


    const [currentIndex, setCurrentIndex] = useState(0);


    const [secretReload, setSecretReload] = useState<string>('');

    const [secret, setSecret] = useState<string>('');
    const [showStripe, setShowStripe] = useState(false);
    const handleCloseStripe = () => setShowStripe(false);
    const handleShowStripe = () => setShowStripe(true);

    const [showStripeReload, setShowStripeReload] = useState(false);
    const handleCloseStripeReload = () => setShowStripeReload(false);
    const handleShowStripeReload = () => setShowStripeReload(true);

    const [showStripeReloadAmount, setShowStripeReloadAmount] = useState(false);
    const handleCloseStripeReloadAmount = () => setShowStripeReloadAmount(false);
    const handleShowStripeReloadAmount = () => setShowStripeReloadAmount(true);

    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = () => setShowSuccess(true);
    
    
    const [mintError, setMintError] = useState("");
    const [mintErrorSub, setMintErrorSub] = useState("");

    const [mintErrorHeader, setMintErrorHeader] = useState("");

    const [hideInitial, setHideInitial] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);

    const config = getConnectionConfig(); 

    const umi = createUmi(config.rpcTarget); 
    //umi.use(mplBubblegum());


    const [currentWallet, setCurrentWallet] = useState("");
    const [hiddenMachine, setHiddenMachine] = useState<any[]>(null)
    const [mainMachine, setMainMachine] = useState<any[]>(null)
    const [mainMachine2, setMainMachine2] = useState<any[]>(null)

    const [userAllNfts, setUserAllNfts] = useState<any[]>([])

    const stripePromise = loadStripe(stripe_pub_key);

    const [isPin, setIsPin] = useState(false);
    const [isMobile, setIsMobile] = useState(true);


    const options = {
        clientSecret: secretReload,
    };


    const openStripe = async () => {
        handleShowStripeReloadAmount();
    }

    const openStripeNow = async () => {
        setReloadBalance(amountInput.current!.value);

        const resp = await getClientStripeSecretReload(amountInput.current!.value);

        if(resp.clientSecret) {
            handleCloseStripeReloadAmount();
            setSecretReload(resp.clientSecret);
            // console.log(clientSecret);
            handleShowStripeReload();
        }
    }

    const openOnRamp = async () => {


        const clientSecret = await getClientOnRampSecret('SOL');
        setSecret(clientSecret);
        //console.log(clientSecret);
        handleShowStripe();

        //console.log(getUserSessionData());

    }

    const showSuccessPayment = () => {
        handleCloseStripe();
        setMintErrorHeader('Notification');
        setMintError('Purchase Successful');
        setMintErrorSub("");
        handleShowError();
        setTimeout(doLoadBalance, 2000);

        //


    }

    const showSuccessPaymentReload = () => {
        handleCloseStripeReload();
        setMintErrorHeader('Notification');
        setMintError('Purchase Successful');
        setMintErrorSub("You’ve paid $"+reloadBalance+" to add "+reloadBalance+" credits to your account.");
        handleShowError();
        setTimeout(reloadUserBalance, 500);

    }
    
     

    // const getUsdcToken = async () => {

    //         const usdcAmount = await getUserBalance();
    //         setUsdcToken(usdcAmount);
    // }
   
    const reloadUserBalanceSave = async(txnId, type) => {
        await saveUserReload(reloadBalance, txnId, type);
        await getUserBalance(setBalance);

    }
    const reloadUserBalance = async() => {
        await getUserBalance(setBalance);

    }

    const showAllNft = () => {
        
        handleShowAllWarning();
    }

    const showAllToybotNft = () => {
        setIsProcessing(true);
        setIsShowAll(false);
        handleCloseAllWarning();
        setRenderedElements([]);
        setRenderedElementsNew([]);
        setListingJsxByCollection([]);
        setHideInitial(false);
        loadAllToybot();
    }

    const showAllNftConfirm = () => {
        setIsProcessing(true);
        setIsShowAll(true);
        handleCloseAllWarning();
        //reloadAllNft(true);
        setRenderedElements([]);
        setRenderedElementsNew([]);
        setListingJsxByCollection([]);
        setHideInitial(false);
        setTimeout(loadAll, 2000);
    }

    const loadAllToybot = () => {
        loadAllNft(currentWallet, hiddenMachine, mainMachine, mainMachine2, false);

    }

    const loadAll = () => {
        loadAllNft(currentWallet, hiddenMachine, mainMachine, mainMachine2, true);

    }

    useEffect(() => { 
        const userData1 = getUserSessionData();
        if(userData1) {

            let isMobile = false;
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
                navigator.userAgent.substr(0, 4)
                )
            )
                isMobile =  true;
            else 
                isMobile = false;
            
            setIsMobile(isMobile);
            
            //console.log("props.loginWeb3Auth();");
            props.loginWeb3Auth();
            //loadAllNft(wallet, hiddenMachines);
            viewNftsOwned(userData1.username);
            reloadUserBalance();
            //getUsdcToken();  
            setUsername(userData1.username);
            setUsernameJsx(<a href={"/user/" + userData1.username}>{userData1.username}</a>);
            
        }else{
            window.location.href = "/";
        }

    }, []);

 
    const viewNftsOwned = async (username) => {
        const params = {
            username: username
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/user-wallet', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    localStorage.setItem("pinnedNfts", JSON.stringify ( data.pinnedNft) );

                    setHiddenMachine (data.xMachines);
                    setMainMachine (data.mMachines);
                    setMainMachine2 (data.gMachines);

                    setCurrentWallet (data.userInfo.primary_wallet) ;
                    loadAllNft(data.userInfo.primary_wallet, data.xMachines, data.mMachines, data.gMachines, isShowAll);
                }
            });
    }

    

    const getTransactionInfo = async(signature) => {
        //let info = null;
         
        const transactionData = await connection.getTransaction(signature, {
          maxSupportedTransactionVersion : 0
        });
    
        //info =  {'signature' : signature, 'transaction' : };
        if(!transactionData) {
            return [];
        }

        return [transactionData];
    }

    const loadAllNft = async (wallet, hiddenMachines, mMachines, gMachines, showALL) => {
        setIsProcessing(true);
        
      
        const allNFTs = userAllNfts.length > 0 ? userAllNfts : await getAssetsByOwner(wallet);
 
        if(!userAllNfts && allNFTs)
            setUserAllNfts(allNFTs);

        var data = Object.keys(allNFTs).map((key) => allNFTs[key]);                                                                    
        
        //console.log(allNFTs);

        const newArr = await Promise.all(
            data.map(async (asset: any) => {
                let resp = false;
                 
                if(selectedNftAddress !== '' && selectedNftAddress === asset.mintAddress) {
                    return resp;
                }
                if(asset.uri && asset.uri.indexOf('http') >= 0) {
                    let timestamp = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime();
                    let transactions = [];
    
                    //console.log(data[i]);
                    let collectionName = ''; 
    
                    if(asset.type === 'cnft') {
                        collectionName = 'temp-name'; // await getCollectionNameByAssetId(asset.assetId);
                    } 
                    if(true || hiddenMachines.length === 0 || !hiddenMachines.includes(asset.collectionAddress)) {
                        
                        let isFromApp = false;

                        if(mMachines.includes(asset.collectionAddress)) {
                            isFromApp = true;
                        }else if (asset.creators && asset.creators.length > 0){
                            for(var h in asset.creators) {
                                if(gMachines.includes(asset.creators[h].address)) {
                                    isFromApp = true;
                                    break;
                                }
                            }
                        }

                        return ({
                            uri : asset.uri,
                            address: asset.mintAddress,
                            type: asset.type,
                            extraInfo: asset,
                            isFromApp: isFromApp,
                            collectionAddress: asset.collectionAddress,
                            timestamp: timestamp,
                            date: new Date(timestamp),
                            hasTransaction: transactions,
                            collectionName : collectionName
                        });
                    }
                }

                return resp;
            })
        );
        ///console.log(newArr);
        const newArr1 = [];
        for(var i in newArr) {
            if(newArr[i]) {
                newArr1.push(newArr[i])
            }
        }
        //console.log(newArr1);

        // return arr;
        loadAllNftImages(newArr1, showALL);
    }

    const sendBalanceNow = async() => {
        //console.log('sendBalanceNow');

        
        props.loginWeb3Auth();

        if(getUserSessionData() == null || props.provider == null) {
            props.loginWeb3Auth();
            return;
        }
        
        const tokenType = (tokenTypeInput.current!.value);
        const amount = (solAmountInput.current!.value);
        const toWallet = (toAddressInput.current!.value);
        
        
        const params = {
            amount: amount,
            address: toWallet.trim()
        }
        //console.log(params);

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                const pubkey =  new PublicKey(userData.wallet);
                let balance = 0;

                if(tokenType === 'usdc') {
                    //balance = await getUsdcTokenBalance(connection);
                }else if(tokenType === 'sol') {
                    balance = await getBalance(pubkey, connection);
                }
                
                if(balance < parseFloat(params.amount)) {
                    setSendTokenErrorMessage("Amount is greater the wallet balance.");
                    return false;
                }

                //check if the address is correct
                try{


                    // code for error testing
                    const accountInfo = await connection.getAccountInfo(new PublicKey(params.address));
                    //console.log(accountInfo);

                    params['accountInfo'] = accountInfo;
                    params['fromWallet'] = new PublicKey(userData.wallet);
                    params['toWallet'] = new PublicKey(params.address);
                    params['type'] = tokenType;


                    //setSolParams(params);

                    await sendAmountNow(params);
                    //send money now here
                    //handleShow();

                }
                catch (e) {
                    handleCloseSuccess();
                // code for error handeling
                            console.log(e)
                    setSendTokenErrorMessage("Invalid Address.");

                    return false;
                }
               
            } 
        } 
        
    }
    const sendAmountNow = async (params) => {
        //console.log(params);
        

        if(params['type'] === 'sol') {
            const amount = params['amount'] * LAMPORTS_PER_SOL; // hardcoded to 1 SOL for now
        
            const solanaWallet = new SolanaWallet(props.provider);

            const pubWal = getCurrentWalletPublicKey();
            const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
            const TransactionInstruction = SystemProgram.transfer({
                fromPubkey: params['fromWallet'],
                toPubkey: params['toWallet'],
                lamports: amount,
            });
            const transaction = new Transaction({
            recentBlockhash: blockhash,
            feePayer: pubWal,
            }).add(TransactionInstruction);
            
            //const web3authKey = await getPublisherNew(props.provider); 

            //await sendAndConfirmTransaction(connection, transaction, [web3authKey]);

            const { signature } = await solanaWallet.signAndSendTransaction(transaction);
 
            handleShowSuccess();
            // console.log(signature);
            // toast.success("Transferred Successfully", {
            //     className : "success-toast"
            // });
            doLoadBalance();
            setTimeout(doLoadBalance, 2000);
        }
        // else if(params['type'] === 'usdc'){
        //     await sendUSDCTokens(params);
        //     //getUsdcToken();
        //     setTimeout(getUsdcToken, 2000);
        // }
        handleClose();

}

async function sendUSDCTokens(params) {
 
    // Generate a new keypair to represent the sender
    // const sender = await initializeKeypair(connection)
  
    // Generate a new keypair to represent the sender
    // const receiver = Keypair.generate()
  
    // The MINT address of token to transfer
    const MINT = new PublicKey(getUsdcMintAddress());
  
    // Get the sender's associated token account address
    const senderTokenAccountAddress = await splToken.getAssociatedTokenAddress(
      MINT,
      params['fromWallet']
    )
  
    // Get the receiver's associated token account address
    const receiverTokenAccountAddress = await splToken.getAssociatedTokenAddress(
      MINT,
      params['toWallet']
    )
  
    // Create a new transaction
    const pubWal = getCurrentWalletPublicKey();
    const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
    const transaction = new Transaction({
        recentBlockhash: blockhash,
        feePayer: pubWal,
    })
  
    // Create an instruction to create the receiver's token account if it does not exist
    const createAccountInstruction = splToken.createAssociatedTokenAccountInstruction(
      params['fromWallet'],
      receiverTokenAccountAddress,
      params['toWallet'],
      MINT,
      splToken.TOKEN_PROGRAM_ID,
      splToken.ASSOCIATED_TOKEN_PROGRAM_ID
    )
  
    // Check if the receiver's token account exists
    let receiverTokenAccount: splToken.Account;
    try {
      receiverTokenAccount = await splToken.getAccount(
        connection,
        receiverTokenAccountAddress,
        "confirmed",
        splToken.TOKEN_PROGRAM_ID
      )
    } catch (e) {
      // If the account does not exist, add the create account instruction to the transaction
      transaction.add(createAccountInstruction)
    }
    const amount = params['amount'] * 1000000;  //conversion

    // Create an instruction to transfer amount from the sender's token account to the receiver's token account
    const transferInstruction = await splToken.createTransferInstruction(
      senderTokenAccountAddress,
      receiverTokenAccountAddress,
      params['fromWallet'],
      amount
    )

    
    // Add the transfer instruction to the transaction
    transaction.add(transferInstruction)
  
    // Send the transaction signed by the sender
    // const transactionSignature = await connection.sendTransaction(transaction, [
    //     params['fromWallet'],
    // ])

    //const web3authKey = await getPublisherNew(props.provider); 
    //await sendAndConfirmTransaction(connection, transaction, [web3authKey]);
     
    const solanaWallet = new SolanaWallet(props.provider);
    const { signature } = await solanaWallet.signAndSendTransaction(transaction);
    //console.log(signature);

    handleShowSuccess();
  }
  
const doLoadBalance = () => {
    props.loadSolBalance();
}
    const showSendNftConfirm = async () => {
         const userData = getUserSessionData();

         props.loginWeb3Auth();

        if(getUserSessionData() == null || props.provider == null) {
            handleClosePreview();
            props.loginWeb3Auth();
            return;
        }
        
        if(!props.provider){
            handleClosePreview();
            props.loginWeb3Auth();
        }


        if(userData) {
            
            if(userData.wallet) {
                     
                

                    try{
                        const toWallet = (toAddressNftInput.current!.value);

                        await connection.getAccountInfo(new PublicKey(toWallet));
                        // handleShowNftSend();
                        setDestinationWallet(toWallet);
                        setSendNftTitle("CONFIRM SEND");

    
                    }
                    catch (e) {
                    // code for error handeling
                                console.log(e)
                        setSendNftErrorMessage("Invalid Address1.");
                        return false;
                    }
                

            }
        }
    }
    const showSendConfirm = async () => {
        const tokenType = (tokenTypeInput.current!.value);
        let balance = 0;
        const userData = getUserSessionData();

        props.loginWeb3Auth();

        if(getUserSessionData() == null || props.provider == null) {
            handleClosePreview();
            props.loginWeb3Auth();
            return;
        }
        
        if(userData) {
            
            if(userData.wallet) {

                const pubkey =  new PublicKey(userData.wallet);
                if(tokenType === 'usdc') {
                    balance = await getUsdcTokenBalance(connection);
                }else if(tokenType === 'sol') {
                    balance = await getBalance(pubkey, connection);
                }
                const amount = (solAmountInput.current!.value);

                if(balance < parseFloat(amount)) {
                    setSendTokenErrorMessage("Amount is greater the wallet balance.");
                    return false;
                }else {

                    try{
                        const toWallet = (toAddressInput.current!.value);

                        await connection.getAccountInfo(new PublicKey(toWallet));
                        //handleClosePreviewSend();
                        //handleShow();                
                        setSendTokenErrorMessage("");

                        setSelectedTokenType(tokenType.toUpperCase());
                        setDestinationWallet(toWallet);
                        setNumOfToken(amount);
                        setSendTokenTitle('CONFIRM SEND');
                    }
                    catch (e) {
                    // code for error handeling
                                console.log(e)
                        setSendTokenErrorMessage("Invalid Address2.");
                        return false;
                    }
                }

            }
        }
    }

    const handleClose = () => {
       // console.log("handleClose");
        setShow(false);

        handleClosePreviewSend();

    };

    const handleCloseNftSend = () => {
        //console.log("handleCloseNftSend");
        setShowNftSend(false);

        handleClosePreview();

    };

    const backSendNft = () => {
        if(sendNftTitle === 'CONFIRM SEND') {
            setSendNftErrorMessage("");
            setSendNftTitle("SEND NFT");
        }else{
            handleClosePreview();
        }
    }

    const getAssetUriInfo = async(asset) => {
        let resp;

        if(!asset)
            return false;

         try {

            const instance = axios.create({
                maxRedirects: 0,
                timeout: 2000 // 2 seconds

            });
            resp = await instance.get(asset.uri).then((response) => {

                //console.log(asset.uri + " == " + response.request.res.responseUrl);

                let data = response.data;
                //console.log(data);
                data.address = asset.extraInfo.id;
                data.type = asset.extraInfo.type;

                let collectionName = (response.data.collection ? response.data.collection : 'Collection');
                if(!response.data.collection && asset.collectionName) {
                    collectionName = asset.collectionName;

                    //console.log(asset);
                }

                data.collection = collectionName;

                if(data.image) {
                    //data.address = asset.address;
                    data.collectionAddress = asset.collectionAddress;

                    data.fullName =  collectionName + " # " + response.data.name.split(".")[0];

                    data.timestamp = asset.timestamp;
                    data.date = asset.date;
                    data.hasTransaction = asset.hasTransaction;
                    
                    data.extraInfo = asset.extraInfo;
                    return data;
                }
                return false;
            }).catch((error) => { 
                //toast.error(error.response);
                return false;
            });

            
            if(resp && asset.type === 'cnft') {
                resp.collection =  await getCollectionNameByAssetId(asset.extraInfo.assetId);
            } 
            

        } catch (err: any) {
            return null;
        }
        
        return resp;
    }

    const isNftPin = (address) => {

        let pinnedNfts = JSON.parse(localStorage.getItem("pinnedNfts"));
        // console.log(pinnedNfts);
        // console.log(address);
        for(var i in pinnedNfts) {
            if(pinnedNfts[i] === address) {
                return true;
            }
        }

        return false;
    }

    const loadAllNftImages = async (uris, showALL) => {
        //console.log(uris);
        var filteredNft = [];

        setIsProcessing(false);
        let resp; 

        var uris = uris.sort((a, b) => b.timestamp - a.timestamp);

        
        let elem = 0;
        for(var index in uris) {
            
            if(uris[index]) {
                if(showALL || (!showALL && uris[index].isFromApp)) {
                        
                    resp = await getAssetUriInfo(uris[index]);
                    if(resp ) {


                        if(resp.name != null) {
    
                            filteredNft.push(resp);

                            localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));
    
                            
                            elem++;
                            setRenderedElements(existing => [...existing, <Fragment key={index + (new Date()).getTime()} >
                                
                                <a style={{"textAlign": 'center'}} data-date={resp.date} data-timestamp={new Date(resp.timestamp)} href={'#nftInfo-'+resp.address} onClick={() => showNftDataCheck()} data-address={resp.address} className={"imgSliderDetails-1 nftDetail1 " + (isNftPin(resp.address) ? 'pin-nft' : 'no-pin')}><img className="pin-icon" src="/images/pin.png"/><img alt="" src={resp.image} /> <br />{resp.name.split(".")[0]}</a>
                
                            </Fragment>]);


                        } 
                    }
                }
            }
        }
        
        setAllNfts(filteredNft);

        localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));

        var byRecent = filteredNft.sort((a, b) => b.timestamp - a.timestamp);
        setByRecentNft(byRecent);
        let byCollection = filteredNft.sort((a, b) => a.fullName.localeCompare(b.fullName));
        setByCollectionNft(byCollection);
        loadImageNfts(filteredNft, false, true, byRecent, byCollection);

    }

    const loadImageNfts = (filteredNft, isRefresh, isTimeout, byRecent, byCollection) => {
        setNum(filteredNft.length);



        // if(byRecent.length === byRecentNft.length) {

        //     byRecent = byRecentNft;

        // }


        //console.log(byRecent);

        setRenderedElements([]);

        setRenderedElementsNew(byRecent.map( (asset : any, index : number) => {
            return asset && <Fragment key={index + (new Date()).getTime()} >
            
                <a style={{"textAlign": 'center'}} data-date={asset.date} data-timestamp={new Date(asset.timestamp)} href="#NftDetail1" onClick={() => showNftData(asset.address)} data-address={asset.address} className={"imgSliderDetails-1 nftDetail1 " + (isNftPin(asset.address) ? 'pin-nft' : 'no-pin')}>  <img className="overlay pin-icon" src="/images/pin.png"/><img alt="" src={asset.image} /> <br />{asset.name.split(".")[0]}</a>

            </Fragment>;
        }));
        setHideInitial(true);

        //setListingJsx(newlistingjsx);


        // if(byCollection.length === byCollectionNft.length) {

        //     byCollection = byCollectionNft;

        // }

        setListingJsxByCollection(byCollection.map( (asset : any, index : number) => {
            return asset && <Fragment key={index + (new Date()).getTime()}>
            
                <a style={{"textAlign": 'center'}} data-date={asset.date} data-timestamp={new Date(asset.timestamp)} href="#NftDetail1" onClick={() => showNftData(asset.address)} data-address={asset.address} className={"imgSliderDetails-1 nftDetail1 " + (isNftPin(asset.address) ? 'pin-nft' : 'no-pin')}><img className="overlay pin-icon" src="/images/pin.png"/><img alt="" src={asset.image} /> <br />{asset.name.split(".")[0]}</a>

            </Fragment>;
        }));

        //if(isRefresh)
        
        //setRenderedElementsNew(newlistingjsx);

        setIsProcessing(false);

    }

    const showCollectionLink = async(collectionAddress, collectionName, address, nftName, type) => {

        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setPreviewCollectionName(<a href={"/collection/" + data.address}>{data.name}</a>);
                    if(type === 'nft')
                        setPreviewImageName(<a target="_blank" className="all-caps" rel="noreferrer" href={getExplorerUrl('address')+address+'?cluster='+ cluster}>{nftName.toUpperCase()}</a>);
                    else
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={getExplorerUrl('address')+address+'/?cluster='+cluster}>{nftName.toUpperCase()}</a>);

                }else{
                    //not in the system, so display address to external url 
                    setPreviewCollectionName(<Fragment>{collectionName}</Fragment>);

                }
            });
            return;
      }

      const showNftDataCheck = () => {

        setTimeout(showNftDataCheckNow, 100)
      }
      const showNftDataCheckNow = () => {
        let address = '';
        if(window.location.hash.indexOf("nftInfo-") === 1) {
            address = window.location.hash.replaceAll("#nftInfo-", "");
        }
        showNftData(address);
      }
    const showNftData = (address) => {
        // console.log(props.provider);
         //if(props.provider) {
            //console.log(allNfts);

            setSendNftTitle("SEND TOKENS");

            //console.log(address);

            let nfts = allNfts === null ? JSON.parse(localStorage.getItem('mintedNfts')) : allNfts;

            if(allNfts) {
                localStorage.removeItem('mintedNfts');
            }
            for(var i in nfts) {
                if(nfts[i].address === address) {
                    //console.log(nfts[i]);
                    setSelectedNftAddress(address);
                    setSelectedNftType(nfts[i].type);

                    //setPreviewCollectionName(nfts[i].collection ? nfts[i].collection : '');
                    let nftName = nfts[i].name.split(".")[0];

                    showCollectionLink(nfts[i].collectionAddress, (nfts[i].collection ? nfts[i].collection : ''), address, nftName, nfts[i].type);

                    setPreviewImageDesc(nfts[i].description);

                    if(nfts[i].type === 'nft') {
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={getExplorerUrl('address')+address+'?cluster='+ cluster}>{nftName}</a>);
                    } else {
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={getExplorerUrl('address')+address+'/?cluster='+cluster}>{nftName}</a>);
                    }

                    setPreviewImageUrl(nfts[i].image);

                    setPropertiesJsx ([]);
                    //console.log(nfts[i]);

                    if(nfts[i].attributes) {
                        //console.log('show att');
                        const attributes = Array.isArray(nfts[i].attributes) ? nfts[i].attributes : JSON.parse(nfts[i].attributes);  
                        //console.log(attributes);

                        // setPropertiesJsx(attributes.map( (asset : any, index : number) => {
                        //     return asset && <Fragment key={index + (new Date()).getTime()}>
                        //          <div className="custom-column">{asset.trait_type} : {asset.value}</div>
                        //     </Fragment>;
                        // }));

                        const chunkedJSX = [];
                        const properties = chunkArray(attributes, 2);

                        for (let i = 0; i < properties.length; i ++) {
                            let elem = properties[i];

                            if(elem.length === 2) {
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                        {elem[1] &&  <div className="custom-column">{elem[1].trait_type}: <span className="prop-value">{elem[1].value}</span></div>} 
                                    </div>
                                );
                            }else{
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                    </div>
                                );
                            }
                        }
                        setPropertiesJsx (chunkedJSX);
                    } 
                    // setPropertiesJsx()

                    setIsPin(false);
                    
                    if(isNftPin(address)) {
                        setIsPin(true);
                    }

                }
            }
            setSendNftErrorMessage("");
            handleShowPreview();
            //console.log("loginWeb3Auth");
            props.loginWeb3Auth();

        // }else{
        //      props.loginWeb3Auth();
        // }
    }

    const chunkArray = (arr, chunkSize) => {
        const chunkedArray = [];
        
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunkedArray.push(arr.slice(i, i + chunkSize));
        }
        
        return chunkedArray;
        }
      
    const sendNftNow = async () => {
        //console.log("sendNftNow "  + selectedNftAddress);

        if(!props.provider){
            //handleCloseNftSend();
             props.loginWeb3Auth();
        }

        let balance = 0;
        const userData = getUserSessionData();
  
        if(userData) {
            
            if(userData.wallet) {

                const pubkey =  new PublicKey(userData.wallet);
                balance = await getBalance(pubkey, connection);
                
            }
        }

         

        if(balance == 0) {
            handleClosePreview();
            handleShowNeedSol();
            return;
        }
 

            const ownerPublicKey = new PublicKey(userData.wallet);
            const destPublicKey = new PublicKey(toAddressNftInput.current!.value);
          
           // Generate a new keypair to represent the sender
            // const sender = await initializeKeypair(connection)
        
            // Generate a new keypair to represent the sender
            // const receiver = Keypair.generate()
        
            // The MINT address of token to transfer
            const MINT = new PublicKey(selectedNftAddress);

            
 
            if(selectedNftType === 'nft') {
            
                handleShow();
            handleClosePreview();
                // Get the sender's associated token account address
                const senderTokenAccountAddress = await splToken.getAssociatedTokenAddress(
                MINT,
                ownerPublicKey
                )
            
                // Get the receiver's associated token account address
                const receiverTokenAccountAddress = await splToken.getAssociatedTokenAddress(
                MINT,
                destPublicKey
                )
            
                // Create a new transaction
                const pubWal = getCurrentWalletPublicKey();
                const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
                const transaction = new Transaction({
                    recentBlockhash: blockhash,
                    feePayer: pubWal,
                })
            
                // Create an instruction to create the receiver's token account if it does not exist
                const createAccountInstruction = splToken.createAssociatedTokenAccountInstruction(
                ownerPublicKey,
                receiverTokenAccountAddress,
                destPublicKey,
                MINT,
                splToken.TOKEN_PROGRAM_ID,
                splToken.ASSOCIATED_TOKEN_PROGRAM_ID
                )
            
                // Check if the receiver's token account exists
                let receiverTokenAccount: splToken.Account;
                try {
                    receiverTokenAccount = await splToken.getAccount(
                        connection,
                        receiverTokenAccountAddress,
                        "confirmed",
                        splToken.TOKEN_PROGRAM_ID
                    )
                } catch (e) {
                // If the account does not exist, add the create account instruction to the transaction
                transaction.add(createAccountInstruction)
                }

                // Create an instruction to transfer amount from the sender's token account to the receiver's token account
                const transferInstruction = await splToken.createTransferInstruction(
                    senderTokenAccountAddress,
                    receiverTokenAccountAddress,
                    ownerPublicKey,
                    1
                )

                
                // Add the transfer instruction to the transaction
                transaction.add(transferInstruction)

                try {
                    const solanaWallet = new SolanaWallet(props.provider);

                    const { signature } = await solanaWallet.signAndSendTransaction(transaction);
                    afterTransferProcessing();
                }
                catch (e) {
                    handleCloseSuccess();
                // code for error handeling
                            console.log(e)
                    setSendNftErrorMessage("Invalid Address.");
                    return false;
                }
            } else {
            
                await transferCompressedNFT();

                
            }
    }
    
    const transferCompressedNFT = async () => {

        try{ 
            
            const asset = await getAsset(selectedNftAddress);
            const assetProof = await getAssetProof(selectedNftAddress);
 
            //Here, we’re looking for the data_hash, creator_hash, owner, delegate, and leaf_id:
            const dataHash = asset.compression.data_hash;
            const creatorHash = asset.compression.creator_hash;
            const leafId = asset.compression.leaf_id;
            const delegate = asset.ownership.delegate;

            //Once we have the necessary information, we need to use the getAssetProof method to retrieve the proof and tree_id (the tree’s address). Here’s an example call:
            const proof = assetProof.proof;
            const treeAddress = new PublicKey(assetProof.tree_id);
            const root = assetProof.root;

            const userData = getUserSessionData();

            const owner = (userData.wallet);
            const newLeafOwner = new PublicKey(toAddressNftInput.current!.value);


            const treeAccount = await ConcurrentMerkleTreeAccount.fromAccountAddress(connection, treeAddress);
        
            const treeAuthority = treeAccount.getAuthority();
            const canopyDepth = treeAccount.getCanopyDepth();
        

            handleShow();
            handleClosePreview();

            const proofPath: AccountMeta[] = proof
            .map((node: string) => ({
                pubkey: new PublicKey(node),
                isSigner: false,
                isWritable: false,
            }))
            .slice(0, proof.length - (!!canopyDepth ? canopyDepth : 0));
        
            const leafOwner = new PublicKey(owner);
            //const leafDelegate = new PublicKey(delegate);
            const leafDelegate = new PublicKey(owner);

            
            const transferInstruction = createTransferInstruction(
            {
                merkleTree: treeAddress,
                treeAuthority,
                leafOwner,
                leafDelegate,
                newLeafOwner,
                logWrapper: SPL_NOOP_PROGRAM_ID,
                compressionProgram: SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
                anchorRemainingAccounts: proofPath,
            },
            {
                root: [...new PublicKey(root.trim()).toBytes()],
                dataHash: [...new PublicKey(dataHash.trim()).toBytes()],
                creatorHash: [...new PublicKey(creatorHash.trim()).toBytes()],
                nonce: leafId,
                index: leafId,
            },
            MPL_BUBBLEGUM_PROGRAM_ID
            );
         

            
                
            const web3authKey = await getPublisherNew(props.provider); 

            const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;

            const txt = new Transaction().add(transferInstruction);
            txt.feePayer = leafOwner;
            txt.recentBlockhash = blockhash;
            txt.sign(web3authKey);

            let feeEstimate = { priorityFeeEstimate: 0 };
            let priorityLevel = 'High';
            if (priorityLevel !== "NONE") {
                feeEstimate = await getPriorityFeeEstimate(priorityLevel, txt);
                if(feeEstimate.priorityFeeEstimate > 0) {
                    const computePriceIx = ComputeBudgetProgram.setComputeUnitPrice({
                        microLamports: feeEstimate.priorityFeeEstimate,
                    });
                    txt.add(computePriceIx);
                }
            }

            const transactionSignature = await sendAndConfirmTransaction(connection, txt, [web3authKey], {
                commitment: "confirmed",
                skipPreflight: true,
            });
            
        
            //console.log(`Successfully transfered the cNFT with txt sig: ${transactionSignature}`);
             
            afterTransferProcessing();
                

        }
        catch (e) {
            handleCloseSuccess();
            console.log(e)
            setSendNftErrorMessage("Invalid Address.");
            return false;
        }
      };
      
      async function getPriorityFeeEstimate(priorityLevel, transaction) {
        const response = await fetch(process.env.REACT_APP_WEB_AUTH_RPC_TARGET, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: "1",
            method: "getPriorityFeeEstimate",
            params: [
              {
                transaction: base58.encode(transaction.serialize()), // Pass the serialized transaction in Base58
                options: { priorityLevel: priorityLevel },
              },
            ],
          }),
        });
        const data = await response.json();

        //console.log(data);

        if(data.result) {
            console.log(
            "Fee in function for",
            priorityLevel,
            " :",
            data.result.priorityFeeEstimate
            );
            return data.result;
        }
        return { priorityFeeEstimate: 0 };
      }

      const reloadAllNft = () => {
        loadAllNft(currentWallet, hiddenMachine, mainMachine, mainMachine2, isShowAll);
      }
    const afterTransferProcessing = () => {
        /*
        var filteredNft = [];
        for(var i in allNfts) {
            if(allNfts[i] && allNfts[i].address !== selectedNftAddress) {
                filteredNft.push(allNfts[i])
            }
        }
        //console.log(filteredNft);
        setAllNfts(filteredNft);

        localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));

        loadImageNfts(filteredNft, true);
        */
        
        setIsProcessing(true);
        setIsRefresh(true);
        setTimeout(reloadAllNft, 2000)

        setShow(false);
        //handleCloseNftSend();
        handleClosePreview();
        handleShowSuccess();
        //loadAllNft();
        
        
    }

    const backSendBalance = () => {
        setSendTokenErrorMessage("");

        setSendTokenTitle("SEND TOKENS");
    }
    const closeSendToken = () => {
        if(sendTokenTitle === 'SEND TOKENS') {
            handleClosePreviewSend();
        }else if(sendTokenTitle === 'CONFIRM SEND') {
            setSendTokenErrorMessage("");
            backSendBalance();
        }
    }
    const showSendBalance = () => {
        //console.log(props.provider);

        if(props.provider) {
            setSendTokenErrorMessage("");

            setSendTokenTitle('SEND TOKENS');
            handleShowPreviewSend();
        }else{
            props.loginWeb3Auth();
        }
    }

    const showByRecent = () => {
        setShowBy('recent');
    }

    const showByCollection = () => {
        setShowBy('collection');
    }

    const showPurchaseSol = () => {
        handleCloseNeedSol();
        openOnRamp();
    }


    const pinNft = async () => {
        let resp = await pinItem (1, selectedNftAddress);
        if(resp) {
            setIsPin(true);
            //console.log(allNfts);
 
            loadImageNfts(allNfts, false, false, byRecentNft, byCollectionNft);
        }
    }
    const unpinNft = async () => {
        let resp = await pinItem (0, selectedNftAddress);
        if(resp) {
            setIsPin(false);
            loadImageNfts(allNfts, false, false, byRecentNft, byCollectionNft);
        }
    }

    const pinItem = async (val, address) => {
        let api = 'pin-nft';
        if(val === 0) {
            api = 'unpin-nft';
        }
     
        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                
                const params = {
                    token: userData.token,
                    secret: userData.secret,
                    address: address
                }
    
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify(params)
                };
    
                const response = await fetch(backend_api_url + 'api/v1/machine/'+api, requestOptions);
                const json = await response.json();
                if(json.status === 1) {
                    //console.log(json);
                    localStorage.setItem("pinnedNfts", JSON.stringify ( json.pinnedNft) );
                    return true;
                }
            }
        }
        return false; 
    }

     
const initialOptions = {
    clientId: getPaypalClientId(),
    currency: "USD",
    intent: "capture",
};

const onCreateOrder = (data,actions) => {


    return actions.order.create({
        purchase_units: [
            {
                amount: {
                    value: reloadBalance, // "8.99",
                },
            },
        ],
    });
}

const onApproveOrder = (data,actions) => {
    return actions.order.capture().then((details) => {
    // const name = details.payer.name.given_name;
    //console.log(details);
    // alert(`Transaction completed by ${name}`);

    //console.log(details.id);
    //console.log(details.status);

    handleCloseStripeReloadAmount();

        if(details.status === 'COMPLETED') {
            showSuccessPaymentReload();
            reloadUserBalanceSave(details.id, 'paypal'); 
        }

    });
}

    props.setClassInfo('walletPg');

    settingsSlider.infinite = num >= 5 ? true : false;
    

    return (
        <Fragment>

        <div className="contents">

            <h5 className="outer">{username}</h5>

            <div className="tokenDetails">
                
            <div className="walletAddress desktop">
                <p>Wallet address : {<span className="account-wallet"><WalletLink mobile={false} loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={true}/></span>} |</p>
                <a href="/wallet-activity" style={{"marginLeft" : "10px"}} className="viewActivity1">wallet activity</a> - <a href="/purchases" className="viewActivity1">purchases</a>
            </div>

            <div className="walletAddress mobile-display">
                <p>Wallet address : {<span className="account-wallet"><WalletLink mobile={true} loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={true}/></span>} |</p>
                <a href="/wallet-activity"  style={{"marginLeft" : "10px"}} className="viewActivity1">wallet activity</a> - <a href="/purchases" className="viewActivity1">purchases</a>
            </div>

            <div className="br"></div>

            <p><b>SOLs : {props.solBalance.toFixed(4)}<br/>Credits : ${balance.toFixed(0)}</b></p>

            <br/>

            {/* <a href="#SendToken"  className="buttonLink sendTokenButton1" >SEND TOKEN</a> */}
            <button className="buttonLink btn-generic send-purchase" onClick={showSendBalance}>SEND TOKEN</button>
            <button className="buttonLink btn-generic send-purchase" onClick={openOnRamp}>PURCHASE SOL</button>
            <button className="buttonLink btn-generic send-purchase" onClick={openStripe}>PURCHASE CREDITS</button>
            </div>

            <br/>

            <div className={!isMobile ? "collections recentlyMinted nftsMinted" : "collections ig recentlyMinted nftsMinted"}>
            <h5>NFTS MINTED
            {!isShowAll && <a className="show-all-nft wallet-mobile-actions-desktop"  onClick={showAllNft} href="#showAll">Show All NFTs</a>}  
            {isShowAll && <a className="show-all-nft wallet-mobile-actions-desktop"  onClick={showAllToybotNft} href="#showAllToybot">Show Toybot NFTs only</a>}  
            </h5> 
            <button onClick={showByRecent} className="buttonLink btn-generic recentLink send-purchase wallet-mobile-actions-desktop">RECENT</button>
            <button onClick={showByCollection} className="buttonLink btn-generic collectionLink send-purchase wallet-mobile-actions-desktop">COLLECTION</button>


            <div className="wallet-mobile-actions">
                <button onClick={showByRecent} style={{"right" : "0 !important"}} className="buttonLink btn-generic recentLink send-purchase">RECENT</button>
                <button onClick={showByCollection} className="buttonLink btn-generic collectionLink send-purchase">COLLECTION</button>
                {!isShowAll && <a className="show-all-nft"  onClick={showAllNft} href="#showAll">Show All NFTs</a>}  
                {isShowAll && <a className="show-all-nft"  onClick={showAllToybotNft} href="#showAllToybot">Show Toybot NFTs only</a>}  
            </div>
            <div className="collectionsDiv1">
                {
                    isProcessing && <p style={{"textAlign": "left"}}>{isRefresh ? 'Refreshing' : 'Processing'}...</p>
                }
                {
                    !isProcessing && showBy === 'recent' && hideInitial === false && renderedElements
                }
                {
                    !isProcessing && showBy === 'recent' && hideInitial === true && renderedElementsNew
                }
                {
                    !isProcessing && showBy === 'collection' && listingJsxByCollection
                }
            </div>


            </div>

            </div>

            <Modal className="modal-preview" show={showPreviewSend} onHide={closeSendToken}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">{sendTokenTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <div style={{"color" : 'red', 'textAlign': 'center', "display" : sendTokenErrorMessage !== '' ? 'block' : 'none' }}>{sendTokenErrorMessage}</div>

        <div id='SendToken' style={ {"padding" : '0px', "display" : sendTokenTitle === 'SEND TOKENS' ? 'block' : 'none'}}>
                <form name="frmSendTokens" id="frmSendTokens" method="get" action="#">

                    <table style={ {"border" : 0}} className="sendTokenTable">
                    <tbody>
                    <tr>
                        <td align="right">Token</td>
                        <td align="left">
                        <select name="TokenType" id="TokenType" ref={tokenTypeInput}>
                            <option value="sol">SOLs</option>  
                            {/* <option value="usdc">USDC</option>   */}
                        </select>	
                        </td>	 
                    </tr>

                    <tr>
                        <td align="right">Amount</td>
                        <td align="left">
                        <input type="text" name="TokenAmount" id="TokenAmount" className="textbox" ref={solAmountInput} defaultValue="100" />
                        </td>	 
                    </tr>

                    <tr>
                        <td align="right">To : Wallet</td>
                        <td align="left">
                        <input type="text" name="ToWallet" id="ToWallet" className="textbox" ref={toAddressInput} />
                        </td>	 
                    </tr>
                    </tbody>
                    </table>
                </form>
                <button className="buttonLink btn-generic" onClick={showSendConfirm} >SEND NOW</button>
        </div>
        <div id='SendToken' style={ {"padding" : '10px', "display" : sendTokenTitle === 'CONFIRM SEND' ? 'block' : 'none'}}>
            <div className="form-group" style={{"marginBottom" : "35px", "wordBreak": "break-word"}}>
                Are you sure you want to send {numOfToken} {selectedTokenType} to {destinationWallet}? 
            </div> 
            <button onClick={backSendBalance} className="buttonLink btn-generic">&lt;&lt; BACK</button>

            <button onClick={sendBalanceNow} className="buttonLink btn-generic">SEND</button>
        </div>

        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>

<Modal className="modal-preview" show={showPreview} onHide={backSendNft}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">{sendNftTitle == 'CONFIRM SEND' ? sendNftTitle : previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <div style={{"color" : 'red', 'textAlign': 'center', "display" : sendNftErrorMessage !== '' ? 'block' : 'none' }}>{sendNftErrorMessage}</div>

                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px", "display" : sendNftTitle === 'CONFIRM SEND' ? 'none' : 'block'} }>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    <p>OWNED BY : <span>{usernameJsx}</span></p>
                    { previewCollectionName && <Fragment><p className="pull-left">COLLECTION : {previewCollectionName}</p></Fragment>}

                    <div className="scrolling-info">
                    <p className="pull-left">DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                     

                    <p className="pull-left">PROPERTIES<br/></p>
                        <div className="custom-row">
                            {
                                propertiesJsx ? propertiesJsx : 'N/A'
                            }
                        </div>
                    </div>
                    {/* <br/> */}
                    <div className="sendNftName">
                        <p>To : Wallet</p>&nbsp;
                        <input type="text" name="ToWallet" id="ToWallet" className="textbox" ref={toAddressNftInput}/>
                        &nbsp;<button onClick={showSendNftConfirm}  className="buttonLink btn-generic">SEND NOW</button>
                    </div>

                    <div className="sendNftName">
                        {
                            isPin && <Fragment> <img className="pin-icon" src="/images/pin.png"/> Pinned - <a href="#pin" onClick={unpinNft}>Unpin</a> </Fragment>
                        }
                        {
                            !isPin && <Fragment> <img className="pin-icon" src="/images/pin.png"/> <a href="#pin" onClick={pinNft}>Pin to User Page</a> </Fragment>
                        }
                        
                        
                    </div>
                </div>

                <div id='SendToken' style={ {"padding" : '10px', "display" : sendNftTitle === 'CONFIRM SEND' ? 'block' : 'none'}}>
                    <div className="form-group" style={{"marginBottom" : "35px", "wordBreak": "break-word"}}>
                            Are you sure you want to send this NFT to this {destinationWallet}? 
                    </div> 
                    <button onClick={backSendNft} className="buttonLink btn-generic">&lt;&lt; BACK</button>

                    <button onClick={sendNftNow} className="buttonLink btn-generic">SEND</button>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>


    <Modal className="modal-processing" show={showSuccess} onHide={handleCloseSuccess} backdrop="static" >
           <Modal.Header closeButton={true}>
                <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
              <div className="success-create">Send Successful</div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

        <Modal  className="modal-preview" show={showAllWarning} onHide={handleCloseAllWarning} backdrop="static"  size="sm">
           <Modal.Header closeButton={true}>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            
            <Modal.Body style={{"textAlign" : "center"}}>
              
            <div style={{"marginBottom" : "30px"}}>
                    View all NFTs? Be careful as some NFTs airdropped into your account are spam and/or lead to phishing sites. Continue and view all NFTs?
                    </div>
     

                    <button onClick={handleCloseAllWarning} className="buttonLink btn-generic">Cancel</button>
                    <button onClick={showAllNftConfirm} className="buttonLink btn-generic">Continue</button>

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

    <Modal  className="modal-preview"  show={showError} onHide={handleCloseError} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>{mintErrorHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{"textAlign" : "center"}}>
    
                    <div>
                        {mintError}
                    </div>
    
                    <div style={{"marginBottom" : "30px"}}>
                        {mintErrorSub}
                    </div>

                    <button onClick={handleCloseError} className="buttonLink btn-generic">OK</button>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 
            
                <Modal className="modal-payment" size="sm" show={showStripe} onHide={handleCloseStripe} backdrop="static">
                <Modal.Header closeButton={true}>
                {/* <Modal.Title>Notification</Modal.Title> */}
                </Modal.Header>
                    <Modal.Body>
                    <div style={{ "textAlign" : "center"}}>
                        {
                            secret && <OnRamp secret={secret} showSuccessPayment={showSuccessPayment} setMintErrorSub={setMintErrorSub}></OnRamp>
                        }
                    </div>
        
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>

                <Modal className="modal-payment" size="sm" show={showNeedSol} onHide={handleCloseNeedSol} backdrop="static">
                <Modal.Header closeButton={true}>
                {/* <Modal.Title>Notification</Modal.Title> */}
                </Modal.Header>
                    <Modal.Body>
                    <div style={{ "textAlign" : "center"}}>
                        You need SOLs to send NFTs to a different wallet. Either send SOLs to this wallet, or <a href="#purchase" onClick={showPurchaseSol}>Purchase SOLs</a>
                    </div>
        
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>



                <Modal className="modal-payment" size="sm" show={showStripeReloadAmount} onHide={handleCloseStripeReloadAmount} backdrop="static">
                    <Modal.Header closeButton={true}>
                    <Modal.Title>Buy Credits
                    </Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                        <div style={{ "textAlign" : "center"}}>
 
                            <div id='SendToken'>
                            <span style={{"fontSize":"12px", "position" : "relative", "top" : "-10px"}}>You currently have {balance.toFixed(2)} credit(s). 1 credit = $1</span>
                                    <form name="frmSendTokens" id="frmSendTokens" method="get" action="#">

                                        <table style={ {"border" : 0}} className="sendTokenTable">
                                        <tbody>
                                        <tr>
                                            <td align="right">Amount</td>
                                            
                                            <td align="left">
                                            <input type="text" className="textbox" required ref={amountInput} defaultValue={100} />
                                            </td>	 
                                        </tr>

                                    
                                        </tbody>
                                        </table>
                                    </form>
                                    <button className="buttonLink btn-generic" onClick={openStripeNow} >PURCHASE NOW</button>
                                    
                            </div> 

                        </div>

                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                </Modal>


                <Modal className="modal-payment" size="sm" show={showStripeReload} onHide={handleCloseStripeReload} backdrop="static">
                    <Modal.Header closeButton={true}>
                    {/* <Modal.Title>Notification</Modal.Title> */}
                    </Modal.Header>
                        <Modal.Body>
                        <div style={{ "textAlign" : "center"}}>

                            <div style={{"textAlign": "left"}}>Buy Credits: ${reloadBalance}</div>

                            {
                                        <PayPalScriptProvider options={initialOptions}>
                                        <PayPalButtons style={{ layout: "horizontal" }} 
                                        createOrder={(data, actions) => onCreateOrder(data, actions)}
                                        onApprove={(data, actions) => onApproveOrder(data, actions)}
                                        />
                                    </PayPalScriptProvider>
                            }
                            <div style={{ "textAlign" : "center", "margin" : "20px"}}>
                                OR
                            </div>
                            {
                                secretReload && 
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutFormOld showSuccessPayment={showSuccessPaymentReload} mint={reloadUserBalanceSave}/>
                                </Elements> 
                            }
                        </div>

                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                </Modal>
                    

                <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton={false}>
                <Modal.Title>Processing...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                <div className="progressBar"><div></div></div>
    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 


        </Fragment>

    );
}

export default WalletInfo;